import {createFeatureSelector, createSelector} from '@ngrx/store';
import {usersFeatureKey, UserState, selectAll} from './user.reducer';

export const selectUserState = createFeatureSelector<UserState>(
  usersFeatureKey
);

export const selectUsers = createSelector(selectUserState, selectAll);
export const selectedUser = createSelector(selectUserState, (state: UserState) => state.selectedUser);
export const getError = createSelector(selectUserState, state => state.error);
