import {Component, Input, OnInit} from '@angular/core';
import {IClientSynchronisation} from '@interfaces/iclient-synchronisation';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-synchronisations',
  templateUrl: './client-synchronisations.component.html',
  styleUrls: ['./client-synchronisations.component.scss']
})
export class ClientSynchronisationsComponent implements OnInit {
  @Input() clientId: string;
  isLoading = true;
  clientSynchronisations: IClientSynchronisation[];
  displayedColumnsSynchronisations: string[] =
    ['error', 'entryUpdated', 'started', 'fiscalYearsSynced', 'accountingSumsAndBalancesSynced', 'accountsReceivableSynced', 'accountsPayableSynced', 'debitorsSynced', 'errorLog'];
  allSynchrosLoaded = false;

  params = {limit: 10, offset: 0};

  constructor(
    private requestManager: RequestManagerService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.getClientSynchronisations()
      .then(res => this.clientSynchronisations = res);
  }

  downloadErrorLog(element: any) {
    console.log(element);
  }

  private async getClientSynchronisations(): Promise<IClientSynchronisation[]> {
    this.isLoading = true;
    this.params.limit += this.params.offset;
    const res = await this.requestManager.get('/adminArea/client/' + this.clientId + '/clientSynchronisations', this.params);
    this.isLoading = false;
    return res['clientSynchronisations'];
  }

  loadMoreSynchronisations() {
    this.params.offset += this.params.limit;
    this.requestManager.get('/adminArea/client/' + this.clientId + '/clientSynchronisations', this.params)
      .then(res => {
        if (!res['clientSynchronisations']) {
          this.allSynchrosLoaded = true;
          this.snackBar.open('Es wurden alle Synchronisationen geladen', 'Okay', {duration: 5000});
        }
        this.clientSynchronisations = [...this.clientSynchronisations, ...res['clientSynchronisations']];
      });
  }
}
