<mat-list>
  <mat-list-item class="margin-12">
    <mat-form-field>
      <mat-label>Land</mat-label>
      <mat-select [(ngModel)]="newAddress.country">
        <mat-option value="AT">Österreich</mat-option>
        <mat-option value="DE">Deutschland</mat-option>
        <mat-option value="ES">Spanien</mat-option>
        <mat-option value="FR">Frankreich</mat-option>
        <mat-option value="CN">China</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>PLZ</mat-label>
      <input matInput [(ngModel)]="newAddress.postalCode">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Stadt</mat-label>
      <input matInput [(ngModel)]="newAddress.city">
    </mat-form-field>
  </mat-list-item>
  <br>
  <mat-list-item class="margin-12">
    <mat-form-field>
      <mat-label>Straße</mat-label>
      <input matInput [(ngModel)]="newAddress.street">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Nummer</mat-label>
      <input matInput [(ngModel)]="newAddress.doorNumber">
    </mat-form-field>
  </mat-list-item>
  <mat-list-item *ngIf="newAddress.entryCreated && newAddress.entryUpdated">
    <div class="flex-column margin-12">
      <div>Erstellungsdatum: {{address.entryCreated | amFromUtc  | amLocal | amDateFormat: 'DD.MM.YYYY HH:mm'}}</div>
      <div>Letzte Änderung: {{address.entryUpdated | amFromUtc  | amLocal | amDateFormat: 'DD.MM.YYYY HH:mm'}}</div>
    </div>
  </mat-list-item>
</mat-list>

<div class="flex-between margin-top-12">
  <button mat-raised-button (click)="dialog.closeAll()">Abbrechen</button>
  <button *ngIf="address" mat-raised-button color="warn" (click)="deleteAddress()">Löschen</button>
  <button mat-raised-button color="primary" (click)="saveAddress()">Speichern</button>
</div>
