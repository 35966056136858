import {Component, Inject, OnInit} from '@angular/core';
import {IUser} from '@interfaces/iuser';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {UserService} from '@services/user/user.service';
import {IDepartment} from '@interfaces/idepartment';
import * as _ from 'lodash';
import {of, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-user-add-department-dialog',
  templateUrl: './user-add-department-dialog.component.html',
  styleUrls: ['./user-add-department-dialog.component.scss']
})
export class UserAddDepartmentDialogComponent implements OnInit {
  data: IDepartment[];
  displayedColumnsDepartments: string[] = ['id', 'name', 'entryCreated', 'edit'];
  user: IUser;
  availableDepartments: IDepartment[];
  private calls = new Subject();

  constructor(
    private requestManager: RequestManagerService,
    private userService: UserService,
    private dialogRef: MatDialogRef<UserAddDepartmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.availableDepartments = data.availableDepartments;
    if (data.user) {
      this.user = _.cloneDeep(data.user);
    }
  }

  async ngOnInit() {
  }


  /**
   * Get user data, create postBody and post new userDepartments-entry, then close dialog
   * @param element userDepartments-object to be posted
   */
  async addDepartment(element: any) {
    this.calls.next(true);
    const body = {
      departmentsId: element.id,
      userId: this.user.id
    };

    of(this.requestManager.post('/userDepartments', body))
      .pipe(
        takeUntil(this.calls)
      ).subscribe( async () => {
      body['canReadDepartmentsFiles'] = '0';
      body['canApproveContentWise'] = '0';
      body['canApproveFinanceWise'] = '0';
      body['canMoveDepartmentsFiles'] = '0';
      body['canDeleteDepartmentsFiles'] = '0';
      await this.requestManager.post('/userDepartmentsRights', body);
    });
    this.dialogRef.close();
    // TODO: REPLACE DIALOG REF WITH UPDATE LOGIC FOR DIALOG
  }
}
