import {Component, Input, OnInit} from '@angular/core';
import {IClientDetailFields} from '@interfaces/iclient-detail-fields';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {ActivatedRoute} from '@angular/router';
import {IClient} from '@interfaces/iclient';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ClientCreateIndividualFieldComponent} from '../../client-create-individual-field/client-create-individual-field.component';

@Component({
  selector: 'app-client-individual-fields',
  templateUrl: './client-individual-fields.component.html',
  styleUrls: ['./client-individual-fields.component.scss']
})
export class ClientIndividualFieldsComponent implements OnInit {
  @Input() client: IClient;
  clientId: string;
  clientDetailFields: IClientDetailFields[];
  isLoading: boolean;

  displayedColumnsDetailFields: string[] = ['name', 'value', 'required', 'visibleInSummary', 'function', 'create'];

  constructor(private route: ActivatedRoute,
              private requestManager: RequestManagerService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) {
  }

  async ngOnInit() {
    this.isLoading = true;
    await this.getClientDetailFields();
    this.isLoading = false;
  }

  async getClientDetailFields() {
    this.clientDetailFields =
      await this.requestManager.get('/client/' + this.client.id + '/clientDetailFields', {array_values: true});
    if (this.clientDetailFields !== undefined && this.clientDetailFields.length !== 0) {
      this.clientDetailFields = this.clientDetailFields['client'][0].clientDetailFields;
    }
  }

  createNewIndividualField() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';
    dialogConfig.data = {client: this.client, editMode: false};

    // TODO remove unncessary requests if there is no change
    this.dialog.open(ClientCreateIndividualFieldComponent, dialogConfig).afterClosed().subscribe(() => {
      this.getClientDetailFields();
    });
  }

  editIndividualField(field: IClientDetailFields) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';
    dialogConfig.data = {client: this.client, clientDetailFields: field, editMode: true};

    // TODO remove unnecessary requests if there is no change
    this.dialog.open(ClientCreateIndividualFieldComponent, dialogConfig).afterClosed().subscribe(() => {
      this.getClientDetailFields();
    });
  }

}
