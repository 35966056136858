<div mat-dialog-title class="modal-title">
  {{editMode ? 'Individuell Feld bearbeiten' : 'Individuell Feld erstellen'}}
</div>
<hr class="hr-margin-bottom">

<div class="flex-detail-field">
  <form [formGroup]="detailFieldFormGroup">
    <mat-list style="margin-bottom: 1em">
      <mat-list-item class="margin-12 margin-right-12">
        <!-- Feld Name -->
        <mat-form-field>
          <mat-label>Feld Name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </mat-list-item>

      <mat-list-item class="margin-12 margin-right-12" style="margin-bottom: 1em; justify-content: space-between">
        <div class="flex-between" style="width: 50%">
          <!-- Pflichtfeld -->
          <mat-label>Pflichtfeld</mat-label>
          <mat-slide-toggle formControlName="required"></mat-slide-toggle>
        </div>
      </mat-list-item>

      <mat-list-item class="margin-12 margin-right-12" style="margin-bottom: 1em; justify-content: space-between">
        <div class="flex-between" style="width: 50%">
          <!-- In Freigabeprotokoll anzeigen -->
          <mat-label>In Freigabeprotokoll anzeigen</mat-label>
          <mat-slide-toggle formControlName="visibleInSummary"></mat-slide-toggle>
        </div>
      </mat-list-item>

      <mat-list-item class="margin-12 margin-right-12">
        <!-- Datev Feld -->
        <mat-form-field>
          <mat-label>Datev Feld</mat-label>
          <mat-select [value]="detailFieldFormGroup.get('function').value">
            <mat-option *ngFor="let singleValue of datevValues"
                        [value]="singleValue.id"
                        (click)="selectFunction(singleValue.id)">
              {{singleValue.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-list-item>

      <div class="flex-between">
        <mat-list-item class="select-type">
          <!-- Feldart -->
          <mat-form-field style="width: 100%">
            <mat-label>Feldart</mat-label>
            <mat-select [value]="detailFieldFormGroup.get('type').value">
              <mat-option *ngFor="let singleValue of type"
                          [value]="singleValue"
                          (click)="selectType(singleValue)">
                {{singleValue === 'select' ? 'Auswahl' :
                singleValue === 'currency' ? 'Betrag' :
                  singleValue === 'date' ? 'Datum' :
                    singleValue === 'text' ? 'Text' :
                      singleValue === 'number' ? 'Zahl' : ''}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-list-item>

        <mat-list-item class="margin-12 margin-right-12">
          <!-- Standardwert -->
          <mat-form-field style="width: 100%" *ngIf="detailFieldFormGroup.get('type').value === 'select'">
            <mat-label>Standardwert</mat-label>
            <mat-select [value]="detailFieldFormGroup.get('value').value">
              <mat-option *ngFor="let singleValue of possibleValues"
                          [value]="singleValue"
                          (click)="selectValue(singleValue)">
                {{singleValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="width: 100%" *ngIf="detailFieldFormGroup.get('type').value === 'currency'">
            <mat-label>Standardwert</mat-label>
            <input type="text" matInput formControlName="value">
          </mat-form-field>
          <mat-form-field style="width: 100%" *ngIf="detailFieldFormGroup.get('type').value === 'date'">
            <mat-label>Standardwert</mat-label>
            <input type="date" matInput formControlName="value">
          </mat-form-field>
          <mat-form-field style="width: 100%" *ngIf="detailFieldFormGroup.get('type').value === 'text'">
            <mat-label>Standardwert</mat-label>
            <input type="text" matInput formControlName="value">
          </mat-form-field>
          <mat-form-field style="width: 100%" *ngIf="detailFieldFormGroup.get('type').value === 'number'">
            <mat-label>Standardwert</mat-label>
            <input type="number" matInput formControlName="value">
          </mat-form-field>
        </mat-list-item>
      </div>

      <div *ngIf="detailFieldFormGroup.get('type').value === 'select'" style="margin: 0 2em; text-align: center">
        <div>
          <form [formGroup]="newValueFormGroup" (ngSubmit)="addNewValue()">
            <!-- Neuer Wert -->
            <mat-form-field>
              <mat-label>Neuer Wert</mat-label>
              <input matInput formControlName="newValue">
            </mat-form-field>
            <button type="submit"
                    mat-raised-button
                    color="primary"
                    style="margin-left: 0.5em; padding: 0 12px; line-height: 24px; font-size: 12px">
              Hinzufügen
            </button>
          </form>
        </div>

        <div style="max-height: 250px; overflow-y: scroll">
          <table *ngIf="possibleValues.length !== 0" mat-table [dataSource]="dataSource"
                 style="margin: auto; max-height: 200px">
            <ng-container matColumnDef="possibleValue">
              <th mat-header-cell *matHeaderCellDef>Wert</th>
              <td mat-cell *matCellDef="let element"> {{element}} </td>
            </ng-container>
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <mat-icon mat-list-icon
                          class="ion ion-md-trash"
                          color="warn"
                          (click)="deleteValue(element)"
                          style="cursor: pointer;">
                </mat-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <div *ngIf="possibleValues.length === 0">
            Die Auswahlliste ist leer
          </div>
        </div>
      </div>
    </mat-list>
  </form>
</div>
<div class="flex-between">
  <button mat-raised-button (click)="closeDialog()" color="warn">
    Abbrechen
  </button>
  <div>
    <button *ngIf="editMode" mat-raised-button (click)="deleteDetailField()" color="warn" style="margin-right: 0.5em">
      Löschen
    </button>
    <button mat-raised-button (click)="createDetailField()" color="primary" [disabled]="detailFieldFormGroup.invalid">
      {{editMode ? 'Speichern' : 'Erstellen'}}
    </button>
  </div>
</div>

