import {Component} from '@angular/core';
import {Router} from '@angular/router';
import { version } from '../../package.json';
import {TokenStorageService} from '@services/token-storage/token-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'taxflow-taxflow-frontend';
  version: string = version;
  events: string[] = [];
  opened: boolean;
  pageName: any;
  menu = [
    {
      type: 'divider',
      name: 'Administration',
    },
    {
      type: 'item',
      name: 'Dashboard',
      route: '/dashboard',
      icon: 'ion-ios-grid'
    },
    {
      type: 'item',
      name: 'Mandanten',
      route: '/client',
      icon: 'ion-md-business'
    },
    {
      type: 'item',
      name: 'Benutzer',
      route: '/user',
      icon: 'ion-md-person'
    },
    {
      type: 'item',
      name: 'DATEV Info',
      route: '/datev-info',
      icon: 'ion-md-information-circle-outline'
    },
    {
      type: 'item',
      name: 'Einstellungen',
      route: '/settings',
      icon: 'ion-md-settings',
    },
    {
      type: 'divider',
      name: 'Vorgänge',
    },
    {
      type: 'item',
      name: 'Debug',
      route: '/debug',
      icon: 'ion-ios-bug'
    },
  ];

  constructor(
    public tokenStorageService: TokenStorageService,
    public router: Router,
  ) {
    this.router.events.subscribe(
      () => {
        if (this.router.url.indexOf('/dashboard') !== -1) {
          this.pageName = 'Dashboard';
        } else if (this.router.url.indexOf('/user') !== -1) {
          this.pageName = 'Benutzer';
        } else if (this.router.url.indexOf('/client') !== -1) {
          if (this.router.url.indexOf('/client/') !== -1) {
            this.pageName = 'Mandant';
          } else {
            this.pageName = 'Mandanten';
          }
        } else if (this.router.url.indexOf('/debug') !== -1) {
          this.pageName = 'Debug';
        } else if (this.router.url.indexOf('/datev-info') !== -1) {
          this.pageName = 'DATEV Information';
        }
      }
    );
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url)
      .then(() => this.opened = false);
  }

  isInValidRoute() {
    return this.router.url !== '/login' && this.router.url !== '/client-select';
  }
}
