<div class="container">
  <div mat-dialog-title class="modal-title">
    {{isPutMode ? "Business Report bearbeiten" : "Business Report hochladen"}}
  </div>
  <hr class="hr-margin-bottom">

  <div class="flex-upload">
    <form [formGroup]="uploadFormGroup">
      <mat-list>
        <mat-list-item class="margin-12 margin-right-12">
          <mat-form-field>
            <mat-label>Name*</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
        </mat-list-item>
        <mat-list-item class="margin-12 margin-right-12">
          <mat-form-field>
            <mat-label>Beschreibung</mat-label>
            <input matInput formControlName="description" />
          </mat-form-field>
        </mat-list-item>
        <mat-list-item *ngIf="uploadFormGroup.value.file">
          <h4 matLine>{{ uploadFormGroup.value.file.name }}</h4>
          <p class="grey" matLine>{{ humanFileSize(uploadFormGroup.value.file.size) }}</p>
        </mat-list-item>
      </mat-list>
      <div
        class="fileupload-container"
        appDragAndDrop
        (fileDropped)="processDroppedFiles($event)"
      >
        <h3>.csv-Datei hierher ziehen</h3>
        <h3>oder</h3>
        <input
          #csvInput
          hidden="true"
          type="file"
          accept=".csv"
          onclick="this.value=null"
          (change)="onFileUploadChange($event)"
        />
        <button mat-flat-button color="primary" (click)="csvInput.click()">
          Auswählen
        </button>
      </div>
    </form>
  </div>
  <div class="flex-between">
    <button mat-raised-button (click)="dialogRef.close(false)" color="warn">
      Abbrechen
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="uploadFormGroup.invalid || isUploading"
      (click)="upload()"
      [class.spinner]="isUploading"
    >
      {{ isPutMode ? "Speichern" : "Hochladen" }}
    </button>
  </div>
</div>
