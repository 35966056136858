<mat-card-content *ngIf="clientDetailFields && clientDetailFields.length !== 0 && !isLoading" class="table-container">
  <table mat-table class="width-100" [dataSource]="clientDetailFields">

    <!-- Feld Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Feld Name</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Standardwert Column -->
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef> Standardwert</th>
      <td mat-cell *matCellDef="let element">
        <div
          *ngIf="element.type === 'select' || element.type === 'currency' || element.type === 'text' || element.type === 'number'">
          {{element.value}}
        </div>
        <div *ngIf="element.type === 'date'">
          {{element.value | date:'dd.MM.yyyy'}}
        </div>
      </td>
    </ng-container>

    <!-- Pflichtfeld Column -->
    <ng-container matColumnDef="required">
      <th mat-header-cell *matHeaderCellDef> Pflichtfeld</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon mat-list-icon color="{{element.required == '1' ? 'primary': 'warn'}}">
          {{element.required == '1' ? 'check' : 'close'}}
        </mat-icon>
      </td>
    </ng-container>

    <!-- In Freigabeprotokoll anzeigen Column -->
    <ng-container matColumnDef="visibleInSummary">
      <th mat-header-cell *matHeaderCellDef> In Freigabeprotokoll anzeigen</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon mat-list-icon color="{{element.visibleInSummary == '1' ? 'primary': 'warn'}}">
          {{element.visibleInSummary == '1' ? 'check' : 'close'}}
        </mat-icon>
      </td>
    </ng-container>

    <!-- DATEV Feld Column -->
    <ng-container matColumnDef="function">
      <th mat-header-cell *matHeaderCellDef> Datev Feld</th>
      <td mat-cell *matCellDef="let element"> {{element.function}} </td>
    </ng-container>

    <ng-container matColumnDef="create">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button (click)="createNewIndividualField()">
          <mat-icon mat-list-icon class="ion ion-md-add-circle-outline" color="primary"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsDetailFields; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsDetailFields;" (click)="editIndividualField(row)"></tr>
  </table>
</mat-card-content>

<mat-card-content *ngIf="(!clientDetailFields || clientDetailFields.length === 0)  && !isLoading">
  <button mat-icon-button (click)="createNewIndividualField()">
    <mat-icon mat-list-icon class="ion ion-md-add-circle-outline" color="primary"></mat-icon>
  </button>
  <div class="empty-array" style="height: fit-content">
    <p>
      Keine Individuelle Felder vorhanden
    </p>
  </div>
</mat-card-content>

<mat-card-content *ngIf="isLoading">
  <div class="empty-array">
    <mat-spinner></mat-spinner>
  </div>
</mat-card-content>
