import {Component, Input, OnInit} from '@angular/core';
import {IUpload} from '@interfaces/iupload';
import {IUser} from '@interfaces/iuser';
import {UserService} from '@services/user/user.service';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import * as moment from 'moment';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-uploads',
  templateUrl: './client-uploads.component.html',
  styleUrls: ['./client-uploads.component.scss']
})
export class ClientUploadsComponent implements OnInit {
  @Input() clientId: string;
  clientUploads: IUpload[];
  clientUsers: IUser[];
  params = {limit: 20, offset: 0};
  isLoading = true;
  displayedColumnsUploads: string[] =
    ['filename', 'description', 'contentAccepted', 'clearedForPayment', 'uploadedToDATEV', 'retry']; // errors are shown right now
  allUploadsLoaded = false;

  constructor(
    public userService: UserService,
    private requestManager: RequestManagerService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.updateUploads();
  }

  loadMoreUploads() {
    this.params.offset += this.params.limit;
    this.requestManager.get('/adminArea/client/' + this.clientId + '/uploads', this.params)
      .then(res => {
        if (!res['departmentsFiles']) {
          this.allUploadsLoaded = true;
          this.snackBar.open('Es wurden alle Uploads geladen', 'Okay', {duration: 5000});
        }
        this.clientUploads = [...this.clientUploads, ...res['departmentsFiles']];
        this.writeUserToUpload();
      });
  }

  writeUserToUpload() {
    this.clientUploads?.forEach(
      upload => {
        upload.contentAcceptedByUser = this.clientUsers.find(user => user.id == upload.contentAcceptedBy);
        upload.clearedForPaymentByUser = this.clientUsers.find(user => user.id == upload.clearedForPaymentBy);
        upload.uploadedByUser = this.clientUsers.find(user => user.id == upload.uploadedBy);

        upload.contentAcceptedByName = upload.contentAcceptedByUser ?
          `${upload.contentAcceptedByUser.name} ${upload.contentAcceptedByUser.surname}` : '';
        const contentDate = moment(upload.contentAcceptedDate).format('[am] DD.MM.YYYY [um] HH:mm');
        upload.contentAcceptedByName = `${upload.contentAcceptedByName} ${contentDate}`;

        upload.clearedForPaymentByName = upload.clearedForPaymentByUser ?
          `${upload.clearedForPaymentByUser.name} ${upload.clearedForPaymentByUser.surname}` : '';
        const paymentDate = moment(upload.clearedForPaymentDate).format('[am] DD.MM.YYYY [um] HH:mm');
        upload.clearedForPaymentByName = `${upload.clearedForPaymentByName} ${paymentDate}`;
        upload.uploadedByName = upload.uploadedByUser ?
          `${upload.uploadedByUser.name} ${upload.uploadedByUser.surname}` : '';
      }
    );
  }

  async retryFileUpload(element: any) {
    await this.requestManager.put('/departmentsFiles/' + element.id, {uploadedToDATEV: 'NULL', error: 'NULL'});
    this.snackBar.open('Der Beleg wurde nochmals in den Upload eingereiht!', undefined, {duration: 2000});
    this.updateUploads();
  }

  updateUploads() {
    this.isLoading = true;
    Promise.all([
      this.requestManager.get('/adminArea/client/' + this.clientId + '/uploads', this.params),
      this.userService.getUserList()
    ])
      .then(([uploads, users]) => {
        this.clientUploads = uploads['departmentsFiles'];
        if (!this.clientUploads) {
          this.allUploadsLoaded = true;
        }
        this.clientUsers = users['user'];
        this.writeUserToUpload();
      });
    this.isLoading = false;
  }
}
