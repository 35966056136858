import {Component, Inject, OnInit} from '@angular/core';
import {IUser} from '@interfaces/iuser';
import {IClient} from '@interfaces/iclient';
import {UserService} from '@services/user/user.service';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-add-accounting-contact',
  templateUrl: './client-add-accounting-contact.component.html',
  styleUrls: ['./client-add-accounting-contact.component.scss']
})
export class ClientAddAccountingContactComponent implements OnInit {
  private user: IUser;
  public users: IUser[];
  private readonly client: IClient;
  private readonly isPutMode: boolean;
  displayedColumnsUser: string[] = ['id', 'name', 'phone', 'entryCreated', 'edit'];
  accountingContact: any;

  constructor(
    private dialogRef: MatDialogRef<ClientAddAccountingContactComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private userService: UserService,
    private dialog: MatDialog,
    private requestManager: RequestManagerService,
    private snackBar: MatSnackBar,
  ) {
    this.client = data.client;
    this.users = data.users?.map(user => user.user[0]);
    this.isPutMode = data.isPutMode;
    this.accountingContact = data.accountingContact;
  }

  async ngOnInit() {
  }

  /**
   * Adds a user to a client via a POST request, and afterwards open a snackBar for 2 seconds
   * @param user the user that should be added to the actual client
   */
  async setAccountingContact(user: IUser) {
    const body = {settingName: 'accountingContact', clientId: this.client.id, settingValue: user.id};
    if (!this.isPutMode) {
      await this.requestManager.post('/clientSettings', body);
      this.snackBar.open(`${user.name} ${user.surname} wurde hinzugefügt`)._dismissAfter(2000);
      this.dialogRef.close();
    } else {
      await this.requestManager.put('/clientSettings/' + this.accountingContact[0].id, body);
      this.snackBar.open(`${user.name} ${user.surname} wurde als Buchhaltungskontakt hinterlegt`)._dismissAfter(2000);
      this.dialogRef.close();
    }
  }
}
