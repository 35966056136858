import {Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Params} from '@angular/router';

import {SubSink} from 'subsink';

import {IUser} from '@interfaces/iuser';
import {IClient} from '@interfaces/iclient';

import {ClientService} from '@services/client/client.service';
import {UserService} from '@services/user/user.service';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {loadUser, updateUser} from '../../store/user.actions';
import {select, Store} from '@ngrx/store';
import {UserState} from '../../store/user.reducer';
import {selectedUser} from '../../store/user.selectors';
import {Observable} from 'rxjs';
import {Update} from '@ngrx/entity';
import {UserEditAvatarDialogComponent} from '../user-edit-avatar-dialog/user-edit-avatar-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {UserDeleteDialogComponent} from '../user-delete-dialog/user-delete-dialog.component';


@Component({
  selector: 'app-user-single',
  templateUrl: './user-single.component.html',
  styleUrls: ['./user-single.component.scss']
})
export class UserSingleComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  user$: Observable<IUser>;
  userModel: IUser;

  private clients: IClient[];
  hasInvoiceRight: boolean;

  constructor(
    private route: ActivatedRoute,
    private requestManager: RequestManagerService,
    private userService: UserService,
    private clientService: ClientService,
    public domSanatizer: DomSanitizer,
    public dialog: MatDialog,
    public store: Store<UserState>) {
  }

  ngOnInit() {
    this.user$ = this.store.pipe(select(selectedUser));
    this.user$.subscribe(user => {
      this.userModel = Object.assign({}, user);
    });
    this.subs.add(
      this.route.params.subscribe(params => this.handleParams(this.userService, this.clientService, params)),
    );
  }

  /**
   * Handle parameters subscription and get all information needed for /user/{id}
   * @param userService userService passed because 'this' is another scope
   * @param clientService clientService passed because 'this' is another scope
   * @param params Parameters given in url
   */
  async handleParams(userService: UserService, clientService: ClientService, params: Params): Promise<void> {
    this.store.dispatch(loadUser({id: params['id']}));
    this.clients = await clientService.getClientList();
  }

  /**
   * Unsubscribe from all subscriptions
   */
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Delete user
   */
  deleteUser(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';

    dialogConfig.data = {id: this.userModel.id, name: this.userModel.name, surname: this.userModel.surname};

    this.dialog.open(UserDeleteDialogComponent, dialogConfig);
  }

  /**
   * Disable actual user
   */
  disableUser(): void {
    this.userModel.deleted = this.userModel.deleted === '1' ? '0' : '1';
    const id = this.userModel.id;
    const update: Update<IUser> = {
      id,
      changes: {deleted: this.userModel.deleted},
    };
    this.store.dispatch(updateUser({user: update}));
  }

  /**
   * Open the dialog for editing the users avatar
   */
  openEditAvatarDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';

    dialogConfig.data = {user: this.user$};

    this.dialog.open(UserEditAvatarDialogComponent, dialogConfig);

  }
}
