import {Injectable} from '@angular/core';
import {IUser} from '@interfaces/iuser';
import {RequestManagerService} from '../request-manager/request-manager.service';
import {Observable} from 'rxjs';

/**
 * User service, that can get all user-specific data
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user: IUser;

  constructor(private requestManager: RequestManagerService) {
  }

  async getLoggedInUser(): Promise<IUser> {
    if (!this.user) {
      this.user = await this.requestManager.get('/user/self');
      return this.user;
    }
    return this.user;
  }

  /**
   * GET-Request to get user-list-information and return promise
   */
  async getUserList(): Promise<IUser[]> {
    return this.requestManager.get('/adminArea/user');
  }

  /**
   * GET-Request to get client-information and return single-user-object
   */
  async getUserSingleInformation(userId: string = null): Promise<IUser> {
    let user;
    if (userId) {
      user = await this.requestManager
        .get('/adminArea/user/' + userId + '/client', {array_values: true});
    } else if (this.user) {
      user = await this.requestManager.get('/adminArea/user/' + this.user.id + '/client', {array_values: true});
    } else {
      return null;
    }
    this.user = user['user'][0];
    return this.user;
  }

  /**
   * GET-Request to get user-list-information and return observable
   */
  getUsers(): Observable<IUser[]> {
    return this.requestManager.sget('/adminArea/user');
  }

  /**
   * GET-Request to get user-list-information and return observable
   */
  getUser(id: string | number): Observable<IUser[]> {
    return this.requestManager.sget('/adminArea/user/' + id + '/client');
  }

    /**
   * GET-Request to get user-list-information and return observable
   */
  getUserAvatar(id: string | number): Observable<{user: IUser[]}> {
    return this.requestManager.sget('/adminArea/user/' + id + '/avatar');
  }

  createUser(postBody: {}): Observable<IUser> {
    return this.requestManager.spost('/user', postBody);
  }

  updateUser(id: string | number, changes: Partial<IUser>): Observable<IUser> {
    return this.requestManager.sput(/user/ + (id as string), changes);
  }

  deleteUser(id: string | number): Observable<IUser> {
    return this.requestManager.sdelete(/user/ + (id as string));
  }
}
