import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {TokenStorageService} from '@services/token-storage/token-storage.service';

/**
 * Authentication guard, that checks if the logged in user is allowed to access a route
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private tokenStorageService: TokenStorageService, private router: Router) {
  }

  /**
   * Check, if logged in user is allowed to change route
   * @param route route to navigate to
   * @param state routerState
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.tokenStorageService.isValid()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
