<mat-card>
  <mat-card-title class="width-100 card-title-flex">
    <div>Abteilungen</div>
    <button mat-icon-button (click)="addDepartmentDialog()">
      <mat-icon mat-list-icon class="ion ion-md-add-circle-outline" color="primary"></mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content class="table-container">
    <table mat-table class="width-100" [dataSource]="userDepartments">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.departments[0].name}} </td>
      </ng-container>

      <!-- Erstellungsdatum Column -->
      <ng-container matColumnDef="entryCreated">
        <th mat-header-cell *matHeaderCellDef> Erstellungsdatum</th>
        <td mat-cell *matCellDef="let element"> {{element.departments[0].entryCreated | date:'dd.MM.yyyy'}} </td>
      </ng-container>

      <!-- Bearbeiten Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="deleteDepartmentDialog(element)">
            <mat-icon mat-list-icon class="ion ion-md-close-circle" color="warn"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsDepartment; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsDepartment;"></tr>
    </table>
  </mat-card-content>
</mat-card>
