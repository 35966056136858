import { Component, OnInit } from '@angular/core';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  welcomeMailText = undefined;
  alreadySent: boolean;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '20rem',
    minHeight: '5rem',
    placeholder: 'Hier Text eingeben ...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ]
  };

  constructor(private requestManager: RequestManagerService, private snackBar: MatSnackBar) { }

  async ngOnInit() {
    this.alreadySent = false;
    await this.getValueWelcomeMailText();
  }

  async getValueWelcomeMailText() {
    this.requestManager.get('/settings?array_values=true')
      .then(async settings => {
        let values;
        if (Object.values(settings) !== undefined && Object.keys(settings).length !== 0) {
          values = Object.values(settings)[0];
          this.welcomeMailText = values.filter((value: any) => {
            return value.settingName === 'welcomeMailText';
          });
        }
        if (this.welcomeMailText !== undefined && this.welcomeMailText.length !== 0) {
          this.welcomeMailText = this.welcomeMailText[0];
        } else {
          if (!this.alreadySent) {
            this.alreadySent = true;
            await this.createValueWelcomeMailText().then(() => this.getValueWelcomeMailText());
          }
        }
      })
      .catch((error) => {
        if (error.status === 404) {
          this.snackBar.open('Es ist ein Fehler aufgetreten. Wende dich an den Support!')._dismissAfter(2000);
        }
      });
  }

  async createValueWelcomeMailText() {
    const body = {settingName: 'welcomeMailText', settingValue: null};
    await this.requestManager.post('/settings', body);
  }

  async onSaveWelcomeMailText() {
    const textBody = {settingValue: undefined};
    textBody.settingValue = this.welcomeMailText.settingValue;
    await this.requestManager.put('/settings/' + this.welcomeMailText.id, textBody)
      .then(() => {
        this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
      })
      .catch(() => {
        this.snackBar.open('Die Änderung ist fehlgeschlagen!')._dismissAfter(4000);
      });
  }

}
