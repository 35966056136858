<mat-card-content
  *ngIf="businessReports && businessReports.length > 0 && !isLoading"
  class="table-container"
>
  <table mat-table class="width-100" [dataSource]="businessReports">

    <!-- Radio Button Column -->
    <ng-container matColumnDef="inUse">
      <th mat-header-cell *matHeaderCellDef>Aktiv</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button class="list-start-button" [disabled]="element.inUse"
                (click)="setStandardBusinessReport(element)">
          <mat-icon [class]="'ion ion-md-checkmark icon' + (element.inUse ? '' : ' hidden')" color="primary"></mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element" (click)="setStandardBusinessReport(element)"> {{element.name}} </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="entryCreated">
      <th mat-header-cell *matHeaderCellDef>Datum</th>
      <td mat-cell *matCellDef="let element"
          (click)="setStandardBusinessReport(element)"> {{element.entryCreated | date: "dd.MM.yyyy"}} </td>
    </ng-container>

    <!-- Add Button / Delete Buttons -->
    <ng-container matColumnDef="create">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button (click)="uploadBusinessReportDialog()">
          <mat-icon
            mat-list-icon
            class="ion ion-md-add-circle-outline"
            color="primary"
          ></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          (click)="deleteBusinessReport($event, element)"
        >
          <mat-icon mat-list-icon class="ion ion-md-trash" color="warn"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsBusinessReports; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsBusinessReports;"
        [ngStyle]="row.disable == '1' && {'background-color': '#E5E5E5'}"></tr>
  </table>
</mat-card-content>

<mat-card-content
  *ngIf="(!businessReports || businessReports.length === 0) && !isLoading"
>
  <div class="empty-array">
    <p>Keine Business Reports vorhanden</p>
  </div>
</mat-card-content>

<mat-card-content *ngIf="isLoading">
  <div class="empty-array">
    <mat-spinner></mat-spinner>
  </div>
</mat-card-content>
