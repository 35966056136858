<div mat-dialog-title class="modal-title">
  Benutzer erstellen
</div>
<hr class="hr-margin-bottom">

<mat-dialog-content>
  <form [formGroup]="userFormGroup">
    <div class="grid">
      <div class="grid-left">
        <mat-list>
          <mat-list-item class="margin-12">
            <h3>
              <mat-form-field class="margin-right-12">
                <mat-label>Vorname</mat-label>
                <input matInput formControlName="name">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Nachname</mat-label>
                <input matInput formControlName="surname">
              </mat-form-field>
            </h3>
          </mat-list-item>
          <br>
          <mat-list-item class="margin-12 margin-right-12">
            <mat-form-field>
              <mat-label>Benutzername</mat-label>
              <input matInput formControlName="username">
            </mat-form-field>
          </mat-list-item>
          <mat-list-item class="margin-12">
            <mat-form-field class="width-100">
              <mat-label>E-Mail</mat-label>
              <input matInput formControlName="email">
            </mat-form-field>
          </mat-list-item>
          <mat-list-item class="margin-12">
            <mat-form-field class="width-100">
              <mat-label>Telefon</mat-label>
              <input matInput formControlName="phone">
            </mat-form-field>
          </mat-list-item>

          <mat-list>
            <mat-list-item>
              <div class="width-100 flex-between">
                <div>Admin</div>
                <mat-slide-toggle
                  color="primary"
                  formControlName="isAdmin">
                </mat-slide-toggle>
              </div>
            </mat-list-item>

            <mat-list-item>
              <div class="width-100 flex-between">
                <div>Sichtbar in Chat</div>
                <mat-slide-toggle
                  color="primary"
                  formControlName="visibleInMessenger">
                </mat-slide-toggle>
              </div>
            </mat-list-item>
          </mat-list>

        </mat-list>
      </div>
      <!--    <div class="grid-right">-->
      <!--      <ngx-img (onSelect)="onSelect($event)" (onReset)="onReset($event)" [config]="imageOptions"></ngx-img>-->
      <!--    </div>-->
      <div class="grid-right">
        <input (change)="fileChangeEvent($event)" type="file">
        <div class="flex-align-center">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [roundCropper]="true"
            format="png"
            (imageCropped)="imageCropped($event)"
          ></image-cropper>
        </div>
      </div>
    </div>

    <hr>

    <div class="flex-between">
      <button mat-raised-button color="warn" (click)="dialog.closeAll()">Abbrechen</button>
      <button mat-raised-button color="primary" [disabled]="userFormGroup.invalid" (click)="postNewUser()">Weiter
      </button>
    </div>
  </form>
</mat-dialog-content>
