import {Component, Input, OnInit} from '@angular/core';
import {IClientDatevDmsIntegration} from '@interfaces/iclient-datev-dms-integration';
import {MatDialog} from '@angular/material/dialog';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientService} from '@services/client/client.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-datev-dms-integration',
  templateUrl: './client-datev-dms-integration.component.html',
  styleUrls: ['./client-datev-dms-integration.component.scss']
})
export class ClientDatevDmsIntegrationComponent implements OnInit {
  @Input() dmsRegisters: any[];
  @Input() emptyState: boolean;
  @Input() dmsError: string;

  displayedRegisters: string[] = ['register', 'read', 'write'];

  register: IClientDatevDmsIntegration;
  clientId: string;
  isLoading: boolean;
  selected = '';
  setting: any;
  constructor(
    private dialog: MatDialog,
    private requestManager: RequestManagerService,
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private snackBar: MatSnackBar
  ) {
  }

  async ngOnInit() {
    await this.route.params.subscribe(params => {
      this.clientId = params['id'];
    });
  }

  changeRead(checked: boolean, element: any) {
    const isChanged = true;
    const index = this.dmsRegisters.indexOf(element);
    this.dmsRegisters[index].canRead = checked;
    this.dmsRegisters[index].isChanged = isChanged;
  }

  changeWrite(checked: boolean, element: any) {
    const isChanged = true;
    const index = this.dmsRegisters.indexOf(element);
    this.dmsRegisters[index].canWrite = checked;
    this.dmsRegisters[index].isChanged = isChanged;
  }

  async save() {
    this.isLoading = true;
    const listToCreate = [];
    const listToUpdate = [];

    this.dmsRegisters.forEach(register => {
      if (register.settingValueId == null && (register.canRead == true || register.canWrite == true)) {
        listToCreate.push(register);
      } else if (register.settingValueId && register.isChanged) {
        listToUpdate.push(register);
      }
    });

    const updateBody = listToUpdate.map(register => {
      let settingValue = '';
      if (register.canRead) {
        settingValue += 'r';
      }
      if (register.canWrite) {
        settingValue += 'w';
      }
      return {
        id: register.settingValueId,
        settingKey: register.registerId,
        settingValue: settingValue,
      };
    });

    const createBody = listToCreate.map(register => {
      let settingValue = '';
      if (register.canRead) {
        settingValue += 'r';
      }
      if (register.canWrite) {
        settingValue += 'w';
      }
      return {
        settingKey: register.registerId,
        settingValue: settingValue,
        clientSettingsId: register.clientSettingId,
      };
    });

    // update user read/write rights
    if (updateBody.length != 0) {
      await this.requestManager.put('/settingValues', updateBody);
    }
    // create user read/write rights
    for (const body of createBody) {
      await this.requestManager.post('/settingValues', body);
    }

    if (updateBody.length != 0 || createBody.length != 0) {
      this.clientService.update.next('registers');
      this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
    }
    this.isLoading = false;
  }
}
