<mat-card style="height: 100%">
  <mat-card-title class="width-100">
    <div>Module</div>
  </mat-card-title>
  <mat-card-subtitle *ngIf="pricingModel === 'v1'" style="margin-top: 16px">
    <div style="margin-top: 1em; font-size: 1.25em">
      <span>Hinweis: Du verwendest noch das alte Preismodell.</span>
      <button mat-raised-button color="primary" (click)="changePricingModel()" style="margin-left: 10px"><b>Jetzt wechseln!</b></button>
    </div>
  </mat-card-subtitle>
  <mat-card-content class="table-container">

    <!-- Total Cost -->
    <div *ngIf="pricingModel !== undefined">
      <hr>
      <mat-grid-list cols="2" rowHeight="25px" style="margin: 8px 0; line-height: 25px">
        <mat-grid-tile>
          <div class="text-left">
            <b>Gesamtkosten:</b>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div *ngIf="pricingModel === 'v1'" class="text-right">
            <b>Umsatzabhängig</b>
          </div>
          <div *ngIf="pricingModel === 'v2'" class="text-right">
            <div *ngIf="totalCost !== undefined">
              <b>{{totalCost.toFixed(2)}} € / Mon.</b>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="pricingModel === 'v2'">
          <span style="margin-left: 20px">+ variable Kosten durch OCR</span>
        </mat-grid-tile>
      </mat-grid-list>
      <hr>
    </div>

    <!-- V1 Pricing -->
    <div *ngIf="pricingModel === 'v1'">
      <table mat-table class="width-100" [dataSource]="fixCosts">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Umsatz/Jahr</th>
          <td mat-cell *matCellDef="let element">{{element.sales}}</td>
        </ng-container>

        <!-- Preis Column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef class="right-cell">Preis/Monat</th>
          <td mat-cell *matCellDef="let element" class="right-cell">{{element.cost}} €</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedFixCosts"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedFixCosts;"></tr>
      </table>
      <hr style="margin-top: 0">
      <mat-grid-list style="margin-left: 24px; margin-right: 24px" cols="2" rowHeight="50px">
        <mat-grid-tile>
          <div>OCR</div>
        </mat-grid-tile>
        <mat-grid-tile class="right-cell">
          <div>0.15 €/Beleg</div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <!-- V2 Pricing -->
    <div *ngIf="pricingModel === 'v2'">
      <table mat-table class="width-100" [dataSource]="clientModules">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Modulname</th>
          <td mat-cell *matCellDef="let element">
            {{element.name}}
            <button mat-icon-button (click)="openInfoModal(element)">
              <mat-icon mat-list-icon class="ion ion-md-information-circle" color="primary"></mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Preis Column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef class="right-cell">Preis</th>
          <td mat-cell *matCellDef="let element" class="right-cell">{{element.cost}} €
            / {{element.monthly === '1' ? 'Mon.' : 'Beleg'}}</td>
        </ng-container>

        <!-- Active Column -->
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef class="right-cell">Aktiv</th>
          <td mat-cell *matCellDef="let element" class="right-cell">
            <div *ngIf="element.id === incomingInvoicesUpload.id && incomingInvoices.active === '1'">
              <mat-slide-toggle
                [disabled]="element.id === incomingInvoicesUpload.id && incomingInvoices.active === '1'"
                [checked]="element.active && element.active === '1'"
              ></mat-slide-toggle>
            </div>
            <div *ngIf="!(element.id === incomingInvoicesUpload.id && incomingInvoices.active === '1')">
              <mat-slide-toggle
                [checked]="element.active && element.active === '1'"
                (click)="updateModules($event, element)"
              ></mat-slide-toggle>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedModules"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedModules;"></tr>
      </table>
      <div class="text-right" style="padding: 16px">
        <button mat-raised-button color="primary" (click)="save()">Speichern</button>
      </div>
    </div>

    <div *ngIf="pricingModel === undefined" class="empty-array" style="height: 100%">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card-content>
</mat-card>
