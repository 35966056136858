<div mat-dialog-title class="flex-row">
  <h1>{{client.name}}</h1>
  <button  mat-raised-button color="warn" (click)="deleteClient()">Verknüpfung entfernen</button>
</div>

<div mat-dialog-content>
  <div class="flex-row">
    <div>USt-Id.:</div>
    <div>{{client.vatId}}</div>
  </div>
  <div class="flex-row">
    <div>Erstellungsdatum:</div>
    <div>{{client.entryCreated| date:'dd.MM.yyyy'}}</div>
  </div>

  <mat-tab-group>
    <mat-tab label="Fenster">
      <table mat-table [dataSource]="userClientRights" >

        <!-- ID Column -->
        <ng-container matColumnDef="settingName">
          <th mat-header-cell *matHeaderCellDef> Name</th>
          <td mat-cell *matCellDef="let element"> {{element.settingName | translate:'userRights'}} </td>
        </ng-container>

        <!-- Bearbeiten Column -->
        <ng-container matColumnDef="settingValue">
          <th mat-header-cell *matHeaderCellDef> Sichtbarkeit</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button>
              <mat-icon *ngIf="element.settingValue == 1" mat-list-icon
                        class="ion ion-md-checkmark-circle"
                        color="primary"
                        (click)="editSingleUserRight(element)"></mat-icon>
              <mat-icon *ngIf="element.settingValue != 1" mat-list-icon
                        class="ion ion-md-close-circle"
                        color="warn"
                        (click)="editSingleUserRight(element)"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-tab>
    <mat-tab label="Abteilungen">
      <div class="flex-end" style="margin: 5px 0;" *ngIf="availableDepartments && availableDepartments.length > 0">
        <button mat-raised-button color="primary" (click)="openAddDepartmentModal()">
          <mat-icon mat-list-icon class="ion ion-md-add-circle" color="light"></mat-icon>
          Abteilung hinzufügen
        </button>
      </div>

      <div class="empty-array" *ngIf="!userDepartments">
        <p>
          Keinen Abteilungen zugeordnet
        </p>
      </div>
      <table mat-table class="width-100" *ngIf="userDepartments" [dataSource]="userDepartments">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name</th>
          <td mat-cell *matCellDef="let element"> {{element.departments[0].name}} </td>
        </ng-container>

        <!-- MailPattern Column -->
        <ng-container matColumnDef="mailPattern">
          <th mat-header-cell *matHeaderCellDef>Anzahl Mails</th>
          <td mat-cell
              *matCellDef="let element"> {{element.departments[0].departmentsMailPattern ? element.departments[0].departmentsMailPattern.length : 0}} </td>
        </ng-container>

        <!-- ALL Column -->
        <ng-container matColumnDef="canRead">
          <th mat-header-cell *matHeaderCellDef> Alle Belege</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button>
              <mat-icon
                *ngIf="element.userDepartmentsRights && element.userDepartmentsRights.canReadDepartmentsFiles == 1"
                mat-list-icon
                class="ion ion-md-checkmark-circle"
                color="primary"
                (click)="toggleApproving('canRead', element)"></mat-icon>
              <mat-icon
                *ngIf="element.userDepartmentsRights && element.userDepartmentsRights.canReadDepartmentsFiles != 1"
                mat-list-icon
                class="ion ion-md-close-circle"
                color="warn"
                (click)="toggleApproving('canRead', element)"></mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- CONTENTWISE Column -->
        <ng-container matColumnDef="contentwise">
          <th mat-header-cell *matHeaderCellDef> Inhaltliche Freigabe</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button>
              <mat-icon
                *ngIf="element.userDepartmentsRights && element.userDepartmentsRights.canApproveContentWise == 1"
                mat-list-icon
                class="ion ion-md-checkmark-circle"
                color="primary"
                (click)="toggleApproving('contentwise', element)"></mat-icon>
              <mat-icon
                *ngIf="element.userDepartmentsRights && element.userDepartmentsRights.canApproveContentWise != 1"
                mat-list-icon
                class="ion ion-md-close-circle"
                color="warn"
                (click)="toggleApproving('contentwise', element)"></mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- FINANCEWISE Column -->
        <ng-container matColumnDef="financewise">
          <th mat-header-cell *matHeaderCellDef> Zahlungsfreigabe</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button>
              <mat-icon
                *ngIf="element.userDepartmentsRights && element.userDepartmentsRights.canApproveFinanceWise == 1"
                mat-list-icon
                class="ion ion-md-checkmark-circle"
                color="primary"
                (click)="toggleApproving('financewise', element)"></mat-icon>
              <mat-icon
                *ngIf="element.userDepartmentsRights && element.userDepartmentsRights.canApproveFinanceWise != 1"
                mat-list-icon
                class="ion ion-md-close-circle"
                color="warn"
                (click)="toggleApproving('financewise', element)"></mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- MOVEDEPARTMENTSFILE Column -->
        <ng-container matColumnDef="canMove">
          <th mat-header-cell *matHeaderCellDef> Abteilung ändern</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button>
              <mat-icon
                *ngIf="element.userDepartmentsRights && element.userDepartmentsRights.canMoveDepartmentsFiles == 1"
                mat-list-icon
                class="ion ion-md-checkmark-circle"
                color="primary"
                (click)="toggleApproving('canMove', element)"></mat-icon>
              <mat-icon
                *ngIf="element.userDepartmentsRights && element.userDepartmentsRights.canMoveDepartmentsFiles != 1"
                mat-list-icon
                class="ion ion-md-close-circle"
                color="warn"
                (click)="toggleApproving('canMove', element)"></mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- DELETEDEPARTMENTSFILE Column -->
        <ng-container matColumnDef="canDelete">
          <th mat-header-cell *matHeaderCellDef> Belege löschen</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button>
              <mat-icon
                *ngIf="element.userDepartmentsRights && element.userDepartmentsRights.canDeleteDepartmentsFiles == 1"
                mat-list-icon
                class="ion ion-md-checkmark-circle"
                color="primary"
                (click)="toggleApproving('canDelete', element)"></mat-icon>
              <mat-icon
                *ngIf="element.userDepartmentsRights && element.userDepartmentsRights.canDeleteDepartmentsFiles != 1"
                mat-list-icon
                class="ion ion-md-close-circle"
                color="warn"
                (click)="toggleApproving('canDelete', element)"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsDepartment"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsDepartment;"></tr>
      </table>
    </mat-tab>
  </mat-tab-group>

</div>

<div *ngIf="!data.newClient" mat-dialog-actions class="flex-end">
  <button mat-raised-button color="success" [mat-dialog-close]="">Schließen</button>
</div>
<div *ngIf="data.newClient" mat-dialog-actions class="flex-end">
  <button mat-raised-button color="success" (click)="postUserClientSettings()">Speichern</button>
</div>
