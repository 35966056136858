<mat-card-content *ngIf="clientSynchronisations && !isLoading" class="table-container">
  <table mat-table class="width-100 height-50" [dataSource]="clientSynchronisations">

    <!-- Done Column -->
    <ng-container matColumnDef="error">
      <th mat-header-cell *matHeaderCellDef> Abgeschlossen</th>
      <td mat-cell *matCellDef="let element"> {{element.name}}
        <mat-icon mat-list-icon color="{{!element.done ? 'warn': 'primary'}}"
                  [matTooltip]="element.done | amFromUtc  | amLocal | amDateFormat: 'DD.MM.YYYY - HH:mm'">
          {{!element.done ? 'close' : 'check'}}
        </mat-icon>
      </td>
    </ng-container>

    <!-- EntryCreated Column -->
    <ng-container matColumnDef="entryUpdated">
      <th mat-header-cell *matHeaderCellDef> Zuletzt geändert</th>
      <td mat-cell
          *matCellDef="let element"> {{element.entryUpdated | amFromUtc  | amLocal | amDateFormat: 'DD.MM.YYYY - HH:mm'}}
      </td>
    </ng-container>

    <!-- Erstellungsdatum Column -->
    <ng-container matColumnDef="started">
      <th mat-header-cell *matHeaderCellDef>Start</th>
      <td mat-cell *matCellDef="let element">
        <p *ngIf="!element.started"></p>
        <p *ngIf="element.started">{{element.started | amFromUtc  | amLocal | amDateFormat: 'DD.MM.YYYY - HH:mm'}}</p>
      </td>
      <td mat-cell *matCellDef="let element"></td>
    </ng-container>

    <!-- FiscalYearSynced Column -->
    <ng-container matColumnDef="fiscalYearsSynced">
      <th mat-header-cell *matHeaderCellDef>Wirtschaftjahr</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon mat-list-icon color="{{element.fiscalYearsSynced == '1' ? 'primary': 'warn'}}">
          {{element.fiscalYearsSynced == '1' ? 'check' : 'close'}}
        </mat-icon>
      </td>
    </ng-container>


    <!-- AccountingSumsAndBalancesSynced Column -->
    <ng-container matColumnDef="accountingSumsAndBalancesSynced">
      <th mat-header-cell *matHeaderCellDef>OPOS</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon mat-list-icon color="{{element.accountingSumsAndBalancesSynced == '1' ? 'primary': 'warn'}}">
          {{element.accountingSumsAndBalancesSynced == '1' ? 'check' : 'close'}}
        </mat-icon>
      </td>
    </ng-container>


    <!-- AccountsReceivableSynced Column -->
    <ng-container matColumnDef="accountsReceivableSynced">
      <th mat-header-cell *matHeaderCellDef>Einnahmen</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon mat-list-icon color="{{element.accountsReceivableSynced == '1' ? 'primary': 'warn'}}">
          {{element.accountsReceivableSynced == '1' ? 'check' : 'close'}}
        </mat-icon>
      </td>
    </ng-container>

    <!-- AccountsPayableSynced Column -->
    <ng-container matColumnDef="accountsPayableSynced">
      <th mat-header-cell *matHeaderCellDef>Ausgaben</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon mat-list-icon color="{{element.accountsPayableSynced == '1' ? 'primary': 'warn'}}">
          {{element.accountsPayableSynced == '1' ? 'check' : 'close'}}
        </mat-icon>
      </td>
    </ng-container>

    <!-- DebitorsSynced Column -->
    <ng-container matColumnDef="debitorsSynced">
      <th mat-header-cell *matHeaderCellDef>Debitoren</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon mat-list-icon color="{{element.debitorsSynced == '1' ? 'primary': 'warn'}}">
          {{element.debitorsSynced == '1' ? 'check' : 'close'}}
        </mat-icon>
      </td>
    </ng-container>

    <!-- Error Column -->
    <ng-container matColumnDef="errorLog">
      <th mat-header-cell *matHeaderCellDef>Log</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="downloadErrorLog(element)">
          <mat-icon mat-list-icon class="ion ion-md-document" color="primary"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsSynchronisations; sticky: true;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsSynchronisations;"></tr>
  </table>


  <div class="footer-container">
    <button (click)="loadMoreSynchronisations()" mat-button color="primary" [disabled]="allSynchrosLoaded">Mehr laden
    </button>
  </div>
</mat-card-content>

<mat-card-content *ngIf="!clientSynchronisations && !isLoading">
  <div class="empty-array">
    <p>
      Keine bisherigen Synchronisationen vorhanden
    </p>
  </div>
</mat-card-content>

<mat-card-content *ngIf="isLoading">
  <div class="empty-array">
    <mat-spinner></mat-spinner>
  </div>
</mat-card-content>
