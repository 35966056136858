import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {IClientModule} from '@interfaces/iclient-module';
import {ModuleInfoTextModalComponent} from './module-info-text-modal/module-info-text-modal.component';

@Component({
  selector: 'app-client-change-pricing-model',
  templateUrl: './client-change-pricing-model.component.html',
  styleUrls: ['./client-change-pricing-model.component.scss']
})
export class ClientChangePricingModelComponent implements OnInit {

  modules = [];
  displayedModules: string[] = ['name', 'price'];

  constructor(
    private requestManager: RequestManagerService,
    private dialogRef: MatDialogRef<ClientChangePricingModelComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.modules.push({
      name: 'Rechnungsupload', cost: '0', monthly: '1', info: 'Mit dem Rechnungsupload Feature kannst du Belege ' +
        'in der Taxflow App scannen, hochladen oder per E-Mail erhalten. Diese Belege werden ohne OCR und ohne Freigabe direkt ' +
        'in Unternehmen Online oder DMS hochgeladen.'
    });
    this.modules.push({
      name: 'Rechnungsfreigabe', cost: '25', monthly: '1', info: 'Mit dem Modul Rechnungsfreigabe kannst du Belege ' +
        'als Scan oder als E-Mail-Anhang an deine Buchhaltung übermitteln und einen detaillierten Freigabe-Workflow definieren. ' +
        'Du bestimmst, wer welchen Beleg inhaltlich freigeben darf und wer zur Zahlungsfreigabe berechtigt ist. Zudem hast du immer ' +
        'im Blick, welche Belege noch auf Freigabe warten, welche bereits freigegeben wurden und welche auf den DATEV-Server hochgeladen wurden.'
    });
    this.modules.push({
      name: 'Auswertungen', cost: '5', monthly: '1', info: 'Mit dem Modul Auswertungen hast du immer deine offenen ' +
        'Posten im Blick. Du kannst einen Zeitraum definieren, für den deine Erfolgsrechnung oder deine offenen Posten angezeigt werden, ' +
        'oder du wählst die Live-Ansicht, um den aktuellen Stand deiner Buchhaltung einzusehen.'
    });
    this.modules.push({
      name: 'Dokumentenablage', cost: '5', monthly: '1', info: 'Mit dem Modul Dokumentenablage kannst du dein DATEV-DMS ' +
        'nahtlos bei deinem Mandanten integrieren. Im Admin-Panel kannst du für jeden Mandanten auswählen, auf welche Register er ' +
        'Lese- bzw. Schreibzugriff hat. In der Taxflow-App hat der Benutzer dann auf die ausgewählten Register Zugriff und kann hier ' +
        'die Dokumente lesen, herunterladen oder – falls erlaubt – auch neue Dokumente erstellen.'
    });
    this.modules.push({
      name: 'OCR (50 Belege pro Monat kostenfrei)', cost: '0,15', monthly: '0', info: 'Mit der OCR-Funktion kommst du ' +
        'in den Genuss der automatischen Texterkennung für deine gescannten oder fotografierten Belege für eine schnellere und ' +
        'effizientere Abwicklung deiner Buchhaltung. Pro Monat ist die Verarbeitung von 50 Belegen kostenfrei enthalten; danach ' +
        'werden 0,15€ pro Beleg verrechnet.'
    });
    this.modules.push({
      name: 'Zahlungsverkehr', cost: '15', monthly: '1', info: 'Dieses Modul erlaubt die Verbindung zu einem Zahlungsinstitut. ' +
        'Benutzer können ihr Bankkonto mit der Taxflow-App verknüpfen und haben so Live-Zugriff auf ihren Kontostand sowie ihre Kontoumsätze und ' +
        'können auf Basis ihrer offenen Posten Zahlungen über ihre Bank auslösen. '
    });
  }

  async switchPricingModelToV2() {
    await this.requestManager.put('/clientSettings/' + this.data.id, {settingValue: 'v2'})
      .then(() => {
        this.data.switched = true;
        this.closeDialog();
      })
      .catch(() => {
        this.snackBar.open('Es ist ein Fehler aufgetreten. Wende dich an den Support!')._dismissAfter(2000);
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openInfoModal(module: IClientModule) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '650px';
    dialogConfig.data = module;
    this.dialog.open(ModuleInfoTextModalComponent, dialogConfig);
  }

}
