<mat-card class="height-50">
  <mat-card-title class="width-100">
    <div>Uploads</div>
  </mat-card-title>
  <mat-card-content *ngIf="uploads && !isLoading" class="debug-table-container">
    <table mat-table class="width-100 height-50" [dataSource]="uploads">
      <!-- Name Column -->
      <ng-container matColumnDef="filename">
        <th mat-header-cell *matHeaderCellDef>Dateiname</th>
        <td mat-cell *matCellDef="let element">{{ element.fileName }}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Beschreibung</th>
        <td
          mat-cell
          *matCellDef="let element"
          [matTooltip]="element.description"
        >
          <p>
            {{
              element.description.length > 100
                ? (element.description | slice: 0:97) + "..."
                : element.description
            }}
          </p>
        </td>
      </ng-container>

      <!-- ContentAccepted Column -->
      <ng-container matColumnDef="contentAccepted">
        <th mat-header-cell *matHeaderCellDef>Inhaltliche Freigabe</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            mat-list-icon
            color="{{ element.contentAccepted == '1' ? 'primary' : 'warn' }}"
            [matTooltip]="element.contentAcceptedByName"
          >
            {{ element.contentAccepted == "1" ? "check" : "close" }}
          </mat-icon>
        </td>
      </ng-container>

      <!-- ClearedForPayment Column -->
      <ng-container matColumnDef="clearedForPayment">
        <th mat-header-cell *matHeaderCellDef>Zahlungsfreigabe</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            mat-list-icon
            color="{{ element.clearedForPayment == '1' ? 'primary' : 'warn' }}"
            [matTooltip]="element.clearedForPaymentByName"
          >
            {{ element.clearedForPayment == "1" ? "check" : "close" }}
          </mat-icon>
        </td>
      </ng-container>

      <!-- UploadedToDATEV Column -->
      <ng-container matColumnDef="uploadedToDATEV">
        <th mat-header-cell *matHeaderCellDef>DATEV-Upload</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.uploadedToDATEV" mat-list-icon color="primary"> check </mat-icon>
          <mat-icon *ngIf="!element.clearedForPayment || !element.contentAccepted || !element.uploadedToDATEV && element.error" mat-list-icon color="warn"> close </mat-icon>
          <mat-icon *ngIf="!element.uploadedToDATEV && element.clearedForPayment  && element.contentAccepted && !element.error" mat-list-icon color="primary"> cloud_upload </mat-icon>
        </td>
      </ng-container>

      <!-- Retry Column -->
      <ng-container matColumnDef="retry">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-mini-fab
            color="primary"
            *ngIf="element.error"
            (click)="retryFileUpload(element)"
          >
            <mat-icon mat-list-icon>
              {{ "autorenew" }}
            </mat-icon>
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumnsUploads; sticky: true"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsUploads"></tr>
    </table>
  </mat-card-content>

  <mat-card-content *ngIf="!uploads && !isLoading">
    <div class="empty-array">
      <p>Keine Uploads gefunden</p>
    </div>
  </mat-card-content>

  <mat-card-content *ngIf="isLoading">
    <div class="empty-array">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card-content>
</mat-card>

<div class="footer-container">
  <button
    (click)="loadMoreUploads()"
    mat-button
    color="primary"
    [disabled]="allUploadsLoaded"
  >
    Mehr laden
  </button>
</div>
