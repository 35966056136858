<div mat-dialog-title class="modal-title">
  Mandanten hinzufügen
</div>
<hr class="hr-margin-bottom">

<mat-form-field *ngIf="!isLoading">
  <input matInput type="search" placeholder="Suche" [(ngModel)]="searchTerm" (ngModelChange)="filter()">
</mat-form-field>

<mat-dialog-content>
  <mat-table *ngIf="!isLoading" class="width-100" [dataSource]="filteredAddableClientList">
    <!-- Nummer Column -->
    <ng-container matColumnDef="number">
      <mat-header-cell *matHeaderCellDef> ID</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.number}} </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="add">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="addClientBottomSheet(element)">
          <mat-icon mat-list-icon class="ion ion-md-add" color="primary"></mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <div *ngIf="isLoading" class="width-100 height-200 flex">
    <mat-spinner></mat-spinner>
  </div>
</mat-dialog-content>
