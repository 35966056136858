import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  /**
   * Translate pipe that translates a value depending on type - args[0]
   * @param value value to translate
   * @param args array, where 0 is the type
   */
  transform(value: any, ...args: any[]): any {
    if (args[0] === 'userRights') {
      switch (value) {
        case 'canSeeOPOS':
          return 'Offene Posten';
        case 'canSeeAccounting':
          return 'Kurzfristige Erfolgsrechnung';
        case 'canSeeVoucherScan':
          return 'Beleg - Scan';
        case 'canSeeVoucherApproval':
          return 'Beleg - Freigabe';
        case 'canSeeCompany':
          return 'Mein Unternehmen';
        case 'accountingContact':
          return 'Buchhaltungskontakt';
        case 'mailSummaryEnabled':
          return 'Tägliche Zusammenfassung';
        case 'canSeeDMS':
          return 'Dokumentensystem';
        case 'canSeePaymentTransactions':
          return 'Zahlungsverkehr';
        case 'pushForNewInvoiceActive':
          return 'Über neue Belege via PUSH benachrichtigen';
        case 'mailForNewInvoiceActive':
          return 'Über neue Belege via E-Mail benachrichtigen';
        default:
          return value;
      }
    } else if (args[0] === 'clientSettingsValue') {
      switch (value) {
        case 'live':
          return 'Live';
        case 'lastMonth':
          return 'Letzter Monat';
        case 'secondLastMonth':
          return 'Vorletzter Monat';
        case '01':
          return 'Januar';
        case '02':
          return 'Februar';
        case '03':
          return 'März';
        case '04':
          return 'April';
        case '05':
          return 'Mai';
        case '06':
          return 'Juni';
        case '07':
          return 'Juli';
        case '08':
          return 'August';
        case '09':
          return 'September';
        case '10':
          return 'Oktober';
        case '11':
          return 'November';
        case '12':
          return 'Dezember';
        default:
          return value;
      }
    } else if (args[0] === 'clientSettingsName') {
      switch (value) {
        case 'defaultViewDataMonthOPOS':
        case 'canSeeLiveValuesMonthOPOS':
          return 'Offene Posten';
        case 'defaultViewDataMonthAccountingAnalysis':
        case 'canSeeLiveValuesMonthAccountingAnalysis':
          return 'Erfolgsrechnung';
        case 'canSeeLiveValuesOPOS':
          return 'Offene Posten';
        case 'canSeeLiveValuesAccountingAnalysis':
          return 'Erfolgsrechnung';
        case 'canSeeLiveValuesVoucherScan':
          return 'Beleg - Scan';
        case 'canSeeLiveValuesVoucherApproval':
          return 'Beleg - Freigabe';
        case 'canSeeLiveValuesCompany':
          return 'Mein Unternehmen';
        case 'uploadToDATEVAfterStep':
          return 'Hochladen nach';
        case 'synchronisationPeriod':
          return 'Synchronisations Zyklus';
        default:
          return value;
      }
    } else if (args[0] === 'uploadToDATEVAfterStep') {
      switch (value) {
        case '0':
          return 'Direkt';
        case '1':
          return 'Inhaltliche Freigabe';
        case '2':
          return 'Zahlungsfreigabe';
        default:
          return value;

      }
    } else if (args[0] === 'synchronisationPeriod') {
      switch (value) {
        case '2 hours':
          return 'Alle 2 Stunden';
        case '4 hours':
          return 'Alle 4 Stunden';
        case '6 hours':
          return 'Alle 6 Stunden';
        case '8 hours':
          return 'Alle 8 Stunden';
        case '12 hours':
          return 'Alle 12 Stunden';
        case '24 hours':
          return 'Einmal am Tag';
        case '48 hours':
          return 'Alle 2 Tage';
        case '72 hours':
          return 'Alle 3 Tage';
        case '120 hours':
          return 'Alle 5 Tage';
        case '168 hours':
          return 'Alle 7 Tage';
        default:
          return value;
      }
    }
    return null;
  }

}
