import {Injectable} from '@angular/core';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {ActivatedRoute} from '@angular/router';
import {interval} from 'rxjs';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class DatevService {
  private clientId: any;
  public status: { status: string, expiry: string, consultantNumber: string, clientNumber: string };
  private error: any;

  constructor(
    private requestManager: RequestManagerService,
    private route: ActivatedRoute,
    private ngxLoaderService: NgxUiLoaderService,
  ) {
  }

  public setClientId(clientId: string) {
    this.clientId = clientId;
  }

  public async getStatus() {
    this.status = undefined;
    this.error = undefined;
    try {
      this.status = await this.requestManager.get('/adminArea/client/' + this.clientId + '/connectOnline/status');
    } catch (e) {
      this.error = e;
    }
    return this.status;
  }

  public async openConsentPage() {
    this.ngxLoaderService.start();
    const url = await this.requestManager.get('/adminArea/client/' + this.clientId + '/connectOnline/consentPageUrl');
    window.open(url);

    const subscription = interval(4000)
      // .pipe(takeUntil(timer(60000))) // maximum of 60 seconds
      .subscribe(
        async () => {
          await this.getStatus();
          if (this.status && this.status.status === 'active') {
            subscription.unsubscribe();
            this.ngxLoaderService.stop();
          }
        }
      );
  }

  public async disconnectFromDatev() {
    await this.requestManager.delete('/adminArea/client/' + this.clientId + '/connectOnline');
    await this.getStatus();
  }
}
