import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

export interface IClientUploadBusinessReportData {
  clientId: string;
  isPutMode?: boolean;
}

@Component({
  selector: 'app-client-upload-business-report',
  templateUrl: './client-upload-business-report.component.html',
  styleUrls: ['./client-upload-business-report.component.scss'],
})
export class ClientUploadBusinessReportComponent implements OnInit {
  public uploadFormGroup: FormGroup;
  public isPutMode = false;
  public isUploading = false;

  constructor(
    public dialogRef: MatDialogRef<ClientUploadBusinessReportComponent>,
    private formBuilder: FormBuilder,
    private requestManager: RequestManagerService,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: IClientUploadBusinessReportData
  ) {
    this.uploadFormGroup = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      file: new FormControl(null, Validators.required),
    });

    if (data && data.isPutMode) {
      this.isPutMode = data.isPutMode;
    }
  }

  ngOnInit() {
  }

  onFileUploadChange(event: any) {
    this.processDroppedFiles(event.target.files);
  }

  humanFileSize(bytes, dp = 1) {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
      );

    return bytes.toFixed(dp) + ' ' + units[u];
  }

  checkFile(file: File): boolean {
    const path = file.name;
    const ext = path.substring(path.lastIndexOf('.') + 1).toLowerCase();
    // TODO: may end length checks or deeper file type inspection

    return ext === 'csv';
  }

  /**
   * on file drop handler
   */
  async processDroppedFiles(files: FileList) {

    if (!files || files.length === 0) {
      this.snackbar.open(`Keine Datei ausgewählt!`, null, {duration: 3000});
    } else if (files.length > 1) {
      this.snackbar.open(
        `Es darf maximal eine Datei ausgewählt werden!`,
        null,
        {duration: 3000}
      );
    } else {
      const file = files.item(0);
      const fileValid = this.checkFile(file);

      if (!fileValid) {
        this.snackbar.open(`Bitte Dateiformat .csv beachten!`, null, {
          duration: 3000,
        });
      } else {
        // Convert to base64
        file['blob'] = ((await toBase64(file)) as any) as Blob;

        // Store all file info in form group
        this.uploadFormGroup.patchValue({
          file: file,
        });

        // Set the name value if not set already for user convince
        if (this.uploadFormGroup.value.name === '') {
          this.uploadFormGroup.patchValue({
            name: file.name.substring(0, file.name.lastIndexOf('.')),
          });
        }
        this.cd.markForCheck();
      }
    }
  }

  public async upload() {
    this.isUploading = true;

    const body = {
      name: this.uploadFormGroup.value.name,
      description: this.uploadFormGroup.value.description,
      file: this.uploadFormGroup.value.file.blob,
      clientId: this.data.clientId,
    };

    try {
      await this.requestManager.post('/businessReports', body);
      this.snackbar.open('Business Report hochgeladen!', null, {
        duration: 2000,
      });
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.snackbar.open(`Der Upload ist fehlgeschlagen! ${err}`, null, {
        duration: 2000,
      });
    }
    this.isUploading = false;
  }
}

const toBase64 = (file: File) =>
  new Promise<string | ArrayBuffer | ProgressEvent<FileReader>>(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(removeDataUrl(reader.result as string));
      reader.onerror = (error) => reject(error);
    }
  );

const removeDataUrl = (s: string) => {
  if (s && s != '') {
    const regexArr = /.*base64,(.+)/.exec(s);
    if (regexArr && regexArr.length === 2) {
      return regexArr[1];
    }
  }
  return s;
};
