import {Component, Inject} from '@angular/core';

import {IClient} from '@interfaces/iclient';
import {IUser} from '@interfaces/iuser';

import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {UserService} from '@services/user/user.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {
  ClientEditUserSettingsDialogComponent
} from 'src/app/clients/components/client-edit-user-settings-dialog/client-edit-user-settings-dialog.component';
import {Store} from '@ngrx/store';
import {UserState} from '../../store/user.reducer';
import {loadUser} from '../../store/user.actions';

@Component({
  selector: 'app-user-add-new-client-dialog',
  templateUrl: './user-add-new-client-dialog.component.html',
  styleUrls: ['./user-add-new-client-dialog.component.scss']
})
export class UserAddNewClientDialogComponent {
  data: { clients: IClient[], user: IUser };
  displayedColumnsClients: string[] = ['id', 'name', 'vatId', 'entryCreated', 'edit'];

  constructor(
    private requestManager: RequestManagerService,
    private userService: UserService,
    private dialogRef: MatDialogRef<UserAddNewClientDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data,
    private store: Store<UserState>
  ) {
    this.data = data;
  }

  /**
   * Init dialog data and configurations open user-add-new-client-dialog and post new userClient-connection
   * @param element client-object needed initialising data
   */
  async addClient(element: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';
    dialogConfig.disableClose = true;

    const userRightsForClient = [
      {settingName: 'canSeeOPOS', settingValue: '0', userId: this.data.user.id, clientId: element.id},
      {settingName: 'canSeeAccounting', settingValue: '0', userId: this.data.user.id, clientId: element.id},
      {settingName: 'canSeeVoucherScan', settingValue: '0', userId: this.data.user.id, clientId: element.id},
      {settingName: 'canSeeVoucherApproval', settingValue: '0', userId: this.data.user.id, clientId: element.id},
      {settingName: 'canSeeCompany', settingValue: '0', userId: this.data.user.id, clientId: element.id},
      {settingName: 'canSeeDMS', settingValue: '0', userId: this.data.user.id, clientId: element.id},
      {settingName: 'canSeePaymentTransactions', settingValue: '0', userId: this.data.user.id, clientId: element.id},
    ];

    const departments = (
      await this.requestManager.get(
        '/adminArea/client/' + element.id + '/departments'
      )
    ).departments;


    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '700px';

    dialogConfig.data = {
      client: element,
      user: this.data.user,
      allDepartments: departments,
      calledFromUser: true
    };

    const dialogRef = this.dialog.open(
      ClientEditUserSettingsDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(loadUser({id: this.data.user.id}));
    });

    await this.requestManager.post('/userClient', {
      clientId: element.id,
      userId: this.data.user.id
    });

    this.dialogRef.close();
  }
}
