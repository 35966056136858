import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from '@components/login/login.component';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { AuthGuardService } from '@services/guards/auth-guard.service';
import {DebugComponent} from '@components/debug/debug.component';
import {DatevInfoComponent} from '@components/datev-info/datev-info.component';
import {ClientSingleComponent} from './clients/components/client-single/client-single.component';
import {ClientOverviewComponent} from './clients/components/client-overview/client-overview.component';
import {UserSingleComponent} from './users/components/user-single/user-single.component';
import {UserOverviewComponent} from './users/components/user-overview/user-overview.component';
import {SettingsComponent} from '@components/settings/settings.component';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    pathMatch: 'full',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'client',
    pathMatch: 'full',
    component: ClientOverviewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'client/:id',
    pathMatch: 'full',
    component: ClientSingleComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'user',
    pathMatch: 'full',
    component: UserOverviewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'user/:id',
    pathMatch: 'full',
    component: UserSingleComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'settings',
    pathMatch: 'full',
    component: SettingsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'debug',
    pathMatch: 'full',
    component: DebugComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'datev-info',
    pathMatch: 'full',
    component: DatevInfoComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
