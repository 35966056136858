import {Component, Inject, OnInit} from '@angular/core';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-user-delete-department-dialog',
  templateUrl: './user-delete-department-dialog.component.html',
  styleUrls: ['./user-delete-department-dialog.component.scss']
})
export class UserDeleteDepartmentDialogComponent implements OnInit {
  public data: { id: string, departments: { id: string, entryCreated: string, name: string }[] };

  constructor(
    private requestManager: RequestManagerService,
    private dialogRef: MatDialogRef<UserDeleteDepartmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.data = data;
  }

  ngOnInit() {
  }

  /**
   * Delete userDepartments-entry and close dialog
   */
  async deleteUserDepartmentRights() {
    await this.requestManager.delete('/userDepartments/' + this.data.id);
    this.dialogRef.close();
  }
}
