<div mat-dialog-title class="modal-title">
  {{data.name}}
</div>
<hr class="hr-margin-bottom">

<mat-dialog-content style="margin-top: 20px">
  {{data.info}}
</mat-dialog-content>

<mat-dialog-actions style="justify-content: flex-end">
  <button mat-raised-button color="primary" (click)="closeDialog()" mat-button="">Verstanden</button>
</mat-dialog-actions>
