<div style="margin: 40px">
  <mat-card>
    <div *ngIf="welcomeMailText" class="mat-card-title">
      Willkommens-Nachricht-Text
    </div>
    <div *ngIf="welcomeMailText">
      <div style="padding-top: 5px">
        <angular-editor [(ngModel)]="welcomeMailText.settingValue" name="ngEditor"
                        [config]="config" style="margin-top: 10px"></angular-editor>
      </div>
      <div style="text-align: right; padding-top: 5px">
        <button
          mat-raised-button color="primary"
          (click)="onSaveWelcomeMailText()">
          Speichern
        </button>
      </div>
    </div>
    <div *ngIf="!welcomeMailText" class="empty-array">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card>
</div>
