import {Component, Input, OnInit} from '@angular/core';
import {IContact} from '@interfaces/icontact';
import {ClientEditContactComponent} from '../../client-edit-contact/client-edit-contact.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Component({
  selector: 'app-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['./client-contacts.component.scss']
})
export class ClientContactsComponent implements OnInit {
  @Input() contacts: IContact[];
  @Input() clientId: string;
  displayedColumnsAddress: string[] = ['id', 'name', 'email', 'phone', 'entryCreated', 'edit'];

  constructor(
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
  }

  editContactDialog(contact: IContact) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';

    if (contact) {
      dialogConfig.data = {contact, clientId: contact.clientId};
    } else {
      dialogConfig.data = {clientId: this.clientId};
    }

    this.dialog.open(ClientEditContactComponent, dialogConfig);
  }
}
