<div mat-dialog-title class="modal-title">
  Mandanten hinzufügen
</div>
<hr class="hr-margin-bottom">

<div *ngIf="!data.clients || data.clients.length <= 0" class="empty-array">
  <p>Es stehen keine weiteren Mandanten zur Auswahl</p>
</div>

<table *ngIf="data.clients && data.clients.length > 0" [dataSource]="data.clients" class="width-100" mat-table>

  <!-- ID Column -->
  <ng-container matColumnDef="id">
    <th *matHeaderCellDef mat-header-cell> ID</th>
    <td *matCellDef="let element" mat-cell> {{element.id}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th *matHeaderCellDef mat-header-cell> Name</th>
    <td *matCellDef="let element" mat-cell> {{element.name | slice:0:30}}{{element.name.length > 30 ? '...' : ''}} </td>
  </ng-container>

  <!-- USt-Id. Column -->
  <ng-container matColumnDef="vatId">
    <th *matHeaderCellDef mat-header-cell> USt-Id.</th>
    <td *matCellDef="let element" mat-cell> {{element.vatId}} </td>
  </ng-container>

  <!-- Erstellungsdatum Column -->
  <ng-container matColumnDef="entryCreated">
    <th *matHeaderCellDef mat-header-cell> Erstellungsdatum</th>
    <td *matCellDef="let element" mat-cell> {{element.entryCreated | date:'dd.MM.yyyy'}} </td>
  </ng-container>

  <!-- Bearbeiten Column -->
  <ng-container matColumnDef="edit">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let element" mat-cell>
      <button mat-icon-button>
        <mat-icon (click)="addClient(element)" class="ion ion-md-add-circle" color="primary" mat-list-icon></mat-icon>
      </button>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumnsClients" mat-header-row></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsClients;"></tr>
</table>

<div class="flex-end" mat-dialog-actions>
  <button [mat-dialog-close]="" color="success" mat-raised-button>Schließen</button>
</div>
