import {createReducer, on} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import * as UserActions from './user.actions';
import {IUser} from '@interfaces/iuser';

export const usersFeatureKey = 'users';

export interface UserState extends EntityState<IUser> {
  error: any;
  selectedUser: IUser;
}

export const adapter: EntityAdapter<IUser> = createEntityAdapter<IUser>();

export const initialState: UserState = adapter.getInitialState({
  error: undefined,
  selectedUser: undefined,
});


export const reducer = createReducer(
  initialState,
  // Add new user
  on(UserActions.addUserSuccess,
    (state, action) => adapter.addOne(action.user, state)
  ),
  on(UserActions.addUserFailure, (state, action) => {
    return {...state, error: action.error};
  }),
  on(UserActions.clearUserFailure, (state, action) => {
    return {...state, error: null};
  }),
  // Load all users
  on(UserActions.loadUsersSuccess,
    (state, action) => adapter.setAll(action.users, state)
  ),
  on(UserActions.loadUsersFailure,
    (state, action) => {
      return {...state, error: action.error};
    }),
  // Load single user
  on(UserActions.loadUserSuccess,
    (state, action) => {
      return {...state, selectedUser: action.selectedUser};
    }),
  on(UserActions.loadUserFailure,
    (state, action) => {
      return {...state, error: action.error};
    }),
  // Update a single user
  on(UserActions.updateUser, (state, action) =>
    adapter.updateOne(action.user, state)
  ),
  // Delete a single user
  on(UserActions.deleteUser, (state, action) =>
    adapter.removeOne(<string>action.id, state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
