<div mat-dialog-title class="modal-title">
  Buchhaltungskontakt {{accountingContact ? 'bearbeiten' : 'hinzufügen'}}
</div>
<hr class="hr-margin-bottom">

<table *ngIf="users && users.length > 0" mat-table class="width-100" [dataSource]="users">

  <!-- ID Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> ID</th>
    <td mat-cell *matCellDef="let element"> {{element.id}}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name</th>
    <td mat-cell *matCellDef="let element"> {{element.name}} {{element.surname}}</td>
  </ng-container>

  <!-- Stadt Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email</th>
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>

  <!-- Straße Column -->
  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef> Telefon</th>
    <td mat-cell *matCellDef="let element"> {{element.phone}}</td>
  </ng-container>

  <!-- Erstellungsdatum Column -->
  <ng-container matColumnDef="entryCreated">
    <th mat-header-cell *matHeaderCellDef> Erstellungsdatum</th>
    <td mat-cell *matCellDef="let element"> {{element.entryCreated | date:'dd.MM.yyyy'}} </td>
  </ng-container>

  <!-- Bearbeiten Column -->
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button class="hover-btn" mat-icon-button (click)="setAccountingContact(element)">
        <mat-icon mat-list-icon class="ion ion-md-checkmark-circle" color="primary"></mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumnsUser"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsUser;"></tr>
</table>
