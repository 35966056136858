import {Injectable, OnInit} from '@angular/core';
import {RequestManagerService} from '../request-manager/request-manager.service';
import {IClient} from '@interfaces/iclient';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService implements OnInit{
  public update: Subject<any>;

  constructor(
    private requestManager: RequestManagerService,
  ) {
    this.update = new Subject<any>();
  }

  ngOnInit(): void {
  }

  /**
   * GET-Request to get client-information and return promise
   */
  async getClientList(): Promise<IClient[]> {
    let clients = await this.requestManager.get('/adminArea/client', {array_values: true});
    clients = clients['client'];
    return clients;
  }


  /**
   * GET-Request to get single-client-information and return promise
   */
  getClientSingle(clientId: string): Promise<IClient> {
    return this.requestManager.get('/adminArea/client/' + clientId + '/user', {array_values: true});
  }
}
