import {HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, from, Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, filter, mergeMap, switchMap, take} from 'rxjs/operators';
import {TokenStorageService} from '@services/token-storage/token-storage.service';
import {RequestManagerService} from '@services/request-manager/request-manager.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private tokenStorageService: TokenStorageService,
    private requestManagerService: RequestManagerService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;

    const token = this.tokenStorageService.getAccessToken();

    if (token !== null) {
      if (!req.url.toString().endsWith('auth/refresh')) {
        authReq = this.addTokenHeader(req, token);
      }
    }

    return next.handle(authReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401
        && !authReq.url.toString().endsWith('auth')
        && !authReq.url.toString().endsWith('auth/refresh')) {
        return this.handle401Error(authReq, next);
      } else if (authReq.url.toString().endsWith('auth/refresh')) {
        this.tokenStorageService.signOutAndReload();
      }
      return throwError(error);
    })) as Observable<HttpEvent<any>>;
  }

  private addTokenHeader(req: HttpRequest<any>, token: string) {
    return req.clone({headers: req.headers.set('Authorization', token)});
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    console.log('REFRESH');
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.tokenStorageService.getRefreshToken();
      if (token) {
        return from(this.requestManagerService.authRefresh({refreshToken: token}))
          .pipe(
            switchMap((tkn: any) => {
              this.tokenStorageService.saveAccessToken(tkn.accessToken);
              this.tokenStorageService.saveRefreshToken(tkn.refreshToken);

              this.isRefreshing = false;
              this.refreshTokenSubject.next(tkn.accessToken);
              return next.handle(this.addTokenHeader(req, tkn.accessToken));
            })
          );
      }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(req, token)))
    );
  }
}

export const authInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
];
