import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as fromUserActions from './user.actions';
import {UserService} from '@services/user/user.service';
import {catchError, concatMap, map, mergeMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';

@Injectable()
export class UserEffects {
  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.loadUsers),
      mergeMap(() =>
        this.userService.getUsers().pipe(
          map((user) =>
            fromUserActions.loadUsersSuccess({users: user['user']})
          ),
          catchError((error) => of(fromUserActions.loadUsersFailure({error})))
        )
      )
    )
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.loadUser),
      mergeMap((action) => {
        return forkJoin({
          avatar: this.userService.getUserAvatar(action.id),
          general: this.userService.getUser(action.id),
        }).pipe(
          map((user) => {
              return fromUserActions.loadUserSuccess({selectedUser: {...user.general['user'][0], avatar: user.avatar.user[0].avatar}});
            }
          ),
          catchError((error) => of(fromUserActions.loadUserFailure({error})))
        );
      })
    )
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.addUser),
      mergeMap((action) =>
        this.userService.createUser(action.user).pipe(
          map((user) =>
            fromUserActions.addUserSuccess({
              user: {...action.user, id: user[0].toString(), entryCreated: new Date().toString()},
            })
          ),
          catchError((error) => of(fromUserActions.addUserFailure({error})))
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.updateUser),
      concatMap((action) =>
        this.userService.updateUser(action.user.id, action.user.changes).pipe(
          map(() => fromUserActions.loadUser({id: action.user.id})),
          catchError((error) => of(fromUserActions.addUserFailure({error})))
        )
      )
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.deleteUser),
      concatMap((action) =>
        this.userService.deleteUser(action.id).pipe(
          map(() => fromUserActions.loadUser({id: action.id})),
          catchError((error) => of(fromUserActions.addUserFailure({error})))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
  ) {
  }
}
