import {Component, OnInit} from '@angular/core';
import {IClientSynchronisation} from '@interfaces/iclient-synchronisation';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-synchronisations',
  templateUrl: './synchronisations.component.html',
  styleUrls: ['./synchronisations.component.scss']
})
export class SynchronisationsComponent implements OnInit {
  synchronisations: IClientSynchronisation[];
  params = {limit: 20, offset: 0};
  displayedColumnsSynchronisations: string[] =
    ['error', 'entryUpdated', 'started', 'client', 'fiscalYearsSynced', 'accountingSumsAndBalancesSynced',
      'accountsReceivableSynced', 'accountsPayableSynced', 'debitorsSynced', 'errorLog'];
  allSynchrosLoaded = false;
  isLoading = true;

  constructor(
    private requestManager: RequestManagerService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.requestManager.get('/adminArea/clientSynchronisations', this.params)
      .then(res => {
        this.synchronisations = res['clientSynchronisations'];
        this.isLoading = false;
      });
  }

  downloadErrorLog(element: any) {
    console.log(element);
  }

  loadMoreSynchronisations() {
    this.isLoading = true;
    this.params.offset += this.params.limit;
    this.requestManager.get('/adminArea/clientSynchronisations', this.params)
      .then(res => {
        if (!res['clientSynchronisations']) {
          this.allSynchrosLoaded = true;
          this.snackBar.open('Es wurden alle Synchronisationen geladen', 'Okay', {duration: 5000});
        }
        this.synchronisations = [...this.synchronisations, ...res['clientSynchronisations']];
        this.isLoading = false;
      });
  }
}
