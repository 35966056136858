<mat-list>
  <mat-list-item class="margin-12">
    <h3>
      <mat-form-field>
        <mat-label>Anrede</mat-label>
        <mat-select [(ngModel)]="newContact.salutation">
          <mat-option value="Herr">Herr</mat-option>
          <mat-option value="Frau">Frau</mat-option>
        </mat-select>
      </mat-form-field>

    </h3>
  </mat-list-item>
  <mat-list-item class="margin-12">
    <h3>
      <mat-form-field>
        <mat-label>Vorname</mat-label>
        <input matInput [(ngModel)]="newContact.name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nachname</mat-label>
        <input matInput [(ngModel)]="newContact.surname">
      </mat-form-field>
    </h3>
  </mat-list-item>
  <br>
  <mat-list-item class="margin-12">
    <mat-form-field class="width-100">
      <mat-label>Beschreibung</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="3"
        [(ngModel)]="newContact.description">
      </textarea>
    </mat-form-field>
  </mat-list-item>
  <mat-list-item class="margin-12">
    <mat-form-field class="width-100">
      <mat-label>E-Mail</mat-label>
      <input matInput [(ngModel)]="newContact.email">
    </mat-form-field>
  </mat-list-item>
  <mat-list-item class="margin-12">
    <mat-form-field class="width-100">
      <mat-label>Telefon</mat-label>
      <input matInput [(ngModel)]="newContact.phone">
    </mat-form-field>
  </mat-list-item>
  <mat-list-item *ngIf="contact">
    <div class="flex-column margin-12">
      <div>Erstellt am: {{contact.entryCreated | amFromUtc  | amLocal | amDateFormat: 'DD.MM.YYYY HH:mm'}}</div>
      <div>Letzte Änderung: {{contact.entryUpdated | amFromUtc  | amLocal | amDateFormat: 'DD.MM.YYYY HH:mm'}}</div>
    </div>
  </mat-list-item>
</mat-list>

<div class="flex-between margin-top-12">
  <button mat-raised-button (click)="dialog.closeAll()">Abbrechen</button>
  <button *ngIf="contact" mat-raised-button color="warn" (click)="deleteContact()">Löschen</button>
  <button mat-raised-button color="primary" (click)="saveContact()">Speichern</button>
</div>
