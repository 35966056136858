<div mat-dialog-title class="modal-title">
  Benutzerbild ändern
</div>
<hr class="hr-margin-bottom">

<div class="dialog-container">
    <input (change)="fileChangeEvent($event)" type="file">
    <div class="flex-align-center height-100">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [roundCropper]="true"
        format="png"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>
      <div class="footer-container">
        <button *ngIf="imageChangedEvent" (click)="close()" mat-raised-button color="warn">Abbrechen</button>
        <button *ngIf="imageChangedEvent" (click)="saveNewAvatar()" mat-raised-button color="primary">Speichern</button>
      </div>
    </div>
  </div>
