import {Component, OnInit} from '@angular/core';
import {IClient} from '@interfaces/iclient';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {ClientAddBottomSheetComponent} from './client-add-bottom-sheet/client-add-bottom-sheet.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-client-add-client',
  templateUrl: './client-add-client.component.html',
  styleUrls: ['./client-add-client.component.scss']
})
export class ClientAddClientComponent implements OnInit {
  addableClientsList: IClient[];
  displayedColumns: string[] = ['number', 'name', 'add'];
  searchTerm: string;
  filteredAddableClientList: IClient[];
  isLoading = true;

  constructor(
    private bottomSheet: MatBottomSheet,
    private requestManager: RequestManagerService,
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.requestManager.get('/adminArea/availableClients')
      .then(addableClientsList => {
          addableClientsList = addableClientsList.body;
          this.addableClientsList = addableClientsList;
          this.filteredAddableClientList = this.addableClientsList;
          this.isLoading = false;
        }
      );
  }

  addClientBottomSheet(client: IClient) {
    this.bottomSheet.open(ClientAddBottomSheetComponent, {
      data: {client},
    });
  }

  /**
   * Filter client-array by id, name, vatId, entryCreated and save results to filteredClientList
   */
  filter() {
    this.filteredAddableClientList = this.addableClientsList.filter(
      el =>
        (el.name && el.name.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
        (el.number && el.number.toString().toLowerCase().includes(this.searchTerm.toLowerCase()))
    );
  }
}
