import {Component, Inject, OnInit} from '@angular/core';
import {IClient} from '@interfaces/iclient';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-add-bottom-sheet',
  templateUrl: './client-add-bottom-sheet.component.html',
  styleUrls: ['./client-add-bottom-sheet.component.scss']
})
export class ClientAddBottomSheetComponent implements OnInit {
  public client: IClient;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheet: MatBottomSheet,
    private snackbar: MatSnackBar,
    private requestManager: RequestManagerService,
  ) {
    this.client = data.client;
  }

  ngOnInit() {
  }

  closeBottomSheet() {
    this.bottomSheet.dismiss();
  }

  async addNewClient() {
    try {
      await this.requestManager.post('/adminArea/client/' + this.client.id, {});
    } catch (e) {
      if (e.status === 409) {
        this.snackbar.open('Für diesen Mandanten konnten keine Wirtschaftsjahre gefunden werden.', 'Schließen', {duration: 10000});
      } else if (e.status === 404) {
        this.snackbar.open('Der Mandant konnte nicht gefunden werden.', 'Schließen', {duration: 10000});
      } else if (e.status === 400) {
        this.snackbar.open('Die Kombination aus Mandantennummer und Beraternummer existiert bereits und kann daher nicht erneut hinzugefügt werden.', 'Schließen', {duration: 10000});
      }
    }
    this.bottomSheet.dismiss();
  }
}
