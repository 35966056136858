<div class="flex-container">

  <div class="margin-24">
    <img src="../../../assets/img/logo.png" alt="">
  </div>
  <p class="info-text">
    Hallo {{this.user ? this.user.name + ' ' + this.user.surname : ''}}, willkommen im Adminbereich von Taxflow!
  </p>
  <p class="info-text">
    Hier kannst du alle Einstellungen für deine Taxflow-Instanz festlegen. Du kannst Mandanten und Benutzer anlegen,
    bearbeiten und zuweisen, Abteilungen erstellen und Auswertungen konfigurieren. Zudem kannst du pro Mandant die gewünschten Module aktivieren.
  </p>

  <mat-grid-list style="width: 65%" cols="2" rowHeight="75px">
    <mat-grid-tile>
      <button (click)="this.router.navigateByUrl('/client')" mat-raised-button color="primary" class="big-btn">
        Mandanten
      </button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button (click)="this.router.navigateByUrl('/user')" mat-raised-button color="primary" class="big-btn">
        Benutzer
      </button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button (click)="this.router.navigateByUrl('/datev-info')" mat-raised-button color="primary" class="big-btn">
        DATEV Info
      </button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button (click)="this.router.navigateByUrl('/debug')" mat-raised-button color="primary" class="big-btn">
        Debug
      </button>
    </mat-grid-tile>
  </mat-grid-list>

</div>
