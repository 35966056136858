import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IClientSettings} from '@interfaces/iclient-settings';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {ClientService} from '@services/client/client.service';
import {IUser} from '@interfaces/iuser';
import {IClient} from '@interfaces/iclient';

import {ClientAddAccountingContactComponent} from '../../client-add-accounting-contact/client-add-accounting-contact.component';
import {DoOcrDialogComponent} from './do-ocr-dialog/do-ocr-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {AngularEditorConfig} from '@kolkov/angular-editor';
import {forkJoin} from 'rxjs';
import {InfoDialogComponent} from './info-dialog/info-dialog.component';

@Component({
  selector: 'app-client-settings',
  templateUrl: './client-settings.component.html',
  styleUrls: ['./client-settings.component.scss'],
})
export class ClientSettingsComponent implements OnInit {
  @Input() users: IUser[];
  @Input() client: IClient;
  @Input() documentStatesDescription: any[];
  @Input() dmsRegisters: any[];
  @Input() dmsRegistersSetting: any;
  @Input() selectedRegisterValue: string;
  @Input() documentStateSetting: any;
  @Input() documentStates: any[];
  @Input() selectedStateValue: string;
  @Input() sonstiges: boolean;
  @Input() releaseInvoices: boolean;
  @Input() evaluations: boolean;
  @Input() dms: boolean;
  @Input() active: any;

  @ViewChild('text') textArea: ElementRef;

  public settingsCanSee: IClientSettings[];
  public settingsDefaultViewMonth: IClientSettings[];
  public settingsDefaultViewYear: IClientSettings[];
  public settinguploadToDATEVAfterStep: IClientSettings[];
  public settingSynchronisationPeriod: IClientSettings[];
  public settingsAccountingContact: IClientSettings[];
  public settingDoOcr: IClientSettings;
  public settingAutomaticEmailReply: IClientSettings;
  public settingAutomaticEmailReplyText: IClientSettings;
  public settingAutomaticEmailReplySubject: IClientSettings;
  public settingUploadToDMSInsteadDUO: IClientSettings;
  public settingInvoiceRegisterId: IClientSettings;
  public settingCreatePostingProposals: IClientSettings;
  public settingsSynchronisationValues = [
    '2 hours',
    '4 hours',
    '6 hours',
    '8 hours',
    '12 hours',
    '24 hours',
    '48 hours',
    '72 hours',
    '120 hours',
    '168 hours',
  ];
  public settingSynchronisationPeriodValue: string;
  public settinguploadToDATEVAfterStepValue: string;
  public settingDefaultViewDataMonthAccountingAnalysis: string;
  public settingDefaultViewDataMonthOPOS: string;
  public registerIsNull = false;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Hier Text eingeben ...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ]
  };

  constructor(
    private requestManager: RequestManagerService,
    private clientService: ClientService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
  }

  @Input() set settings(value: IClientSettings[]) {
    if (value) {
      this.settingsCanSee = value
        .filter((setting) => setting.settingName.includes('canSeeLiveValues'))
        .sort((a, b) => a.settingName.localeCompare(b.settingName));
      this.settingsDefaultViewMonth = value
        .filter((setting) =>
          setting.settingName.includes('defaultViewDataMonth')
        )
        .sort((a, b) => a.settingName.localeCompare(b.settingName));
      this.settingDefaultViewDataMonthAccountingAnalysis = this.settingsDefaultViewMonth[0]?.settingValue;
      this.settingDefaultViewDataMonthOPOS = this.settingsDefaultViewMonth[1]?.settingValue;
      this.settingsDefaultViewYear = value
        .filter((setting) =>
          setting.settingName.includes('defaultViewDataYear')
        )
        .sort((a, b) => a.settingName.localeCompare(b.settingName));
      this.settinguploadToDATEVAfterStep = value
        .filter((setting) =>
          setting.settingName.includes('uploadToDATEVAfterStep')
        )
        .sort((a, b) => a.settingName.localeCompare(b.settingName));
      this.settinguploadToDATEVAfterStepValue = this.settinguploadToDATEVAfterStep[0]?.settingValue;
      this.settingSynchronisationPeriod = value
        .filter((setting) =>
          setting.settingName.includes('synchronisationPeriod')
        )
        .sort((a, b) => a.settingName.localeCompare(b.settingName));
      this.settingSynchronisationPeriodValue = this.settingSynchronisationPeriod[0]?.settingValue;
      this.settingsAccountingContact = value
        .filter((setting) => setting.settingName.includes('accountingContact'))
        .filter((setting) => this.users?.find((u) => u.userId === setting.settingValue) != null) // Filter non-existing users
        .sort((a, b) => a.settingName.localeCompare(b.settingName));
      this.settingDoOcr = value.find(
        (setting) => setting.settingName === 'doOCR'
      );
      this.settingAutomaticEmailReply = value.find((setting) => setting.settingName === 'automaticEmailReply');
      this.settingAutomaticEmailReplyText = value.find((setting) => setting.settingName === 'automaticEmailReplyText');
      this.settingAutomaticEmailReplySubject = value.find((setting) => setting.settingName === 'automaticEmailReplySubject');
      this.settingUploadToDMSInsteadDUO = value.find((setting) => setting.settingName.includes('uploadToDMSInsteadDUO'));
      this.settingInvoiceRegisterId = value.find((setting) => setting.settingName.includes('invoiceRegisterId'));
      this.settingCreatePostingProposals = value.find((setting) => setting.settingName.includes('createPostingProposals'));
    }
  }

  async selectState(event: any) {
    const id = this.documentStateSetting.id;
    let body;
    this.documentStates.forEach((state: any) => {
      if (state.description === event) {
        body = {
          settingValue: state.id,
        };
      }
    });
    await this.requestManager.put('/clientSettings/' + id, body);
  }

  async selectRegister(event: any) {
    const id = this.dmsRegistersSetting.id;
    let body;
    let dmsRegistersSettingValue = null;
    this.dmsRegisters.forEach((register: any) => {
      if (register.name === event.name && register.path === event.path) {
        dmsRegistersSettingValue = register.name + ' ' + register.path;
        body = {
          settingValue: register.registerId,
        };
      }
    });
    await this.requestManager.put('/clientSettings/' + id, body)
      .then(async () => {
        this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
        if (this.dmsRegistersSetting.settingValue === null) {
          const obj = {settingValue: undefined};
          obj.settingValue = this.settingUploadToDMSInsteadDUO.settingValue === null || this.settingUploadToDMSInsteadDUO.settingValue === '0' ? '1' : '0';
          if (dmsRegistersSettingValue !== null) {
            this.dmsRegistersSetting.settingValue = dmsRegistersSettingValue;
          }
          await this.requestManager.put('/clientSettings/' + this.settingUploadToDMSInsteadDUO.id, obj)
            .then(() => {
              this.settingUploadToDMSInsteadDUO.settingValue =
                this.settingUploadToDMSInsteadDUO.settingValue === null || this.settingUploadToDMSInsteadDUO.settingValue === '0' ? '1' : '0';
              if (this.registerIsNull) {
                this.registerIsNull = false;
              }
              this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
            })
            .catch(() => {
              this.snackBar.open('Die Änderung ist fehlgeschlagen!')._dismissAfter(4000);
            });
        }
      })
      .catch(() => {
        this.snackBar.open('Die Änderung ist fehlgeschlagen!')._dismissAfter(4000);
      });
  }

  // TODO: use this everywhere, convention: '1' for true setting, '0' for false setting
  isSettingValueTrue(setting: IClientSettings) {
    return setting.settingValue === '1';
  }

  async openDialog(): Promise<boolean> {
    const dialogRef = this.dialog.open(DoOcrDialogComponent, {
      width: '250px',
    });

    return await dialogRef.afterClosed().toPromise();
  }

  async toggleSettingDoOcr(setting: IClientSettings) {
    // If setting is changed to true, open info dialog
    if (!this.isSettingValueTrue(setting)) {
      const areYouSure = await this.openDialog();
      if (!areYouSure) {
        return;
      }
    }

    const putBody = {settingValue: undefined};
    putBody.settingValue = this.isSettingValueTrue(setting) ? '0' : '1';

    await this.requestManager.put('/clientSettings/' + setting.id, putBody);
    this.clientService.update.next('settings');
    this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
  }

  async onSaveEmailReplyText() {
    const textBody = {settingValue: undefined};
    textBody.settingValue = this.settingAutomaticEmailReplyText.settingValue;
    const subjectBody = {settingValue: undefined};
    subjectBody.settingValue = this.settingAutomaticEmailReplySubject.settingValue;
    let error = false;
    await forkJoin({
      r1: this.requestManager.put('/clientSettings/' + this.settingAutomaticEmailReplyText.id, textBody).catch(() => {
        error = true;
      }),
      r2: this.requestManager.put('/clientSettings/' + this.settingAutomaticEmailReplySubject.id, subjectBody).catch(() => {
        error = true;
      })
    }).subscribe(() => {
      if (!error) {
        this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
      } else {
        this.snackBar.open('Die Änderung ist fehlgeschlagen!')._dismissAfter(4000);
      }
    });
  }

  async toggleSetting(setting: IClientSettings) {
    if (setting.settingName === 'automaticEmailReply') {
      const body = {settingValue: undefined};
      body.settingValue = setting.settingValue === null || setting.settingValue === '0' ? '1' : '0';
      await this.requestManager.put('/clientSettings/' + setting.id, body)
        .then(() => {
          this.settingAutomaticEmailReply.settingValue = setting.settingValue === null || setting.settingValue === '0' ? '1' : '0';
          this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
        })
        .catch(() => {
          this.snackBar.open('Die Änderung ist fehlgeschlagen!')._dismissAfter(4000);
        });
      return;
    }
    if (setting.settingName === 'uploadToDMSInsteadDUO') {
      const body = {settingValue: undefined};
      body.settingValue = setting.settingValue === null || setting.settingValue === '0' ? '1' : '0';
      if (this.dmsRegistersSetting.settingValue === null) {
        this.registerIsNull = true;
        this.snackBar.open('Um die Option zu aktivieren, noch ein Register auswählen.')._dismissAfter(10000);
        return;
      }
      await this.requestManager.put('/clientSettings/' + setting.id, body)
        .then(() => {
          this.settingUploadToDMSInsteadDUO.settingValue = setting.settingValue === null || setting.settingValue === '0' ? '1' : '0';
          this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
        })
        .catch(() => {
          this.snackBar.open('Die Änderung ist fehlgeschlagen!')._dismissAfter(4000);
        });
      return;
    }
    if (setting.settingName === 'createPostingProposals') {
      const body = {settingValue: undefined};
      body.settingValue = setting.settingValue === null || setting.settingValue === '0' ? '1' : '0';
      await this.requestManager.put('/clientSettings/' + setting.id, body)
        .then(() => {
          this.settingCreatePostingProposals.settingValue = setting.settingValue === null || setting.settingValue === '0' ? '1' : '0';
          this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
        })
        .catch(() => {
          this.snackBar.open('Die Änderung ist fehlgeschlagen!')._dismissAfter(4000);
        });
      return;
    }
    const tmp = setting.settingName.substr(16, setting.settingName.length);
    const defaultSetting = this.settingsDefaultViewMonth.find((set) =>
      set.settingName.includes(tmp)
    );
    const putBody = {settingValue: undefined};
    putBody.settingValue = setting.settingValue === 'true' ? 'false' : 'true';

    if (
      defaultSetting.settingValue === 'live' &&
      putBody.settingValue === 'false'
    ) {
      this.snackBar.open('Ändern Sie zu erst den Standardwert!');
    } else {
      await this.requestManager.put('/clientSettings/' + setting.id, putBody);
      this.clientService.update.next('settings');
      this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
    }
  }

  async editDefaultViewDataMonthAccountAnalysis(setting: string) {
    const putBody = {
      settingValue: setting
    };
    await this.requestManager.put('/clientSettings/' + this.settingsDefaultViewMonth[0].id, putBody);
    this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
  }

  async editDefaultViewDataMonthOPOS(setting: string) {
    const putBody = {
      settingValue: setting
    };
    await this.requestManager.put('/clientSettings/' + this.settingsDefaultViewMonth[1].id, putBody);
    this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
  }

  async editDefaultViewValue(setting: string) {
    const putBody = {
      settingValue: setting,
    };
    await this.requestManager.put('/clientSettings/' + this.settinguploadToDATEVAfterStep[0].id, putBody);
    this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
  }

  getDefaultListForSetting(setting: IClientSettings) {
    const settingsValues = [
      'lastMonth',
      'secondLastMonth',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
    const settingName = setting.settingName.replace('defaultViewDataMonth', '');
    if (
      this.settingsCanSee.find((canSee) =>
        canSee.settingName.includes(settingName)
      ).settingValue === 'true'
    ) {
      settingsValues.unshift('live');
    }
    return settingsValues;
  }

  async editSettingSynchronisationPeriodValue(setting: string) {
    const putBody = {
      settingValue: setting,
    };
    await this.requestManager.put('/clientSettings/' + this.settingSynchronisationPeriod[0].id, putBody);
    this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
  }

  editAccountingContact(accountingContact) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '800px';
    dialogConfig.data = {
      users: this.users,
      client: this.client,
      isPutMode: accountingContact !== undefined,
      accountingContact: accountingContact,
    };

    this.dialog.open(ClientAddAccountingContactComponent, dialogConfig);
  }

  openInfoModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '650px';
    this.dialog.open(InfoDialogComponent, dialogConfig);
  }
}
