import {Pipe, PipeTransform} from '@angular/core';
import {IUser} from '@interfaces/iuser';

@Pipe({
  name: 'toUser'
})
export class ToUserPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const users = args[0];
    const getBy = args[1];
    const back = args[2];
    let user: IUser;

    switch (getBy) {
      case 'id':
      default:
        user = users.find((u) => u.userId == value);
        break;
    }

    switch (back) {
      case 'name':
      default:
        return `${user['user'][0].name} ${user['user'][0].surname}`;
    }
  }

}
