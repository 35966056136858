import {Component, Inject, OnInit} from '@angular/core';

import * as _ from 'lodash';

import {IContact} from '@interfaces/icontact';

import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-edit-contact',
  templateUrl: './client-edit-contact.component.html',
  styleUrls: ['./client-edit-contact.component.scss']
})
export class ClientEditContactComponent implements OnInit {
  public contact: IContact;
  public  newContact: IContact;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ClientEditContactComponent>,
    private requestManager: RequestManagerService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {

    if (data.contact) {
      this.contact = data.contact;
    } else {
      this.newContact = {
        clientId: data.clientId,
        salutation: undefined,
        name: undefined,
        surname: undefined,
        description: undefined,
        email: undefined,
        phone: undefined
      };
    }
  }

  ngOnInit() {
    if (this.contact) {
      this.newContact = _.clone(this.contact);
    }
  }

  async saveContact() {
    if (this.contact) {
      const putBody = {
        salutation: this.newContact.salutation,
        name: this.newContact.name,
        surname: this.newContact.surname,
        description: this.newContact.description,
        email: this.newContact.email,
        phone: this.newContact.phone
      };
      await this.requestManager.put('/clientContacts/' + this.contact.id, putBody);
      this.snackBar.open('Kontakt erfolgreich geändert!')._dismissAfter(3000);
      this.dialog.closeAll();
    } else {
      await this.requestManager.post('/clientContacts', this.newContact);
      this.snackBar.open('Kontakt erfolgreich erstellt!')._dismissAfter(3000);
      this.dialog.closeAll();
    }
  }

  async deleteContact() {
    await this.requestManager.delete('/clientContacts/' + this.contact.id);
    this.snackBar.open('Kontakt erfolgreich gelöscht!')._dismissAfter(3000);
    this.dialog.closeAll();
  }
}
