import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MaterialModule} from './sharedModules/material/material.module';

import {NgxCopyPasteModule} from 'ngx-copypaste';
import {MomentModule} from 'angular2-moment';
import {NgxUiLoaderModule} from 'ngx-ui-loader';

import {LoginComponent} from '@components/login/login.component';
import {DashboardComponent} from '@components/dashboard/dashboard.component';
import {DebugComponent} from '@components/debug/debug.component';

import {CountryCodeToCountryPipe} from '@pipes/country-code-to-country.pipe';
import {SynchronisationsComponent} from '@components/debug/synchronisations/synchronisations.component';
import {UploadsComponent} from '@components/debug/uploads/uploads.component';
import {DatevInfoComponent} from '@components/datev-info/datev-info.component';
import {environment} from '../environments/environment';

import {EffectsModule} from '@ngrx/effects';
import {ClientsModule} from './clients/clients.module';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {UsersModule} from './users/users.module';

import {CommonModule} from '@angular/common';
import {authInterceptorProviders} from './interceptors/auth-interceptor';

import {AngularEditorModule} from '@kolkov/angular-editor';
import { SettingsComponent } from './components/settings/settings.component';

@NgModule({
  entryComponents: [],
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CountryCodeToCountryPipe,
    DebugComponent,
    // ToUserPipe,
    SynchronisationsComponent,
    UploadsComponent,
    DatevInfoComponent,
    SettingsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxCopyPasteModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    MomentModule,
    AngularEditorModule,
    NgxUiLoaderModule.forRoot(
      {
        blur: 5,
        fgsColor: '#00baff',
        fgsPosition: 'center-center',
        fgsSize: 150,
        fgsType: 'rectangle-bounce-pulse-out',
        gap: 24,
        overlayBorderRadius: '0',
        overlayColor: 'rgba(40, 40, 40, 0.8)',
        hasProgressBar: false,
        text: 'Verbindung zu Unternehmen Online wird aufgebaut',
        textColor: '#00baff',
        textPosition: 'center-center',
      }
    ),
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    EffectsModule.forRoot([]),
    ClientsModule,
    UsersModule,
  ],
  providers: [
    authInterceptorProviders
  ],
  exports: [
    CountryCodeToCountryPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
