import {Component, OnInit} from '@angular/core';
import {IUser} from '@interfaces/iuser';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SuperForm} from 'angular-super-validator';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import * as _ from 'lodash';
import {UserService} from '@services/user/user.service';
import {UserState} from '../../store/user.reducer';
import {select, Store} from '@ngrx/store';
import {addUser, clearUserFailure} from '../../store/user.actions';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {getError} from '../../store/user.selectors';

@Component({
  selector: 'app-user-create-dialog',
  templateUrl: './user-create-dialog.component.html',
  styleUrls: ['./user-create-dialog.component.scss']
})
export class UserCreateDialogComponent implements OnInit {
  private errors: {};
  private user: IUser;
  public userFormGroup: FormGroup;
  public imageOptions: any;
  imageChangedEvent: any = '';
  usernamePattern = '^[a-zA-Z][A-Za-z0-9.\-]+[a-zA-Z0-9]$';

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private requestManager: RequestManagerService,
    private userService: UserService,
    private store: Store<UserState>,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.userFormGroup = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      surname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.email, Validators.required]),
      phone: new FormControl(''),
      avatar: new FormControl(''),
      visibleInMessenger: new FormControl(false, Validators.required),
      isAdmin: new FormControl(false, Validators.required),
      username: new FormControl('', [Validators.minLength(4), Validators.required, Validators.pattern(this.usernamePattern)]),
    });

    // this.user = { isAdmin: false, visibleInMessenger: false} as IUser; // TODO: uncomment when column isAdmin available
    this.user = {visibleInMessenger: false} as IUser;
    this.imageOptions = {
      minHeight: 200,  // minimum height of image that can be uploaded (by default 0, signifies any height)
      maxHeight: 200,  // maximum height of image that can be uploaded (by default 0, signifies any height)
      height: 400, // height of cropper
      crop: [  // array of objects for multiple image crop instances (by default null, signifies no cropping)
        {
          ratio: 1, // ratio in which image needed to be cropped (by default null, signifies ratio to be free of any restrictions)
          width: 0,  // width of image to be exported (by default 0, signifies any width)
          height: 0,  // height of image to be exported (by default 0, signifies any height)
        }
      ]
    };
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.user.avatar = event.base64;
    this.user.avatar = this.user.avatar.split(',')[1];
    this.userFormGroup.controls.avatar.setValue(this.user.avatar);
  }

  async postNewUser() {
    this.errors = SuperForm.getAllErrors(this.userFormGroup);

    if (_.isEmpty(this.errors)) {
      const postBody = _.clone(this.userFormGroup.value);
      delete postBody.isAdmin;
      postBody.visibleInMessenger = postBody.visibleInMessenger ? '1' : '0';
      postBody.isFirstLogin = '1';

      this.store.dispatch((addUser({user: postBody})));
      const finished = this.store.pipe(select(getError)).subscribe(
        err => {
          if (err !== undefined && err !== null && err.status === 409) {
            this.snackBar.open('' + err.error)._dismissAfter(5000);
            this.store.dispatch((clearUserFailure()));
          }
        }
      );
      if (finished !== undefined) {
        this.dialog.closeAll();
      }
    }
  }
}
