import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '@services/user/user.service';
import {IUser} from '@interfaces/iuser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  user: IUser;

  constructor(
    public router: Router,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.userService.getLoggedInUser()
      .then(user =>
        this.user = user
      );
  }

}
