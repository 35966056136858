import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {ClientService} from '@services/client/client.service';

import {IClient} from '@interfaces/iclient';
import {SubSink} from 'subsink';
import {ClientAddClientComponent} from '../client-add-client/client-add-client.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Component({
  selector: 'app-client-overview',
  templateUrl: './client-overview.component.html',
  styleUrls: ['./client-overview.component.scss']
})
export class ClientOverviewComponent implements OnInit, OnDestroy {
  searchTerm: string;
  displayedColumns: string[] = ['id', 'name', 'vatId', 'entryCreated'];
  isLoading = true;
  clientList: IClient[];
  filteredClientList: IClient[];
  private dialogSub = new SubSink();

  constructor(
    public clientService: ClientService,
    private router: Router,
    private dialog: MatDialog,
  ) {
  }

  async ngOnInit() {
    await this.loadClientList();
    this.dialogSub.add(this.dialog.afterAllClosed.subscribe(() => this.loadClientList()));
    this.isLoading = false;
  }

  async loadClientList() {
    this.isLoading = true;
    this.clientList = await this.clientService.getClientList();
    this.clientList = this.clientList.filter((client: any) => client.active === '1');
    this.filteredClientList = this.clientList;
    this.isLoading = false;
  }

  /**
   * Navigate to client-single route
   * @param row client-object needed to get user id for route
   */
  openSingleClient(row: any) {
    this.router.navigateByUrl('client/' + row.id);
  }

  /**
   * Filter client-array by id, name, vatId, entryCreated and save results to filteredClientList
   */
  filter() {
    this.filteredClientList = this.clientList.filter(
      el =>
        (el.id && el.id.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
        (el.name && el.name.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
        (el.vatId && el.vatId.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
        (el.entryCreated && el.entryCreated.toLowerCase().includes(this.searchTerm.toLowerCase()))
    );
  }

  /**
   * Open add client dialog
   */
  public async addClientDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '800px';
    this.dialog.open(ClientAddClientComponent, dialogConfig);
  }

  ngOnDestroy() {
    this.dialogSub.unsubscribe();
  }
}
