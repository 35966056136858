import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-client-create-department-info',
  templateUrl: './client-create-department-info.component.html',
  styleUrls: ['./client-create-department-info.component.scss']
})
export class ClientCreateDepartmentInfoComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ClientCreateDepartmentInfoComponent>
  ) {
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
