/* tslint:disable:no-unnecessary-initializer */
import {Component, Input, OnInit} from '@angular/core';
import {IUser} from '@interfaces/iuser';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {IClient} from '@interfaces/iclient';
import {IDepartment} from '@interfaces/idepartment';

import {ClientAddUserComponent} from '../../client-add-user/client-add-user.component';
import {ClientEditUserSettingsDialogComponent} from '../../client-edit-user-settings-dialog/client-edit-user-settings-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Component({
  selector: 'app-client-users',
  templateUrl: './client-users.component.html',
  styleUrls: ['./client-users.component.scss']
})
export class ClientUsersComponent implements OnInit {
  @Input() users: IUser[];
  @Input() client: IClient;
  @Input() departments: IDepartment[];
  @Input() isLoading: boolean;
  @Input() hasInvoiceRight: boolean;

  displayedColumnsUser: string[] = ['id', 'name', 'email', 'phone', 'entryCreated'];

  constructor(
    private dialog: MatDialog,
    private requestManager: RequestManagerService,
  ) {
  }

  ngOnInit() {
  }

  addUserToClientDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '800px';
    dialogConfig.maxHeight = '70vh';
    dialogConfig.data = {
      existingUsers: this.users,
      client: this.client,
      departments: this.departments,
    };

    this.dialog.open(ClientAddUserComponent, dialogConfig);
  }

  async openEditUserDialog(user) {
    user = user.user[0] as IUser;
    if (user.deleted != '1') {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.minWidth = '850px';
      // dialogConfig.disableClose = true;

      const departments = await this.requestManager
        .get('/adminArea/user/' + user.id + '/client/' + this.client.id + '/departments', {array_values: true});

      let userDepartments;
      let userDepartmentsRights;

      if (departments.length == 0) {
        userDepartments = [];
        userDepartmentsRights = [];
      } else {
        userDepartments = departments.user[0].userDepartments.map(el => {
          return {...el.departments[0], tableId: el.id};
        });
        userDepartmentsRights = departments.user[0].userDepartmentsRights;
      }


      let userRightsForClient = await this.requestManager
        .get('/adminArea/user/' + user.id + '/client/' + this.client.id + '/userRightsForClient', {array_values: true});
      if (userRightsForClient.length == 0) {
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeeOPOS', settingValue: '0', userId: user.id, clientId: this.client.id
        });
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeeAccounting', settingValue: '0', userId: user.id, clientId: this.client.id
        });
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeeVoucherScan', settingValue: '0', userId: user.id, clientId: this.client.id
        });
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeeVoucherApproval', settingValue: '0', userId: user.id, clientId: this.client.id
        });
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeeCompany', settingValue: '0', userId: user.id, clientId: this.client.id
        });
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeeDMS', settingValue: '0', userId: user.id, clientId: this.client.id
        });
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeePaymentTransactions', settingValue: '0', userId: user.id, clientId: this.client.id
        });
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'pushForNewInvoiceActive', settingValue: '0', userId: user.id, clientId: this.client.id
        });
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'mailForNewInvoiceActive', settingValue: '0', userId: user.id, clientId: this.client.id
        });
        userRightsForClient = await this.requestManager
          .get('/adminArea/user/' + user.id + '/client/' + this.client.id + '/userRightsForClient', {array_values: true});
      } else {
        const userRights = [];
        userRightsForClient.user[0].userRightsForClient.forEach(value => {
          userRights.push(value.settingName);
        });
        if (!userRights.includes('canSeeAccounting')) {
          await this.requestManager.post('/userRightsForClient', {
            settingName: 'canSeeAccounting', settingValue: '0', userId: user.id, clientId: this.client.id
          });
        }
        if (!userRights.includes('canSeeCompany')) {
          await this.requestManager.post('/userRightsForClient', {
            settingName: 'canSeeCompany', settingValue: '0', userId: user.id, clientId: this.client.id
          });
        }
        if (!userRights.includes('canSeeOPOS')) {
          await this.requestManager.post('/userRightsForClient', {
            settingName: 'canSeeOPOS', settingValue: '0', userId: user.id, clientId: this.client.id
          });
        }
        if (!userRights.includes('canSeeVoucherApproval')) {
          await this.requestManager.post('/userRightsForClient', {
            settingName: 'canSeeVoucherApproval', settingValue: '0', userId: user.id, clientId: this.client.id
          });
        }
        if (!userRights.includes('canSeeVoucherScan')) {
          await this.requestManager.post('/userRightsForClient', {
            settingName: 'canSeeVoucherScan', settingValue: '0', userId: user.id, clientId: this.client.id
          });
        }
        if (!userRights.includes('canSeeDMS')) {
          await this.requestManager.post('/userRightsForClient', {
            settingName: 'canSeeDMS', settingValue: '0', userId: user.id, clientId: this.client.id
          });
        }
        if (!userRights.includes('canSeePaymentTransactions')) {
          await this.requestManager.post('/userRightsForClient', {
            settingName: 'canSeePaymentTransactions', settingValue: '0', userId: user.id, clientId: this.client.id
          });
        }
        if (!userRights.includes('pushForNewInvoiceActive')) {
          await this.requestManager.post('/userRightsForClient', {
            settingName: 'pushForNewInvoiceActive', settingValue: '0', userId: user.id, clientId: this.client.id
          });
        }
        if (!userRights.includes('mailForNewInvoiceActive')) {
          await this.requestManager.post('/userRightsForClient', {
            settingName: 'mailForNewInvoiceActive', settingValue: '0', userId: user.id, clientId: this.client.id
          });
        }
        userRightsForClient = await this.requestManager
          .get('/adminArea/user/' + user.id + '/client/' + this.client.id + '/userRightsForClient', {array_values: true});
      }
      userRightsForClient = userRightsForClient.user[0].userRightsForClient;

      dialogConfig.data = {
        client: this.client,
        user: user,
        userDepartments,
        userDepartmentsRights,
        userRightsForClient,
        allDepartments: this.departments,
        hasInvoiceRight: this.hasInvoiceRight,
      };

      this.dialog.open(ClientEditUserSettingsDialogComponent, dialogConfig);
    }
  }
}
