import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserState} from '../../store/user.reducer';
import {deleteUser} from '../../store/user.actions';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {UserService} from '@services/user/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-delete-dialog',
  templateUrl: './user-delete-dialog.component.html',
  styleUrls: ['./user-delete-dialog.component.scss']
})
export class UserDeleteDialogComponent implements OnInit {
  public data: any;

  constructor(
    public store: Store<UserState>,
    private dialogRef: MatDialogRef<UserDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {
    this.data = data;
  }

  ngOnInit(): void {
  }

  /**
   * Delete user and close dialog
   */
  async deleteUser() {
    const user = await this.userService.getLoggedInUser();
    if (user.id === this.data.id) {
      this.snackBar.open('Der Benutzer kann sein eigenes Konto nicht löschen.')._dismissAfter(5000);
      return;
    }

    this.store.dispatch(deleteUser({id: this.data.id}));
    this.dialogRef.close();
    setTimeout( () => {
      this.router.navigate(['/user']);
    }, 500 );
  }
}
