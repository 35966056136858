<div class="page-container">
  <div class="logo-container">
    <img class="login-logo" src="assets/img/logo_vector.svg" alt="">
  </div>
  <div class="admin-text-container">
    <h4 class="admin-text">Adminbereich</h4>
  </div>

  <div class="content-container" fxLayoutAlign="center center">
    <mat-card class="login-card">
      <form class="example-form" [formGroup]="loginFormGroup" (ngSubmit)="login()">
        <mat-card-title>Willkommen</mat-card-title>
        <mat-card-subtitle>Login</mat-card-subtitle>
        <mat-card-content>
          <mat-form-field class="input-full-width">
            <input matInput placeholder="Kanzlei Identifikation" formControlName="url">
            <mat-error *ngIf="loginFormGroup.get('url').hasError('required')">
              Kanzlei Identifikation wird benötigt
            </mat-error>
          </mat-form-field>
          <mat-form-field class="input-full-width">
            <input matInput placeholder="Benutzername" formControlName="username">
            <mat-error *ngIf="loginFormGroup.get('username').hasError('required')">
              Benutzername wird benötigt
            </mat-error>
          </mat-form-field>
          <mat-form-field class="input-full-width">
            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Passwort" formControlName="password">
            <mat-icon style="cursor: pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
            <mat-error *ngIf="loginFormGroup.get('password').hasError('required')">
              Password wird benötigt
            </mat-error>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
          <div *ngIf="!loginLoading" class="login-button-container">
            <button type="submit" mat-raised-button color="primary" [disabled]="!loginFormGroup.valid">Einloggen
            </button>
          </div>
          <div *ngIf="loginLoading" class="login-button-container">
            <mat-spinner [diameter]="40"></mat-spinner>
          </div>
        </mat-card-actions>
        <mat-card-footer></mat-card-footer>
      </form>
    </mat-card>
  </div>
</div>
