import { Injectable } from '@angular/core';

const ACCESS_TOKEN = 'access-token';
const REFRESH_TOKEN = 'refresh-token';
const URL = 'url';
@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  signOut(): void {
    window.localStorage.clear();
  }

  signOutAndReload(): void {
    this.signOut();
    location.reload();
  }

  public isValid(): boolean {
    return window.localStorage.getItem(ACCESS_TOKEN) !== null
      && window.localStorage.getItem(URL) !== null
      && window.localStorage.getItem(REFRESH_TOKEN) !== null;
  }

  public saveAccessToken(token: string): void {
    window.localStorage.removeItem(ACCESS_TOKEN);
    if (token !== undefined) {
      window.localStorage.setItem(ACCESS_TOKEN, token);
    }
  }

  public getAccessToken(): string | null {
    return window.localStorage.getItem(ACCESS_TOKEN);
  }

  public saveRefreshToken(token: string): void {
    window.localStorage.removeItem(REFRESH_TOKEN);
    if (token !== undefined) {
      window.localStorage.setItem(REFRESH_TOKEN, token);
    }
  }

  public getRefreshToken(): string | null {
    return window.localStorage.getItem(REFRESH_TOKEN);
  }

  public saveUrl(url: string): void {
    window.localStorage.removeItem(URL);
    window.localStorage.setItem(URL, url);
  }

  public getUrl(): string | null {
    return window.localStorage.getItem(URL);
  }

  public saveCryptoKeys(cryptoKeys): void {
    window.localStorage.removeItem('cryptoKeys');
    window.localStorage.setItem('cryptoKeys', JSON.stringify(cryptoKeys));
  }

}
