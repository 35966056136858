<mat-card>
  <mat-card-title class="width-100 card-title-flex">
    <div>Mandanten</div>
    <button mat-icon-button>
      <mat-icon mat-list-icon class="ion ion-md-add-circle-outline" color="primary"
                (click)="addNewClientToUser()"></mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content class="table-container">
    <mat-table *ngIf="userClients" mat-table class="width-100" [dataSource]="userClients">

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.client[0].id}} </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.client[0].name}} </mat-cell>
      </ng-container>

      <!-- USt-Id. Column -->
      <ng-container matColumnDef="vatId">
        <mat-header-cell *matHeaderCellDef> USt-Id.</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.client[0].vatId}} </mat-cell>
      </ng-container>

      <!-- Erstellungsdatum Column -->
      <ng-container matColumnDef="entryCreated">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Erstellungsdatum</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.client[0].entryCreated | date:'dd.MM.yyyy'}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsClients; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsClients;" (click)="editUserRights(row)"></mat-row>
    </mat-table>
    <div *ngIf="!userClients" class="empty-array">
      <p>
        Keine Mandanten vorhanden
      </p>
    </div>
  </mat-card-content>
</mat-card>
