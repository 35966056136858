<mat-card>
  <mat-card-title class="width-100 card-title-flex">
    <div>Kontakte</div>
    <button mat-icon-button (click)="editContactDialog(undefined)">
      <mat-icon mat-list-icon class="ion ion-md-add-circle-outline" color="primary"></mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content *ngIf="contacts">
    <table mat-table class="width-100" [dataSource]="contacts">

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID</th>
        <td mat-cell *matCellDef="let element"> {{element.id}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} {{element.surname}}</td>
      </ng-container>

      <!-- Stadt Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> E-Mail</th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <!-- Straße Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Telefon</th>
        <td mat-cell *matCellDef="let element"> {{element.phone}}</td>
      </ng-container>

      <!-- Erstellungsdatum Column -->
      <ng-container matColumnDef="entryCreated">
        <th mat-header-cell *matHeaderCellDef> Erstellungsdatum</th>
        <td mat-cell *matCellDef="let element"> {{element.entryCreated | date:'dd.MM.yyyy'}} </td>
      </ng-container>

      <!-- Bearbeiten Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="editContactDialog(element)">
            <mat-icon mat-list-icon class="ion ion-md-create" color="primary"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsAddress"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsAddress;"></tr>
    </table>
  </mat-card-content>
  <mat-card-content *ngIf="!contacts">
    <div class="empty-array">
      <p>
        Keine Kontakte vorhanden
      </p>
    </div>
  </mat-card-content>
</mat-card>
