<mat-card>
  <mat-card-title class="width-100">
    <div>Benutzer</div>
    <button mat-icon-button (click)="addUserToClientDialog()">
      <mat-icon mat-list-icon class="ion ion-md-add-circle-outline" color="primary"></mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content *ngIf="users && !isLoading" class="table-container">
    <table mat-table class="width-100" [dataSource]="users">

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID</th>
        <td mat-cell *matCellDef="let element"> {{element.user[0].id}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.user[0].name}} {{element.user[0].surname}}</td>
      </ng-container>

      <!-- Stadt Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> E-Mail</th>
        <td mat-cell *matCellDef="let element"> {{element.user[0].email}} </td>
      </ng-container>

      <!-- Straße Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Telefon</th>
        <td mat-cell *matCellDef="let element"> {{element.user[0].phone}}</td>
      </ng-container>

      <!-- Erstellungsdatum Column -->
      <ng-container matColumnDef="entryCreated">
        <th mat-header-cell *matHeaderCellDef> Erstellungsdatum</th>
        <td mat-cell *matCellDef="let element"> {{element.user[0].entryCreated | date:'dd.MM.yyyy'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsUser; sticky: true"></tr>
      <tr mat-row *matRowDef="let user; columns: displayedColumnsUser;" (click)="openEditUserDialog(user)"
          [ngStyle]="user['user'][0].deleted == '1' && {'background-color': '#E5E5E5'}"></tr>
    </table>
  </mat-card-content>

  <mat-card-content *ngIf="!users && !isLoading">
    <div class="empty-array">
      <p>
        Keine Benutzer vorhanden
      </p>
    </div>
  </mat-card-content>

  <mat-card-content *ngIf="isLoading">
    <div class="empty-array">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card-content>
</mat-card>
