import {Component, Inject} from '@angular/core';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {IUserDevice} from '@interfaces/iuser-devices';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-user-delete-device-dialog',
  templateUrl: './user-delete-device-dialog.component.html',
  styleUrls: ['./user-delete-device-dialog.component.scss']
})
export class UserDeleteDeviceDialogComponent {
  public data: IUserDevice;

  constructor(
    private requestManager: RequestManagerService,
    private dialogRef: MatDialogRef<UserDeleteDeviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.data = data;
  }

  /**
   * Delete userDevices-entry and close dialog
   */
  async deleteDevice() {
    await this.requestManager.delete('/userDevices/' + this.data.id);
    this.dialogRef.close();
  }
}
