<mat-card-content
  *ngIf="clientUploads && !isLoading"
  class="debug-table-container"
>
  <table mat-table class="width-100 height-50" [dataSource]="clientUploads">
    <!-- Name Column -->
    <ng-container style="width: 20px" matColumnDef="filename">
      <th style="width: 20px" mat-header-cell *matHeaderCellDef>Dateiname</th>
      <td mat-cell *matCellDef="let element">{{ element.fileName }}</td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Beschreibung</th>
      <td
        mat-cell
        *matCellDef="let element"
        [matTooltip]="element.description"
      >
        <p>
          {{
          element.description.length > 50
            ? (element.description | slice: 0:47) + "..."
            : element.description
          }}
        </p>
      </td>
      <td mat-cell *matCellDef="let element"></td>
    </ng-container>

    <!-- ContentAccepted Column -->
    <ng-container matColumnDef="contentAccepted">
      <th mat-header-cell *matHeaderCellDef>Inhaltliche Freigabe</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon
          mat-list-icon
          color="{{ element.contentAccepted == '1' ? 'primary' : 'warn' }}"
          [matTooltip]="element.contentAcceptedByName"
        >
          {{ element.contentAccepted == "1" ? "check" : "close" }}
        </mat-icon>
      </td>
    </ng-container>

    <!-- ClearedForPayment Column -->
    <ng-container matColumnDef="clearedForPayment">
      <th mat-header-cell *matHeaderCellDef>Zahlungs- freigabe</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon
          mat-list-icon
          color="{{ element.clearedForPayment == '1' ? 'primary' : 'warn' }}"
          [matTooltip]="element.clearedForPaymentByName"
        >
          {{ element.clearedForPayment == "1" ? "check" : "close" }}
        </mat-icon>
      </td>
    </ng-container>

    <!-- UploadedToDATEV Column -->
    <ng-container matColumnDef="uploadedToDATEV">
      <th mat-header-cell *matHeaderCellDef>DATEV-Upload</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon
          mat-list-icon
          color="{{ element.uploadedToDATEV == '1' ? 'primary' : 'warn' }}"
        >
          {{ element.uploadedToDATEV == "1" ? "check" : "close" }}
        </mat-icon>
      </td>
    </ng-container>

    <!-- Error Column -->
    <!-- <ng-container matColumnDef="error">
      <th mat-header-cell *matHeaderCellDef>Log</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="downloadErrorLog(element)">
          <mat-icon mat-list-icon class="ion ion-md-document" color="primary"></mat-icon>
        </button>
      </td>
    </ng-container> -->

    <!-- Retry Column -->
    <ng-container matColumnDef="retry">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-mini-fab
          color="primary"
          *ngIf="element.error"
          (click)="retryFileUpload(element)"
        >
          <mat-icon mat-list-icon>
            {{ "autorenew" }}
          </mat-icon>
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumnsUploads; sticky: true"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsUploads"></tr>
  </table>
  <div class="footer-container">
    <button
      (click)="loadMoreUploads()"
      mat-button
      color="primary"
      [disabled]="allUploadsLoaded"
    >
      Mehr laden
    </button>
  </div>
</mat-card-content>

<mat-card-content *ngIf="!clientUploads && !isLoading">
  <div class="empty-array">
    <p>Keine Uploads gefunden</p>
  </div>
</mat-card-content>

<mat-card-content *ngIf="isLoading">
  <div class="empty-array">
    <mat-spinner></mat-spinner>
  </div>
</mat-card-content>
