import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryCodeToCountry'
})
export class CountryCodeToCountryPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    switch (value.toUpperCase()) {
      case 'DE':
        return 'Deutschland';
      case 'AT':
        return 'Österreich';
      case 'ES':
        return 'Spanien';
      case 'FR':
        return 'Frankreich';
      case 'CN':
        return 'China';
      default:
        return value;
    }
  }

}
