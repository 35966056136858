<div mat-dialog-title class="modal-title">
  {{isPutMode ? 'Abteilung bearbeiten' : 'Abteilung erstellen'}}
</div>
<hr class="hr-margin-bottom">

<div class="flex-department">
  <form [formGroup]="departmentFormGroup">
    <mat-list>
      <div class="flex-between">
        <div>
          <mat-list-item class="margin-12 margin-right-12">
            <mat-form-field>
              <mat-label>Abteilung</mat-label>
              <input matInput formControlName="departmentName">
            </mat-form-field>
          </mat-list-item>
          <mat-list-item class="margin-12 margin-right-12">
            <mat-form-field>
              <mat-label>Belegtyp</mat-label>
              <input matInput formControlName="departmentDocumentType">
            </mat-form-field>
            <button mat-icon-button (click)="showDocumentTypeInfo()">
              <mat-icon mat-list-icon class="ion ion-md-information-circle" color="primary"></mat-icon>
            </button>
          </mat-list-item>
        </div>
        <div *ngIf="isPutMode">
          <span style="margin-right: 10px">AKTIV</span>
          <mat-slide-toggle
            [checked]="department.disabled === null || department.disabled === '0'"
            (click)="disableUserDialog()"
          ></mat-slide-toggle>
        </div>
      </div>

      <div class="flex-between">
        <p>E-Mail Vorlagen</p>
        <button mat-icon-button (click)="addEmailControl()">
          <mat-icon mat-list-icon class="ion ion-md-add-circle-outline" color="primary"></mat-icon>
        </button>
      </div>
      <mat-list-item class="margin-12 margin-right-12" *ngFor="let mailId of amountEmails; let i = index">
        <mat-form-field>
          <mat-label>E-Mail Vorlagen</mat-label>
          <input matInput formControlName="{{'mailPattern' + mailId}}">
        </mat-form-field>
        <button mat-icon-button (click)="deleteEmailControl(i)">
          <mat-icon mat-list-icon class="ion ion-md-remove-circle-outline" color="primary"></mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </form>
</div>
<div class="flex-between">
  <button mat-raised-button (click)="closeDialog()" color="warn">
    Abbrechen
  </button>
  <button mat-raised-button (click)="createEditDepartment()" color="primary" [disabled]="departmentFormGroup.invalid">
    {{isPutMode ? 'Speichern' : 'Erstellen'}}
  </button>
</div>

