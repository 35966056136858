import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';

import {StoreModule} from '@ngrx/store';
import * as fromUser from './store/user.reducer';
import {EffectsModule} from '@ngrx/effects';
import {UserEffects} from './store/user.effects';
import {UserOverviewComponent} from './components/user-overview/user-overview.component';
import {UserSingleComponent} from './components/user-single/user-single.component';
import {UserSingleClientsComponent} from './components/user-single/user-single-clients/user-single-clients.component';
import {UserSingleDevicesComponent} from './components/user-single/user-single-devices/user-single-devices.component';
import {UserSingleDepartmentsComponent} from './components/user-single/user-single-departments/user-single-departments.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../sharedModules/material/material.module';
import {UserDeleteDeviceDialogComponent} from './components/user-delete-device-dialog/user-delete-device-dialog.component';
import {UserAddNewClientDialogComponent} from './components/user-add-new-client-dialog/user-add-new-client-dialog.component';
import {UserClientSettingsDialogComponent} from './components/user-client-settings-dialog/user-client-settings-dialog.component';
import {UserDeleteDepartmentDialogComponent} from './components/user-delete-department-dialog/user-delete-department-dialog.component';
import {UserAddDepartmentDialogComponent} from './components/user-add-department-dialog/user-add-department-dialog.component';
import {UserEditAvatarDialogComponent} from './components/user-edit-avatar-dialog/user-edit-avatar-dialog.component';
import {UserCreateDialogComponent} from './components/user-create-dialog/user-create-dialog.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ClientsModule} from '../clients/clients.module';
import { UserDeleteDialogComponent } from './components/user-delete-dialog/user-delete-dialog.component';


@NgModule({
  entryComponents: [
    UserDeleteDeviceDialogComponent,
    UserAddNewClientDialogComponent,
    UserClientSettingsDialogComponent,
    UserDeleteDepartmentDialogComponent,
    UserAddDepartmentDialogComponent,
    UserAddNewClientDialogComponent,
    UserEditAvatarDialogComponent,
    UserCreateDialogComponent,
  ],
  declarations: [
    UserOverviewComponent,
    UserSingleComponent,
    UserSingleClientsComponent,
    UserSingleDevicesComponent,
    UserSingleDepartmentsComponent,
    UserDeleteDeviceDialogComponent,
    UserAddNewClientDialogComponent,
    UserClientSettingsDialogComponent,
    UserDeleteDepartmentDialogComponent,
    UserAddDepartmentDialogComponent,
    UserAddNewClientDialogComponent,
    UserEditAvatarDialogComponent,
    UserCreateDialogComponent,
    UserDeleteDialogComponent,
  ],
  imports: [

    CommonModule,
    BrowserModule, FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    StoreModule.forFeature(fromUser.usersFeatureKey, fromUser.reducer),
    EffectsModule.forFeature([UserEffects]),
    ImageCropperModule, ClientsModule,
  ]
})
export class UsersModule {
}

