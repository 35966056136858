import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {IClient} from '@interfaces/iclient';

// Load Clients
export const loadClients = createAction(
  '[Clients Overview Component] Load Clients'
);
export const loadClientsSuccess = createAction(
  '[Client List Effect] Load Clients Success',
  props<{ clients: IClient[] }>()
);
export const loadClientsFailure = createAction(
  '[Client List Effect] Load Clients Failure',
  props<{ error: any }>()
);


// Load Client
export const loadClient = createAction(
  '[Client Overview Component] Load Client',
  props<{ id: string | number }>()
);
export const loadClientSuccess = createAction(
  '[Client Effect] Load Client Success',
  props<{ selectedClient: IClient }>()
);
export const loadClientFailure = createAction(
  '[Client Effect] Load Client Failure',
  props<{ error: any }>()
);

// Update Client
export const updateClient = createAction(
  '[Client Single Component] Update Client',
  props<{ client: Update<IClient> }>()
);
