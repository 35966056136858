import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IClientModule} from '@interfaces/iclient-module';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {ActivatedRoute} from '@angular/router';
import {ClientChangePricingModelComponent} from '../../client-change-pricing-model/client-change-pricing-model.component';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ModuleInfoTextModalComponent} from '../../client-change-pricing-model/module-info-text-modal/module-info-text-modal.component';

@Component({
  selector: 'app-client-module',
  templateUrl: './client-module.component.html',
  styleUrls: ['./client-module.component.scss']
})
export class ClientModuleComponent implements OnInit, OnChanges {
  @Input() clientModules: IClientModule[] = [];
  @Input() pricingModelId: number;
  @Input() pricingModel: string;
  @Output() updateActiveModules = new EventEmitter<IClientModule[]>();
  @Output() switchPricingModel = new EventEmitter();
  fixCosts: any[];
  totalCost: number;
  myForm: FormGroup;
  switched: boolean;
  displayedFixCosts: string[] = ['name', 'price'];
  displayedModules: string[] = ['name', 'price', 'active'];
  clientId: string;
  incomingInvoicesUpload: IClientModule;
  incomingInvoices: IClientModule;

  constructor(
    private dialog: MatDialog,
    private requestManager: RequestManagerService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
  }

  async ngOnInit() {
    this.fixCosts = [
      {
        sales: '< 75.000',
        cost: '0',
      },
      {
        sales: '< 500.000',
        cost: '15',
      },
      {
        sales: '< 1.000.000',
        cost: '25',
      },
      {
        sales: 'ab 1.000.000',
        cost: '35',
      }
    ];
    if (this.clientModules && this.clientModules.length !== 0) {
      this.calculateTotalCost();
      this.incomingInvoices = this.clientModules.filter(module => module.name === 'Rechnungsfreigabe')[0];
      this.incomingInvoicesUpload = this.clientModules.filter(module => module.name === 'Rechnungsupload')[0];
    }
    this.route.params.subscribe(params => {
      this.clientId = params['id'];
    });
    this.myForm = this.formBuilder.group({
      id: new FormControl(null),
      switched: new FormControl(null)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clientModules) {
      if (changes.clientModules.currentValue !== changes.clientModules.previousValue) {
        this.clientModules = changes.clientModules.currentValue;
      }
    }
    this.calculateTotalCost();
    if (this.clientModules !== undefined) {
      this.incomingInvoices = this.clientModules.filter(module => module.name === 'Rechnungsfreigabe')[0];
      this.incomingInvoicesUpload = this.clientModules.filter(module => module.name === 'Rechnungsupload')[0];
    }
  }

  private calculateTotalCost() {
    if (!this.clientModules) {
      return;
    }
    this.totalCost = 0;
    for (const module of this.clientModules) {
      if (module.active === '1' && module.monthly === '1') {
        this.totalCost += +module.cost;
      }
    }
  }

  save() {
    this.updateActiveModules.emit(this.clientModules);
  }

  updateModules($event, module: IClientModule) {
    $event.preventDefault();
    this.clientModules.forEach(mod => {
      if (mod.id === module.id) {
        mod.active = module.active === '1' ? '0' : '1';
      }
    });
    this.calculateTotalCost();
  }

  openInfoModal(module: IClientModule) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '650px';
    dialogConfig.data = module;
    this.dialog.open(ModuleInfoTextModalComponent, dialogConfig);
  }

  changePricingModel() {
    this.myForm.controls.id.setValue(this.pricingModelId);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.minWidth = '600px';
    dialogConfig.data = this.myForm.value;

    this.dialog.open(ClientChangePricingModelComponent, dialogConfig).afterClosed().subscribe(() => {
      if (this.myForm.value.switched) {
        this.switchPricingModel.emit();
      }
    });
  }
}
