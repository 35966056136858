import {Component, Input, OnInit} from '@angular/core';

import {IClient, IClientWrapped} from '@interfaces/iclient';
import {IUser} from '@interfaces/iuser';

import {UserService} from '@services/user/user.service';

import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {UserAddNewClientDialogComponent} from '../../user-add-new-client-dialog/user-add-new-client-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {UserState} from 'src/app/users/store/user.reducer';
import {Store} from '@ngrx/store';
import {loadUser} from 'src/app/users/store/user.actions';
import {
  ClientEditUserSettingsDialogComponent
} from 'src/app/clients/components/client-edit-user-settings-dialog/client-edit-user-settings-dialog.component';

@Component({
  selector: 'app-user-single-clients',
  templateUrl: './user-single-clients.component.html',
  styleUrls: ['./user-single-clients.component.scss'],
})
export class UserSingleClientsComponent implements OnInit {
  @Input() userClients: IClientWrapped[];
  @Input() user: IUser;
  @Input() clients: IClient[];
  @Input() hasInvoiceRight: boolean;

  public displayedColumnsClients: string[] = [
    'id',
    'name',
    'vatId',
    'entryCreated'
  ];

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private requestManager: RequestManagerService,
    private store: Store<UserState>
  ) {
  }

  ngOnInit() {
  }

  /**
   * Init dialog configurations and filter possibleNewClients, then open dialog for adding clients
   */
  async addNewClientToUser() {
    const availableClients = this.user.userClient
      ? this.clients.filter(
        (ac) =>
          this.user.userClient.find((uc) => ac.id === uc.clientId) ===
          undefined
      )
      : this.clients;

    const dialog = this.dialog.open(UserAddNewClientDialogComponent, {
      autoFocus: true,
      minWidth: '600px',
      data: {clients: availableClients, user: this.user},
    });
    dialog.afterClosed().subscribe(() => {
      this.store.dispatch(loadUser({id: this.user.id}));
    });
  }

  /**
   * Init dialog configurations and filter users userRightsForClient, then open dialog for editing user rights
   * @param client client which user-settings should be changed
   */
  async editUserRights(client: any) {

    // TODO: Rewrite this into function
    let rights = await this.requestManager.get('/adminArea/client/' + client.client[0].id + '/clientSettings', {array_values: true});
    let right = rights.clientSettings?.find(s => s.settingName == 'incomingInvoicesWithClearanceActive');

    if (!right) {
      await this.requestManager.post('/clientSettings', {
        settingName: 'incomingInvoicesWithClearanceActive', settingValue: '0', clientId: client.client[0].id
      });
      rights = await this.requestManager.get('/adminArea/client/' + client.client[0].id + '/clientSettings', {array_values: true});
      right = rights.clientSettings?.find(s => s.settingName == 'incomingInvoicesWithClearanceActive');
    }

    this.hasInvoiceRight = right.settingValue != 0;


    const departmentsResponse = await this.requestManager.get(
      '/adminArea/user/' +
      this.user.id +
      '/client/' +
      client.client[0].id +
      '/departments',
      {array_values: true}
    );

    let userDepartments;
    let userDepartmentsRights;

    if (departmentsResponse.length == 0) {
      userDepartments = [];
      userDepartmentsRights = [];
    } else {
      userDepartments = departmentsResponse.user[0].userDepartments.map(
        (el) => {
          return {...el.departments[0], tableId: el.id};
        }
      );
      userDepartmentsRights = departmentsResponse.user[0].userDepartmentsRights;
    }

    let userRightsForClient = await this.requestManager.get(
      '/adminArea/user/' +
      this.user.id +
      '/client/' +
      client.client[0].id +
      '/userRightsForClient',
      {array_values: true}
    );

    if (userRightsForClient.length == 0) {
      await this.requestManager.post('/userRightsForClient', {
        settingName: 'canSeeOPOS', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
      });
      await this.requestManager.post('/userRightsForClient', {
        settingName: 'canSeeAccounting', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
      });
      await this.requestManager.post('/userRightsForClient', {
        settingName: 'canSeeVoucherScan', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
      });
      await this.requestManager.post('/userRightsForClient', {
        settingName: 'canSeeVoucherApproval', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
      });
      await this.requestManager.post('/userRightsForClient', {
        settingName: 'canSeeCompany', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
      });
      await this.requestManager.post('/userRightsForClient', {
        settingName: 'canSeeDMS', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
      });
      await this.requestManager.post('/userRightsForClient', {
        settingName: 'canSeePaymentTransactions', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
      });
      await this.requestManager.post('/userRightsForClient', {
        settingName: 'pushForNewInvoiceActive', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
      });
      await this.requestManager.post('/userRightsForClient', {
        settingName: 'mailForNewInvoiceActive', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
      });
      userRightsForClient = await this.requestManager
        .get('/adminArea/user/' + this.user.id + '/client/' + client.client[0].id + '/userRightsForClient', {array_values: true});
    } else {
      const userRights = [];
      userRightsForClient.user[0].userRightsForClient.forEach(value => {
        userRights.push(value.settingName);
      });
      if (!userRights.includes('canSeeAccounting')) {
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeeAccounting', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
        });
      }
      if (!userRights.includes('canSeeCompany')) {
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeeCompany', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
        });
      }
      if (!userRights.includes('canSeeOPOS')) {
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeeOPOS', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
        });
      }
      if (!userRights.includes('canSeeVoucherApproval')) {
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeeVoucherApproval', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
        });
      }
      if (!userRights.includes('canSeeVoucherScan')) {
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeeVoucherScan', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
        });
      }
      if (!userRights.includes('canSeeDMS')) {
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeeDMS', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
        });
      }
      if (!userRights.includes('canSeePaymentTransactions')) {
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'canSeePaymentTransactions', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
        });
      }
      if (!userRights.includes('pushForNewInvoiceActive')) {
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'pushForNewInvoiceActive', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
        });
      }
      if (!userRights.includes('mailForNewInvoiceActive')) {
        await this.requestManager.post('/userRightsForClient', {
          settingName: 'mailForNewInvoiceActive', settingValue: '0', userId: this.user.id, clientId: client.client[0].id
        });
      }
      userRightsForClient = await this.requestManager
        .get('/adminArea/user/' + this.user.id + '/client/' + client.client[0].id + '/userRightsForClient', {array_values: true});
    }
    userRightsForClient = userRightsForClient.user[0].userRightsForClient;


    let departments = (
      await this.requestManager.get(
        '/adminArea/client/' + client.client[0].id + '/departments'
      )
    ).departments;
    if (departments == undefined) {
      departments = [];
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '700px';

    dialogConfig.data = {
      client: client.client[0],
      user: this.user,
      userDepartments,
      userDepartmentsRights,
      userRightsForClient,
      allDepartments: departments,
      calledFromUser: true,
      hasInvoiceRight: this.hasInvoiceRight,
    };

    const dialogRef = this.dialog.open(
      ClientEditUserSettingsDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(loadUser({id: this.user.id}));
    });
  }
}
