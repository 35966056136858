<div class="margin-40">
  <mat-card class="blue-card">
    <mat-card-header class="search-flex-container">
      <mat-icon style="width: 20px; padding-right: 40px" mat-list-icon class="ion ion-md-search"
                color="light"></mat-icon>
      <mat-form-field style="width: 95%">
        <input matInput type="search" placeholder="Suche" [(ngModel)]="searchTerm" (ngModelChange)="filter()">
      </mat-form-field>
      <button mat-icon-button (click)="addClientDialog()">
        <mat-icon mat-list-icon class="ion ion-md-add-circle-outline"></mat-icon>
      </button>
    </mat-card-header>
  </mat-card>

  <table mat-table *ngIf="filteredClientList" [dataSource]="filteredClientList" class="mat-elevation-z8">

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- USt-Id. Column -->
    <ng-container matColumnDef="vatId">
      <th mat-header-cell *matHeaderCellDef> USt-Id.</th>
      <td mat-cell *matCellDef="let element"> {{element.vatId}} </td>
    </ng-container>

    <!-- Erstellungsdatum Column -->
    <ng-container matColumnDef="entryCreated">
      <th mat-header-cell *matHeaderCellDef> Erstellungsdatum</th>
      <td mat-cell *matCellDef="let element"> {{element.entryCreated | date:'dd.MM.yyyy'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openSingleClient(row)"></tr>
  </table>

  <div *ngIf="!clientList && !isLoading" class="empty-array">
    <p>
      Es wurden noch keine Mandanten hinzugefügt
    </p>
  </div>

  <div *ngIf="!filteredClientList && isLoading" class="empty-array">
    <mat-spinner></mat-spinner>
  </div>
</div>
