import {Component, Input, OnInit} from '@angular/core';

import {IUserDevice} from '@interfaces/iuser-devices';
import {UserDeleteDeviceDialogComponent} from '../../user-delete-device-dialog/user-delete-device-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';


@Component({
  selector: 'app-user-single-devices',
  templateUrl: './user-single-devices.component.html',
  styleUrls: ['./user-single-devices.component.scss']
})
export class UserSingleDevicesComponent implements OnInit {
  @Input() userDevices: IUserDevice[];
  public displayedColumnsDevices: string[] = ['id', 'deviceDescription', 'lastSeen', 'entryCreated', 'edit'];

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  /**
   * Init dialog configurations and data, then delete-device-dialog
   * @param row device-object that should be deleted
   */
  deleteDeviceDialog(row: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';

    dialogConfig.data = row;

    this.dialog.open(UserDeleteDeviceDialogComponent, dialogConfig);
  }
}
