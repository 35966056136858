import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-module-info-text-modal',
  templateUrl: './module-info-text-modal.component.html',
  styleUrls: ['./module-info-text-modal.component.scss']
})
export class ModuleInfoTextModalComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ModuleInfoTextModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
