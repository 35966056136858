import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {ICredentials} from '@interfaces/icredentials';

import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {CryptographyService} from '@services/cryptography/cryptography.service';
import {UserService} from '@services/user/user.service';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {TokenStorageService} from '@services/token-storage/token-storage.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // TODO: DELETE LOGIN DATA
  loginFormGroup = new FormGroup({
    url: new FormControl('', [Validators.required]),
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });
  loginLoading = false;
  hide = true;

  constructor(
    private router: Router,
    private requestManager: RequestManagerService,
    private cryptoService: CryptographyService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private tokenStorageService: TokenStorageService
  ) {
  }

  ngOnInit() {
    if (this.tokenStorageService.isValid()) {
      this.requestManager.setBaseUrl(this.tokenStorageService.getUrl());
      this.router.navigateByUrl('/dashboard');
    }
  }

  /**
   * Check credential validity, selected storage and post auth request, then navigate to dashboard or prompt error message
   */
  async login() {
    this.loginLoading = true;
    const credentials = this.loginFormGroup.value as ICredentials;

    // Authenticate at server
    await this.requestManager.auth(credentials)
      .then(async (res) => {
        this.tokenStorageService.saveAccessToken(res.accessToken);
        this.tokenStorageService.saveRefreshToken(res.refreshToken);
        this.tokenStorageService.saveUrl(credentials.url);

        // load user
        const user = await this.userService.getLoggedInUser();

        if (user.isSuperAdmin !== '1') {
          const snackBarConfig: MatSnackBarConfig = {
            verticalPosition: 'top',
            duration: 5000,
            horizontalPosition: 'right'
          };
          this.snackBar.open('Sie haben keine Zutrittsrechte!', '', snackBarConfig);
          this.tokenStorageService.signOut();
          this.loginLoading = false;
          return;
        }

        // ToDo @dusko.stupar what was this used for? Can this be removed safely?
        // ToDo @dusko.stupar I was forced to remove it due to login problems!
        // const cryptoKeys = await this.cryptoService.getOrGenerateCryptoKeysForUser(user, credentials);
        // if (cryptoKeys) {
        //   this.tokenStorageService.saveCryptoKeys(cryptoKeys);
        //   this.router.navigateByUrl('/client-select');
        // }

        this.loginLoading = false;
        this.router.navigateByUrl('/dashboard');
      })
      .catch(() => {
        const snackBarConfig: MatSnackBarConfig = {
          verticalPosition: 'top',
          duration: 5000,
          horizontalPosition: 'right'
        };
        this.snackBar.open('Login fehlgeschlagen!', '', snackBarConfig);
        this.loginLoading = false;
      });
  }
}
