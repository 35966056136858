<div mat-dialog-title class="modal-title">
  Preismodell wechseln
</div>
<hr class="hr-margin-bottom">

<mat-dialog-content>
  Im neuen Preismodell zahlst du nur, was du nutzt:
  <table mat-table class="width-100" style="margin-top: 10px;" [dataSource]="modules">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <td mat-cell *matCellDef="let element">
        {{element.name}}
        <button mat-icon-button (click)="openInfoModal(element)">
          <mat-icon mat-list-icon class="ion ion-md-information-circle" style="vertical-align: middle" color="primary"></mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Preis Column -->
    <ng-container matColumnDef="price">
      <td mat-cell *matCellDef="let element" class="right-cell">{{element.cost}} €
        / {{element.monthly === '1' ? 'Mon.' : 'Beleg'}}</td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedModules;"></tr>
  </table>
  <div class="right-cell" style="margin-top: 16px">
    <a style="cursor: pointer; margin-right: 16px" (click)="closeDialog()">Abbrechen</a>
    <button mat-raised-button color="primary" (click)="switchPricingModelToV2()">Jetzt dauerhaft wechseln</button>
  </div>
</mat-dialog-content>
