import {createReducer, on} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import * as ClientActions from './client.actions';
import {IClient} from '@interfaces/iclient';

export const clientsFeatureKey = 'clients';

export interface ClientState extends EntityState<IClient> {
  // additional entities state properties
}

export const adapter: EntityAdapter<IClient> = createEntityAdapter<IClient>();

export const initialState: ClientState = adapter.getInitialState({
  error: undefined,
  selectedClient: undefined,
});


export const reducer = createReducer(
  initialState,
  // Load all clients
  on(ClientActions.loadClientsSuccess,
    (state, action) => adapter.setAll(action.clients, state)
  ),
  on(ClientActions.loadClientsFailure,
    (state, action) => {
      return {...state, error: action.error};
    }),
  // Load single client
  on(ClientActions.loadClientSuccess,
    (state, action) => {
      return {...state, selectedClient: action.selectedClient};
    }),
  on(ClientActions.loadClientFailure,
    (state, action) => {
      return {...state, error: action.error};
    }),
  // Update a single client
  on(ClientActions.updateClient, (state, action) =>
    adapter.updateOne(action.client, state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
