import { ToUserPipe } from '@pipes/to-user.pipe';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';

import {StoreModule} from '@ngrx/store';
import * as fromClient from './store/client.reducer';
import {EffectsModule} from '@ngrx/effects';
import {ClientEffects} from './store/client.effects';

import {MaterialModule} from '../sharedModules/material/material.module';

import {ClientOverviewComponent} from './components/client-overview/client-overview.component';
import {ClientSingleComponent} from './components/client-single/client-single.component';
import {ClientAddressesComponent} from './components/client-single/client-addresses/client-addresses.component';
import {ClientContactsComponent} from './components/client-single/client-contacts/client-contacts.component';
import {ClientUsersComponent} from './components/client-single/client-users/client-users.component';
import {ClientSettingsComponent} from './components/client-single/client-settings/client-settings.component';
import {ClientDepartmentsComponent} from './components/client-single/client-departments/client-departments.component';
import {ClientBusinessReportsComponent} from './components/client-single/client-business-reports/client-business-reports.component';
import {ClientUploadBusinessReportComponent} from './components/client-upload-business-report/client-upload-business-report.component';
import {ClientSynchronisationsComponent} from './components/client-single/client-synchronisations/client-synchronisations.component';
import {ClientUploadsComponent} from './components/client-single/client-uploads/client-uploads.component';
import { DoOcrDialogComponent } from './components/client-single/client-settings/do-ocr-dialog/do-ocr-dialog.component';
import { DeleteDialogComponent } from './components/client-single/client-business-reports/delete-dialog/delete-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MomentModule} from 'angular2-moment';
import {TranslatePipe} from '@pipes/translate.pipe';

import {ClientCreateDepartmentComponent} from './components/client-create-department/client-create-department.component';
import {ClientCreateDepartmentInfoComponent} from './components/client-create-department-info/client-create-department-info.component';
import {ClientEditUserSettingsDialogComponent} from './components/client-edit-user-settings-dialog/client-edit-user-settings-dialog.component';
import {ClientEditContactComponent} from './components/client-edit-contact/client-edit-contact.component';
import {ClientEditAddressComponent} from './components/client-edit-address/client-edit-address.component';
import {ClientAddClientComponent} from './components/client-add-client/client-add-client.component';
import {ClientActivateEmailComponent} from './components/client-activate-email/client-activate-email.component';
import {ClientAddUserComponent} from './components/client-add-user/client-add-user.component';
import {DragAndDropDirective} from './components/client-upload-business-report/directives/drag-and-drop.directive';
import {ClientAddAccountingContactComponent} from './components/client-add-accounting-contact/client-add-accounting-contact.component';
import {ClientModuleComponent} from './components/client-single/client-module/client-module.component';
import {
  ClientDatevDmsIntegrationComponent
} from './components/client-single/client-datev-dms-integration/client-datev-dms-integration.component';
import {ClientChangePricingModelComponent} from './components/client-change-pricing-model/client-change-pricing-model.component';
import {
  ModuleInfoTextModalComponent
} from './components/client-change-pricing-model/module-info-text-modal/module-info-text-modal.component';
import {ClientAddBottomSheetComponent} from './components/client-add-client/client-add-bottom-sheet/client-add-bottom-sheet.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {InfoDialogComponent} from './components/client-single/client-settings/info-dialog/info-dialog.component';
import {ClientIndividualFieldsComponent} from './components/client-single/client-individual-fields/client-individual-fields.component';
import {ClientCreateIndividualFieldComponent} from './components/client-create-individual-field/client-create-individual-field.component';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
  entryComponents: [
    ClientCreateDepartmentComponent,
    ClientCreateDepartmentInfoComponent,
    ClientEditUserSettingsDialogComponent,
    ClientEditContactComponent,
    ClientEditAddressComponent,
    ClientAddClientComponent,
    ClientActivateEmailComponent,
    ClientAddUserComponent,
  ],
  declarations: [
    ClientAddAccountingContactComponent,
    ClientOverviewComponent,
    ClientSingleComponent,
    ClientAddressesComponent,
    ClientContactsComponent,
    ClientUsersComponent,
    ClientSettingsComponent,
    ClientDepartmentsComponent,
    ClientBusinessReportsComponent,
    ClientUploadBusinessReportComponent,
    ClientSynchronisationsComponent,
    ClientUploadsComponent,
    TranslatePipe,
    ToUserPipe,
    ClientCreateDepartmentComponent,
    ClientCreateDepartmentInfoComponent,
    ClientEditUserSettingsDialogComponent,
    ClientEditContactComponent,
    ClientEditAddressComponent,
    ClientAddClientComponent,
    ClientActivateEmailComponent,
    ClientAddUserComponent,
    DoOcrDialogComponent,
    DeleteDialogComponent,
    DragAndDropDirective,
    ClientModuleComponent,
    DragAndDropDirective,
    ClientDatevDmsIntegrationComponent,
    ClientChangePricingModelComponent,
    ModuleInfoTextModalComponent,
    ClientAddBottomSheetComponent,
    InfoDialogComponent,
    ClientIndividualFieldsComponent,
    ClientCreateIndividualFieldComponent
  ],
  exports: [
    TranslatePipe,
    ToUserPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    StoreModule.forFeature(fromClient.clientsFeatureKey, fromClient.reducer),
    EffectsModule.forFeature([ClientEffects]),
    MomentModule,
    AngularEditorModule,
    MatDatepickerModule,
  ]
})
export class ClientsModule {
}
