<div class="div-content" *ngIf="client">
  <mat-card-title class="heading-content">{{client.name}}</mat-card-title>

  <mat-grid-list class="heading-content" cols="4" rowHeight="45px">
    <mat-grid-tile>
      <mat-card-subtitle class="flex">
        <div><b>USt-ID-Nummer:</b></div>

        <div *ngIf="!isEditVatID"><b>{{client.vatId || '- - -'}}</b></div>
        <input *ngIf="isEditVatID" style="width: 40%; text-align: right;" matInput placeholder="ATU12345678"
               [(ngModel)]="client.vatId"
               (keyup.enter)="saveVatID(client)">

        <span style="position: absolute; right: 25%">
          <button *ngIf="isEditVatID " mat-icon-button color="primary"
                  (click)="saveVatID(client)">
            <mat-icon mat-list-icon class="ion ion-md-save"></mat-icon>
          </button>
          <button *ngIf="!isEditVatID" mat-icon-button color="primary"
                  (click)="editVatID(client)">
            <mat-icon mat-list-icon class="ion ion-md-create"></mat-icon>
          </button>
        </span>
      </mat-card-subtitle>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-card-subtitle class="flex-start">
        <div><b>Mandantennummer:</b></div>
        <div><b>{{client.clientNumber}}</b></div>
      </mat-card-subtitle>
    </mat-grid-tile>

    <mat-grid-tile rowspan="2">
      <div class="width-100 padding-sides">
        <!--EMAIL CONNECTION-->
        <button *ngIf="!isEmailActive" mat-raised-button color="primary" (click)="openActivateEmailModal()"
                class="right-10 btn-fit">
          <mat-icon mat-list-icon class="ion ion-md-sync"></mat-icon>
          ER-Email erstellen
        </button>
        <div class="width-100" *ngIf="isEmailActive">
          <button mat-raised-button class="green-btn right-10 btn-fit" disableRipple>
            <mat-icon mat-list-icon class="ion ion-md-swap"></mat-icon>
            ER-Email verbunden
          </button>

        </div>
        <button *ngIf="isEmailActive" mat-raised-button color="primary" class="right-10 btn-fit"
                style="margin-top: 10px" disableRipple>
          <mat-icon mat-list-icon class="ion ion-md-mail"></mat-icon>
          {{fallbackEmail}}
        </button>
      </div>
    </mat-grid-tile>

    <mat-grid-tile rowspan="2" style="margin-left: 10px">
      <div class="width-100">
        <!--DATEV CONNECTION-->
        <button *ngIf="datev.status == undefined || datev.status.status !== 'active'" mat-raised-button color="primary"
                (click)="datev.openConsentPage()" class="btn-fit">
          <mat-icon mat-list-icon class="ion ion-md-sync"></mat-icon>
          MIT DATEV RZ VERBINDEN
        </button>

        <div class="width-100" *ngIf="datev.status && datev.status.status === 'active'">
          <button mat-raised-button class="green-btn right-10 btn-fit" disableRipple>
            <mat-icon mat-list-icon class="ion ion-md-swap"></mat-icon>
            Mit DATEV RZ verbunden
          </button>
        </div>
        <button *ngIf="datev.status && datev.status.status === 'active'" mat-raised-button
                class="red-btn right-10 top-7 btn-fit" style="margin-top: 10px" (click)="datev.disconnectFromDatev()"
                disableRipple>
          <mat-icon mat-list-icon class="ion ion-md-trash"></mat-icon>
          Verbindung aufheben
        </button>
      </div>
    </mat-grid-tile>

    <mat-grid-tile class="flex-end">
      <mat-card-subtitle class="flex">
        <div><b>Erstellungsdatum:</b></div>
        <div><b>{{client.entryCreated | date:'dd.MM.yyyy'}}</b></div>
      </mat-card-subtitle>
    </mat-grid-tile>

    <mat-grid-tile class="flex-end">
      <mat-card-subtitle class="flex-start">
        <div><b>Beraternummer:</b></div>
        <div><b>{{client.consultantNumber}}</b></div>
      </mat-card-subtitle>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="2" rowHeight="580px" gutterSize="20px">

    <!-- Benutzer -->
    <mat-grid-tile class="card" colspan="2" rowspan="1">
      <app-client-users class="mat-elevation-z0"
                        [users]="client.userClient" [client]="client" [departments]="departments"
                        [isLoading]="usersLoading" [hasInvoiceRight]="hasInvoiceRight">
      </app-client-users>
    </mat-grid-tile>

    <!-- Module -->
    <mat-grid-tile class="card" colspan="1" rowspan="1">
      <app-client-module
        [clientModules]="modules"
        [pricingModel]="pricingModel"
        [pricingModelId]="pricingModelId"
        (updateActiveModules)="updateActiveModules()"
        (switchPricingModel)="switchPricingModel()"
      ></app-client-module>
    </mat-grid-tile>

    <!-- Rechnungsfreigabe -->
    <mat-grid-tile *ngIf="hasInvoiceRight || hasInvoiceUploadRight" class="card" colspan="1" rowspan="1">
      <mat-card class="mat-elevation-z0">
        <mat-card-title>
          <div>Rechnungsfreigabe</div>
        </mat-card-title>
        <mat-tab-group>
          <mat-tab label="Abteilungen">
            <app-client-departments class="flex-item" [active]="hasInvoiceRight"
                                    [clientDepartments]="departments" [isLoading]="departmentsLoading">
            </app-client-departments>
          </mat-tab>
          <mat-tab label="Einstellungen">
            <app-client-settings [sonstiges]="false" [releaseInvoices]="true" [evaluations]="false" [dms]="false"
                                 [users]="client.userClient" [client]="client" [settings]="client.clientSettings"
                                 [dmsRegisters]="dmsRegisters" [dmsRegistersSetting]="dmsRegisterSetting"
                                 [selectedRegisterValue]="selectedRegisterValue" [active]="hasInvoiceRight">
            </app-client-settings>
          </mat-tab>
          <mat-tab *ngIf="hasInvoiceRight" label="Individuelle Felder">
            <app-client-individual-fields [client]="client"></app-client-individual-fields>
          </mat-tab>
          <mat-tab label="Uploads">
            <app-client-uploads class="flex-item"
                                [clientId]="clientId">
            </app-client-uploads>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </mat-grid-tile>

    <!-- Auswertungen -->
    <mat-grid-tile *ngIf="hasBusinessReportRight" class="card" colspan="1" rowspan="1">
      <mat-card class="mat-elevation-z0">
        <mat-card-title>
          <div>Auswertungen</div>
        </mat-card-title>
        <mat-tab-group>
          <mat-tab label="Einstellungen">
            <app-client-settings [sonstiges]="false" [releaseInvoices]="false" [evaluations]="true" [dms]="false"
                                 [users]="client.userClient" [client]="client" [settings]="client.clientSettings">
            </app-client-settings>
          </mat-tab>
          <mat-tab label="Business Reports">
            <app-client-business-reports [clientId]="clientId">
            </app-client-business-reports>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </mat-grid-tile>

    <!-- DATEV DMS Integration -->
    <mat-grid-tile *ngIf="hasDmsRight" class="card" colspan="1" rowspan="1">
      <mat-card class="mat-elevation-z0">
        <mat-card-title>
          <div>DATEV DMS Integration</div>
        </mat-card-title>
        <mat-tab-group>
          <mat-tab label="Register">
            <app-client-datev-dms-integration [dmsRegisters]="dmsRegisters"
                                              [emptyState]="emptyState"
                                              [dmsError]="dmsError"></app-client-datev-dms-integration>
          </mat-tab>
          <mat-tab [disabled]="dmsError !== undefined || emptyState" label="Einstellungen">
            <app-client-settings [sonstiges]="false" [releaseInvoices]="false" [evaluations]="false" [dms]="true"
                                 [documentStatesDescription]="documentStatesDescription"
                                 [documentStateSetting]="documentStateSetting"
                                 [selectedStateValue]="selectedStateValue"
                                 [documentStates]="documentStates"
            ></app-client-settings>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </mat-grid-tile>

    <!-- Sonstiges -->
    <mat-grid-tile class="card" colspan="1" rowspan="1">
      <mat-card class="mat-elevation-z0">
        <mat-card-title>
          <div>Sonstiges</div>
        </mat-card-title>
        <mat-tab-group>
          <mat-tab label="Einstellungen">
            <app-client-settings [sonstiges]="true" [releaseInvoices]="false" [evaluations]="false" [dms]="false"
                                 [users]="client.userClient" [client]="client" [settings]="client.clientSettings">
            </app-client-settings>
          </mat-tab>
          <mat-tab label="Synchronisationen">
            <app-client-synchronisations [clientId]="clientId">
            </app-client-synchronisations>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </mat-grid-tile>

  </mat-grid-list>
</div>
