import {Component, Inject, OnInit} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {IUser} from '@interfaces/iuser';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-user-edit-avatar-dialog',
  templateUrl: './user-edit-avatar-dialog.component.html',
  styleUrls: ['./user-edit-avatar-dialog.component.scss']
})
export class UserEditAvatarDialogComponent implements OnInit {
  imageChangedEvent: Event;
  user: IUser;

  constructor(
    private requestManager: RequestManagerService,
    private dialogRef: MatDialogRef<UserEditAvatarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.user = data.user;
    data.user.subscribe(u => this.user.id = u.id);
  }

  ngOnInit() {
  }

  /**
   * Save file changed event for actualizing the image cropper preview image
   * @param event Event that is evoked on file change
   */
  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
  }

  /**
   * Wait for image file changed event and then update user avatar local and in database
   * @param event Event that is evoked onimage file change
   */
  imageCropped(event: ImageCroppedEvent): void {
    this.user.avatar = event.base64;
    this.user.avatar = this.user.avatar.split(',')[1];
  }

  saveNewAvatar(): void {
    this.requestManager.put('/user/' + this.user.id, {avatar: this.user.avatar})
      .then(() => this.dialogRef.close());
  }

  close() {
    this.dialogRef.close();
  }
}
