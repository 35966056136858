<mat-card-content *ngIf="clientDepartments && !isLoading" class="table-container">
  <table *ngIf="active" mat-table class="width-100" [dataSource]="clientDepartments">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Erstellungsdatum Column -->
    <ng-container matColumnDef="entryCreated">
      <th mat-header-cell *matHeaderCellDef> Erstellungsdatum</th>
      <td mat-cell *matCellDef="let element"> {{element.entryCreated | date:'dd.MM.yyyy'}} </td>
    </ng-container>

    <!-- MailPattern Column -->
    <ng-container matColumnDef="mailPattern">
      <th mat-header-cell *matHeaderCellDef>Anzahl Mails</th>
      <td mat-cell
          *matCellDef="let element"> {{element.departmentsMailPattern ? element.departmentsMailPattern.length : 0}} </td>
    </ng-container>

    <ng-container matColumnDef="create">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button (click)="createNewDepartmentDialog()">
          <mat-icon mat-list-icon class="ion ion-md-add-circle-outline" color="primary"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsDepartment; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsDepartment;" (click)="editDepartmentDialog(row)"
        [ngStyle]="row.disabled == '1' && {'background-color': '#E5E5E5'}"></tr>
  </table>
  <table *ngIf="!active" mat-table class="width-100" [dataSource]="departmentDirektUpload">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Erstellungsdatum Column -->
    <ng-container matColumnDef="entryCreated">
      <th mat-header-cell *matHeaderCellDef> Erstellungsdatum</th>
      <td mat-cell *matCellDef="let element"> {{element.entryCreated | date:'dd.MM.yyyy'}} </td>
    </ng-container>

    <!-- MailPattern Column -->
    <ng-container matColumnDef="mailPattern">
      <th mat-header-cell *matHeaderCellDef>Anzahl Mails</th>
      <td mat-cell
          *matCellDef="let element"> {{element.departmentsMailPattern ? element.departmentsMailPattern.length : 0}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsDepartmentDirektUpload; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsDepartmentDirektUpload;"
        (click)="editDepartmentDialog(row)"></tr>
  </table>
</mat-card-content>

<mat-card-content *ngIf="!clientDepartments  && !isLoading" style="height: fit-content">
  <button mat-icon-button (click)="createNewDepartmentDialog()">
    <mat-icon mat-list-icon class="ion ion-md-add-circle-outline" color="primary"></mat-icon>
  </button>
  <div class="empty-array">
    <p>
      Keine Abteilungen vorhanden
    </p>
  </div>
</mat-card-content>

<mat-card-content *ngIf="isLoading">
  <div class="empty-array">
    <mat-spinner></mat-spinner>
  </div>
</mat-card-content>
