<div mat-dialog-title class="modal-title">
  Informationen zum Belegtyp
</div>
<hr class="hr-margin-bottom">

<mat-card-content>
  <p>Hier können Sie den Belegtyp aus DATEV Unternehmen Online angeben.</p>
  <p>Wenn Sie das Feld leer lassen, werden Belege, die aus dieser Abteilung in Unternehmen Online hochgeladen werden,
    mit
    dem Belegtyp “Rechnungseingang” hochgeladen.</p>
  <p>Wenn Sie aber einen eigenen Belegtyp (z.B. “Firmenkreditkarte”) im Unternehmen Online angelegt haben, können Sie
    den
    Belegtypnamen hier eingeben.
    Dann wird der Beleg beim Upload in Unternehmen Online mit dem bezeichneten Belegtyp versehen.</p>
</mat-card-content>
<mat-card-actions align="end" style="margin: 0">
  <button mat-raised-button color="primary" (click)="closeDialog()" mat-button="">Verstanden</button>
</mat-card-actions>

