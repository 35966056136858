import {Component, Inject, OnInit} from '@angular/core';
import {IDepartment} from '@interfaces/idepartment';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-client-activate-email',
  templateUrl: './client-activate-email.component.html',
  styleUrls: ['./client-activate-email.component.scss']
})
export class ClientActivateEmailComponent implements OnInit {
  public departments: { id: string; entryCreated: string; name: string }[] | IDepartment[];
  public selectedDepartment: any;
  private clientId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private requestManager: RequestManagerService,
    private dialog: MatDialog,
  ) {
    this.departments = data.departments;
    this.clientId = data.clientId;
  }

  ngOnInit() {
  }

  async activateEmailService() {
    await this.requestManager.post('adminArea/client/' + this.clientId + '/inboundMail',
      {fallbackDepartmentsId: this.selectedDepartment.id});
    this.dialog.closeAll();
  }
}
