import { Injectable } from '@angular/core';

import * as CJS from 'crypto-js';
import * as FORGE from 'node-forge';
import * as _ from 'lodash';

import {IUser} from '@interfaces/iuser';
import {ICredentials} from '@interfaces/icredentials';

import { RequestManagerService } from '../request-manager/request-manager.service';

/**
 * Cryptography service, that can generate, save and decrypt private and public keys
 */

@Injectable({
  providedIn: 'root'
})

export class CryptographyService {
  private userId: any;
  private cryptoKeys: { publicKey: string, privateKey: string };

  constructor(
    private requestManager: RequestManagerService
  ) {  }

  /**
   * Decrypt private encrypted key with login credentials
   * @param privateKeyEncrypted Encrypted private key
   * @param credentials Login credentials for decryption
   */
  public decryptPrivateKey(privateKeyEncrypted: string, credentials: ICredentials): string {
    // password + username is decryption key, if longer than 32 shorten it, if shorter extend it with 0's
    const decryptKey = (credentials.password + credentials.username + '0000000000000000').substr(0, 32);

    // Decrypt
    const key = CJS.enc.Utf8.parse(decryptKey);
    const iv = CJS.enc.Utf8.parse(decryptKey);
    const decrypted = CJS.AES.decrypt(privateKeyEncrypted, key, {
      keySize: 256,
      iv: iv,
      mode: CJS.mode.ECB,
      padding: CJS.pad.Pkcs7
    });
    return decrypted.toString(CJS.enc.Utf8);
    // return decrypted.toString(CJS.enc.Base64);
  }

  /**
   * Encrypt a private key with login credentials
   * @param privateKey Readable private jey
   * @param body Login credentials for encryption
   */
  public encryptPrivateKey(privateKey: string, body: ICredentials): string {
    // password + username is decryption key, if longer than 32 shorten it, if shorter extend it with 0's
    const encryptKey = (body.password + body.username + '0000000000000000').substr(0, 32);

    // Decrypt
    const key = CJS.enc.Utf8.parse(encryptKey);
    const iv = CJS.enc.Utf8.parse(encryptKey);
    const encrypted = CJS.AES.encrypt(privateKey, key, {
      keySize: 256,
      iv: iv,
      mode: CJS.mode.ECB,
      padding: CJS.pad.Pkcs7
    });
    return encrypted.toString();
    // return decrypted.toString(CJS.enc.Base64);
  }

  /**
   * Generate public/private keys
   */
  public generateCryptoKeys() {
    const keypair = FORGE.rsa.generateKeyPair(2048);
    const privateKey = FORGE.pki.privateKeyToPem(keypair.privateKey);
    const publicKey = FORGE.pki.publicKeyToPem(keypair.publicKey);
    return [publicKey, privateKey];
  }

  /**
   * Set cryptoKeys inside this service
   * @param cryptoKeys private/public-key-object
   */
  public setCryptoKeys(cryptoKeys) {
    this.cryptoKeys = cryptoKeys;
  }

  /**
   * Reset userId and cryptoKeys
   */
  public reset() {
    this.userId = undefined;
    this.cryptoKeys = {publicKey: undefined, privateKey: undefined};
  }

  /**
   * Get or generate cryptoKeys for User
   * @param user logged in user
   * @param credentials credentials of logged in user
   */
  public async getOrGenerateCryptoKeysForUser(user: IUser, credentials: ICredentials): Promise<any> {
    // Retrieve keys from user object or generate new one
    let publicKey, privateKey;
    if (_.has(user, 'publicKey') && user['publicKey']) {
      publicKey = user['publicKey'];
      privateKey = this.decryptPrivateKey(user['privateKeyEncrypted'], credentials);
    } else {
      // if crypto keys not available generate new ones and push to backend
      [publicKey, privateKey] = this.generateCryptoKeys();
      const privateKeyEncrypted = this.encryptPrivateKey(privateKey, credentials);
      const cryptoKeys = {privateKeyEncrypted: privateKeyEncrypted, publicKey};
      try {
        await this.requestManager.put('/user/' + user.id, cryptoKeys);
      } catch (err) {
        console.log(err);
        // TODO: show error in notifications
        return null;
      }
    }

    this.cryptoKeys = {publicKey, privateKey};
    // return null;
    return this.cryptoKeys;
  }
}
