import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-do-ocr-dialog',
  templateUrl: './do-ocr-dialog.component.html',
})
export class DoOcrDialogComponent {
  constructor(public dialogRef: MatDialogRef<DoOcrDialogComponent>) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
