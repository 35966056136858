import {Component, Input, OnInit} from '@angular/core';

import {IAddress} from '@interfaces/iaddress';
import {ClientEditAddressComponent} from '../../client-edit-address/client-edit-address.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Component({
  selector: 'app-client-addresses',
  templateUrl: './client-addresses.component.html',
  styleUrls: ['./client-addresses.component.scss']
})
export class ClientAddressesComponent implements OnInit {
  @Input() addresses: IAddress[];
  @Input() clientId: string;
  displayedColumnsAddress: string[] = ['id', 'country', 'city', 'street', 'entryCreated', 'edit'];

  constructor(
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
  }

  editAddressDialog(address: IAddress) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';

    if (address) {
      dialogConfig.data = {address: address, clientId: address.clientId};
    } else {
      dialogConfig.data = {address: undefined, clientId: this.clientId};
    }
    this.dialog.open(ClientEditAddressComponent, dialogConfig);

  }
}
