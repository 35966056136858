import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IClientDetailFields} from '@interfaces/iclient-detail-fields';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-client-create-individual-field',
  templateUrl: './client-create-individual-field.component.html',
  styleUrls: ['./client-create-individual-field.component.scss']
})
export class ClientCreateIndividualFieldComponent implements OnInit {
  detailFieldFormGroup: FormGroup;
  detailField: IClientDetailFields;
  type = ['select', 'currency', 'date', 'text', 'number'];
  datevValues = [
    {id: 'totalSum', name: 'Rechnungsbetrag'},
    {id: 'currency', name: 'Währung'},
    {id: 'invoiceDueDate', name: 'Fälligkeitsdatum'},
    {id: 'accountNumber', name: 'Kreditorenkontonummer'},
    {id: 'invoiceNumber', name: 'Rechnungsnummer'},
    {id: 'tax', name: 'Steuersatz'},
    {id: 'goodsAndServices', name: 'Ware/Leistung'},
    {id: 'supplierName', name: 'Kreditorenname'},
    {id: 'invoiceDate', name: 'Rechnungsdatum'},
    {id: 'costCenter1', name: 'KOST 1'}
  ];
  possibleValues = [];
  dataSource = new MatTableDataSource(this.possibleValues);
  displayedColumns = ['possibleValue', 'delete'];
  newValueFormGroup: FormGroup;
  editMode = false;

  constructor(private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data,
              private formBuilder: FormBuilder,
              private requestManager: RequestManagerService,
              private snackBar: MatSnackBar) {
    this.detailFieldFormGroup = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      value: new FormControl({value: '', disabled: true}, Validators.required),
      function: new FormControl('', Validators.required),
      required: new FormControl(false, Validators.required),
      visibleInSummary: new FormControl(false, Validators.required)
    });

    if (data.editMode) {
      this.editMode = data['editMode'];

      this.detailField = data['clientDetailFields'];
      this.detailFieldFormGroup.patchValue({'name': this.detailField.name});
      this.detailFieldFormGroup.patchValue({'value': this.detailField.value});
      if (this.detailFieldFormGroup.get('value').value !== null || this.detailFieldFormGroup.get('value').value !== '') {
        this.detailFieldFormGroup.controls['value'].enable();
      }
      this.detailFieldFormGroup.patchValue({'type': this.detailField.type});
      this.detailFieldFormGroup.patchValue({'function': this.detailField.function});
      if (this.detailField.required === '1') {
        this.detailFieldFormGroup.patchValue({'required': true});
      } else {
        this.detailFieldFormGroup.patchValue({'required': false});
      }
      if (this.detailField.visibleInSummary === '1') {
        this.detailFieldFormGroup.patchValue({'visibleInSummary': true});
      } else {
        this.detailFieldFormGroup.patchValue({'visibleInSummary': false});
      }
      if (this.detailField.possibleValues !== null && this.detailField.possibleValues.split(',').length !== 0) {
        this.possibleValues = this.detailField.possibleValues.split(',');
        this.dataSource = new MatTableDataSource(this.possibleValues);
      }
    }
  }

  ngOnInit(): void {
    this.newValueFormGroup = this.formBuilder.group({
      newValue: new FormControl('')
    });
  }

  public async createDetailField() {
    const body = {};
    if (this.editMode) {
      if (this.detailFieldFormGroup.get('name').value !== this.detailField.name) {
        body['name'] = this.detailFieldFormGroup.get('name').value;
      }
      if (this.detailFieldFormGroup.get('type').value !== this.detailField.type) {
        body['type'] = this.detailFieldFormGroup.get('type').value;
      }
      if (this.detailFieldFormGroup.get('value').value !== this.detailField.value) {
        body['value'] = this.detailFieldFormGroup.get('value').value;
      }
      if (this.detailFieldFormGroup.get('function').value !== this.detailField.function) {
        body['function'] = this.detailFieldFormGroup.get('function').value;
      }
      if ((this.detailFieldFormGroup.get('required').value ? '1' : '0') !== this.detailField.required) {
        body['required'] = this.detailFieldFormGroup.get('required').value ? '1' : '0';
      }
      if ((this.detailFieldFormGroup.get('visibleInSummary').value ? '1' : '0') !== this.detailField.visibleInSummary) {
        body['visibleInSummary'] = this.detailFieldFormGroup.get('visibleInSummary').value ? '1' : '0';
      }
      if (this.possibleValues.toString() !== this.detailField.possibleValues) {
        body['possibleValues'] = this.possibleValues.toString();
      }
      if (Object.keys(body).length === 0) {
        return;
      }
      await this.requestManager.put('/client/' + this.data.client.id + '/clientDetailFields/' + this.detailField.id, body)
        .then(() => {
          this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
          this.dialog.closeAll();
        })
        .catch(() => {
          this.snackBar.open('Die Änderung ist fehlgeschlagen!')._dismissAfter(4000);
        });
    } else {
      body['clientId'] = this.data.client.id;
      body['name'] = this.detailFieldFormGroup.get('name').value;
      body['type'] = this.detailFieldFormGroup.get('type').value;
      body['value'] = this.detailFieldFormGroup.get('value').value;
      body['function'] = this.detailFieldFormGroup.get('function').value;
      body['required'] = this.detailFieldFormGroup.get('required').value ? '1' : '0';
      body['visibleInSummary'] = this.detailFieldFormGroup.get('visibleInSummary').value ? '1' : '0';
      if (this.detailFieldFormGroup.get('type').value === 'select') {
        body['possibleValues'] = this.possibleValues.toString();
      }
      await this.requestManager.post('/clientDetailFields', body)
        .then(() => {
          this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
          this.dialog.closeAll();
        })
        .catch(() => {
          this.snackBar.open('Die Änderung ist fehlgeschlagen!')._dismissAfter(4000);
        });
    }
  }

  async deleteDetailField() {
    await this.requestManager.delete('/clientDetailFields/' + this.detailField.id)
      .then(() => {
        this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
        this.dialog.closeAll();
      })
      .catch(() => {
        this.snackBar.open('Die Änderung ist fehlgeschlagen!')._dismissAfter(4000);
      });
  }

  selectFunction(value: any) {
    this.detailFieldFormGroup.patchValue({'function': value});
  }

  selectType(value: any) {
    this.detailFieldFormGroup.patchValue({'type': value});
    this.detailFieldFormGroup.patchValue({'value': ''});
    this.detailFieldFormGroup.controls['value'].enable();
  }

  selectValue(value: any) {
    this.detailFieldFormGroup.patchValue({'value': value});
  }

  addNewValue() {
    if ((this.newValueFormGroup.get('newValue').value.trim() === '')) {
      return;
    }
    this.possibleValues.push(this.newValueFormGroup.get('newValue').value);
    this.dataSource = new MatTableDataSource(this.possibleValues);
    this.newValueFormGroup.reset();
  }

  deleteValue(value: any) {
    this.possibleValues = this.possibleValues.filter((val) => val !== value);
    this.dataSource = new MatTableDataSource(this.possibleValues);
    if (this.possibleValues.length === 0 || this.detailFieldFormGroup.get('value').value === value) {
      this.detailFieldFormGroup.patchValue({'value': ''});
    }
  }

  public closeDialog() {
    this.dialog.closeAll();
  }

}
