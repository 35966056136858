import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

import {IDepartment} from '@interfaces/idepartment';

import {UserService} from '@services/user/user.service';

import {UserDeleteDepartmentDialogComponent} from '../../../../users/components/user-delete-department-dialog/user-delete-department-dialog.component';
import {UserAddDepartmentDialogComponent} from '../../../../users/components/user-add-department-dialog/user-add-department-dialog.component';
import {ClientCreateDepartmentComponent} from '../../client-create-department/client-create-department.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';


@Component({
  selector: 'app-client-departments',
  templateUrl: './client-departments.component.html',
  styleUrls: ['./client-departments.component.scss']
})
export class ClientDepartmentsComponent implements OnInit, OnChanges {
  @Input() clientDepartments: IDepartment[] = [];
  @Input() isLoading: boolean;
  @Input() active: any;
  public displayedColumnsDepartment: string[] = ['name', 'entryCreated', 'mailPattern', 'create'];
  public displayedColumnsDepartmentDirektUpload: string[] = ['name', 'entryCreated', 'mailPattern'];
  departmentDirektUpload: IDepartment[];

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    if (!this.active) {
      this.departmentDirektUpload = this.clientDepartments.filter(department => department.name === 'Direktupload');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clientDepartments) {
      if (changes.clientDepartments.currentValue !== changes.clientDepartments.previousValue) {
        this.clientDepartments = changes.clientDepartments.currentValue;
        this.departmentDirektUpload = this.clientDepartments.filter(department => department.name === 'Direktupload');
      }
    }
  }

  /**
   * Init dialog configurations and data, then open delete-department-dialog
   * @param row department-object that should be deleted
   */
  deleteDepartmentDialog(row: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';

    dialogConfig.data = row;

    this.dialog.open(UserDeleteDepartmentDialogComponent, dialogConfig);
  }

  /**
   * Init dialog configurations and data, then open add-department-dialog
   */
  addDepartmentDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';

    const user = this.userService.getUserSingleInformation();

    // TODO: CHANGE GET ALL DEPARTMENTS ROUTE AND FILTER DEPARTMENTS
    dialogConfig.data = this.clientDepartments;

    this.dialog.open(UserAddDepartmentDialogComponent, dialogConfig);
  }

  createNewDepartmentDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';

    this.dialog.open(ClientCreateDepartmentComponent, dialogConfig);
  }

  editDepartmentDialog(department: IDepartment) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';

    dialogConfig.data = {
      department
    };

    this.dialog.open(ClientCreateDepartmentComponent, dialogConfig);
  }
}
