import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';

import {SubSink} from 'subsink';
import * as _ from 'lodash';

import {IClient} from '@interfaces/iclient';
import {IUser} from '@interfaces/iuser';

import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {ClientService} from '@services/client/client.service';
import {UserService} from '@services/user/user.service';
import {IDepartment} from '@interfaces/idepartment';
import {DatevService} from '@services/datev/datev.service';
import {ClientActivateEmailComponent} from '../client-activate-email/client-activate-email.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {IClientModule} from '@interfaces/iclient-module';
import {IClientDatevDmsIntegration} from '@interfaces/iclient-datev-dms-integration';


@Component({
  selector: 'app-client-single',
  templateUrl: './client-single.component.html',
  styleUrls: ['./client-single.component.scss']
})
export class ClientSingleComponent implements OnInit, OnDestroy {
  public clientId: string;
  public client: IClient;
  public users: IUser[];
  public departments: IDepartment[];
  public modules: IClientModule[];
  public datevDmsIntegrationRegisters: IClientDatevDmsIntegration[];

  public dmsRegisters: any[];
  public selectedRegisterValue: string;
  public dmsRegisterSetting: any;
  public documentStatesDescription: any[];
  public documentStateSetting: any[];
  public selectedStateValue = '';
  public documentStates: any[];
  public pricingModel: string;
  public pricingModelId: number;

  public isEmailActive = false;
  public isEditVatID = false;
  public isEditTaxId = false;
  public departmentsLoading = true;
  public usersLoading = true;

  public emptyState: boolean;

  private subs = new SubSink();
  private fallbackEmail: string;
  private lastVatID: string;
  private lastTaxID: string;

  hasInvoiceUploadRight: boolean;
  hasInvoiceRight: boolean;
  hasBusinessReportRight: boolean;
  hasDmsRight: boolean;
  hasOcrRight: boolean;
  hasPaymentTransactionRight: boolean;

  dmsError: string;

  hasInvoiceUploadRightId: number;
  hasInvoiceRightId: number;
  hasBusinessReportRightId: number;
  hasDmsRightId: number;
  hasOcrRightId: number;
  hasPaymentTransactionRightId: number;

  // TODO add a new value that will be created if it doesn't already exist
  requiredSettingValues = [
    // Modules
    'incomingInvoicesUploadActive',
    'incomingInvoicesWithClearanceActive',
    'businessReportActive',
    'documentManagementActive',
    'doOCR',
    'paymentTransactionActive',

    // Settings
    'coreSettings',
    'canSeeLiveValuesOPOS',
    'canSeeLiveValuesAccountingAnalysis',
    'defaultViewDataMonthAccountingAnalysis',
    'defaultViewDataYearAccountingAnalysis',
    'defaultViewDataMonthOPOS',
    'defaultViewDataYearOPOS',
    'uploadToDATEVAfterStep',
    'connectOnlineIdentity',
    'synchronisationPeriod',
    'accountingContact',
    'businessReportInUse',
    'pricingModel',
    'DMSDirectories',
    'automaticEmailReply',
    'automaticEmailReplyText',
    'automaticEmailReplySubject',
    'uploadToDMSInsteadDUO',
    'invoiceRegisterId',
    'createPostingProposals',
    'documentStates'
  ];

  constructor(
    private route: ActivatedRoute,
    private requestManager: RequestManagerService,
    private clientService: ClientService,
    private userService: UserService,
    private dialog: MatDialog,
    public datev: DatevService,
    private snackBar: MatSnackBar,
  ) {
  }

  async ngOnInit() {
    this.emptyState = false;
    this.subs.add(
      this.route.params.subscribe(params => this.handleParams(this.userService, this.clientService, params)),
      this.clientService.update.subscribe(next => this.handleClientUpdate(this.clientService, next)),
      this.dialog.afterAllClosed.subscribe(() => this.handleClientUpdate(this.clientService, undefined)),
    );
    await this.fetchRights();
    this.modules = [];
    this.getModules();
    await this.fetchDmsRegisters();
    await this.fetchDocumentStates();
  }

  getModules() {
    this.modules.push({
      id: '1',
      name: 'Rechnungsupload',
      cost: '0',
      monthly: '1',
      active: '' + (this.hasInvoiceUploadRight ? 1 : 0),
      info: 'Mit dem Rechnungsupload Feature kannst du Belege in der Taxflow App scannen, hochladen oder per E-Mail erhalten. ' +
        'Diese Belege werden ohne OCR und ohne Freigabe direkt in Unternehmen Online oder DMS hochgeladen.'
    });
    this.modules.push({
      id: '2',
      name: 'Rechnungsfreigabe',
      cost: '25',
      monthly: '1',
      active: '' + (this.hasInvoiceRight ? 1 : 0),
      info: 'Mit dem Modul Rechnungsfreigabe kannst du Belege als Scan oder als E-Mail-Anhang an deine Buchhaltung übermitteln und einen ' +
        'detaillierten Freigabe-Workflow definieren. Du bestimmst, wer welchen Beleg inhaltlich freigeben darf und wer zur Zahlungsfreigabe ' +
        'berechtigt ist. Zudem hast du immer im Blick, welche Belege noch auf Freigabe warten, welche bereits freigegeben wurden und welche ' +
        'auf den DATEV-Server hochgeladen wurden.'
    });
    this.modules.push({
      id: '3',
      name: 'Auswertungen',
      cost: '5',
      monthly: '1',
      active: '' + (this.hasBusinessReportRight ? 1 : 0),
      info: 'Mit dem Modul Auswertungen hast du immer deine offenen Posten im Blick. Du kannst einen Zeitraum definieren, für den deine ' +
        'Erfolgsrechnung oder deine offenen Posten angezeigt werden, oder du wählst die Live-Ansicht, um den aktuellen Stand deiner ' +
        'Buchhaltung einzusehen.'
    });
    this.modules.push({
      id: '4',
      name: 'Dokumentenablage',
      cost: '5',
      monthly: '1',
      active: '' + (this.hasDmsRight ? 1 : 0),
      info: 'Mit dem Modul Dokumentenablage kannst du dein DATEV-DMS nahtlos bei deinem Mandanten integrieren. Im Admin-Panel kannst du ' +
        'für jeden Mandanten auswählen, auf welche Register er Lese- bzw. Schreibzugriff hat. In der Taxflow-App hat der Benutzer dann ' +
        'auf die ausgewählten Register Zugriff und kann hier die Dokumente lesen, herunterladen oder – falls erlaubt – auch neue ' +
        'Dokumente erstellen.'
    });
    this.modules.push({
      id: '5',
      name: 'OCR (50 Belege pro Monat kostenfrei)',
      cost: '0,15',
      monthly: '0',
      active: '' + (this.hasOcrRight ? 1 : 0),
      info: 'Mit der OCR-Funktion kommst du in den Genuss der automatischen Texterkennung für deine gescannten oder fotografierten ' +
        'Belege für eine schnellere und effizientere Abwicklung deiner Buchhaltung. Pro Monat ist die Verarbeitung von 50 Belegen ' +
        'kostenfrei enthalten; danach werden 0,15€ pro Beleg verrechnet.'
    });
    this.modules.push({
      id: '6',
      name: 'Zahlungsverkehr',
      cost: '15',
      monthly: '1',
      active: '' + (this.hasPaymentTransactionRight ? 1 : 0),
      info: 'Dieses Modul erlaubt die Verbindung zu einem Zahlungsinstitut. Benutzer können ihr Bankkonto mit der Taxflow-App verknüpfen und haben ' +
        'so Live-Zugriff auf ihren Kontostand sowie ihre Kontoumsätze und können auf Basis ihrer offenen Posten Zahlungen über ihre Bank auslösen. '
    });
  }

  updateActiveModules() {
    const body = [];
    if (this.modules[0].active !== (this.hasInvoiceUploadRight ? '1' : '0')) {
      body.push({id: this.hasInvoiceUploadRightId, settingValue: this.modules[0].active});
    }
    // Rechnungsfreigabe -> AUS .. thereafter request: DATEV Upload: Hochladen nach -> Direkt
    let hasInvoiceRightSwitchToOff = false;
    if (this.modules[1].active !== (this.hasInvoiceRight ? '1' : '0')) {
      body.push({id: this.hasInvoiceRightId, settingValue: this.modules[1].active});
      if (this.modules[1].active === '0') {
        hasInvoiceRightSwitchToOff = true;
      }
    }
    if (this.modules[2].active !== (this.hasBusinessReportRight ? '1' : '0')) {
      body.push({id: this.hasBusinessReportRightId, settingValue: this.modules[2].active});
    }
    if (this.modules[3].active !== (this.hasDmsRight ? '1' : '0')) {
      body.push({id: this.hasDmsRightId, settingValue: this.modules[3].active});
    }
    if (this.modules[4].active !== (this.hasOcrRight ? '1' : '0')) {
      body.push({id: this.hasOcrRightId, settingValue: this.modules[4].active});
    }
    if (this.modules[5].active !== (this.hasPaymentTransactionRight ? '1' : '0')) {
      body.push({id: this.hasPaymentTransactionRightId, settingValue: this.modules[5].active});
    }
    if (body.length !== 0) {
      this.requestManager.put('/clientSettings', body)
        .then(() => {
          body.forEach(value => {
            if (value.id === this.hasInvoiceUploadRightId) {
              this.hasInvoiceUploadRight = !this.hasInvoiceUploadRight;
            }
            if (value.id === this.hasInvoiceRightId) {
              this.hasInvoiceRight = !this.hasInvoiceRight;
            }
            if (value.id === this.hasBusinessReportRightId) {
              this.hasBusinessReportRight = !this.hasBusinessReportRight;
            }
            if (value.id === this.hasDmsRightId) {
              this.hasDmsRight = !this.hasDmsRight;
            }
            if (value.id === this.hasOcrRightId) {
              this.hasOcrRight = !this.hasOcrRight;
            }
            if (value.id === this.hasPaymentTransactionRightId) {
              this.hasPaymentTransactionRight = !this.hasPaymentTransactionRight;
            }
          });
          if (hasInvoiceRightSwitchToOff) {
            const settingUploadToDATEVAfterStep = this.client.clientSettings
              .filter((setting) =>
                setting.settingName.includes('uploadToDATEVAfterStep')
              )
              .sort((a, b) => a.settingName.localeCompare(b.settingName));
            const settingUploadToDATEVAfterStepId = settingUploadToDATEVAfterStep[0].id;
            // 'Direkt' = '0'
            const putBody = {
              settingValue: '0',
            };
            this.requestManager.put('/clientSettings/' + settingUploadToDATEVAfterStepId, putBody)
              .then(() => {
                this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
              });

            // disable all departments except "Direktupload"
            let hasDirektUpload = false;
            this.departments.forEach(department => {
              if (department.name !== 'Direktupload') {
                if (department.disabled !== '1') {
                  this.requestManager.put('/departments/' + department.id, {disabled: '1'});
                }
              } else {
                hasDirektUpload = true;
              }
            });

            if (!hasDirektUpload) {
              this.requestManager.post('/departments', {name: 'Direktupload'})
                .then(async (value) => {
                  this.departments.forEach(department => {
                    if (department.name !== 'Direktupload') {
                      department.disabled = '1';
                    }
                  });
                  await this.requestManager.post('/clientDepartments', {clientId: this.client.id, departmentsId: '' + value[0]});
                  const settingIncomingInvoicesMailFallbackDepartmentsId = this.client.clientSettings
                    .filter((setting) =>
                      setting.settingName.includes('incomingInvoicesMailFallbackDepartmentsId')
                    )[0];
                  await this.requestManager.put(
                    '/clientSettings/' + settingIncomingInvoicesMailFallbackDepartmentsId.id, {settingValue: '' + value[0]});
                  const clientDepartments = await this.requestManager.get('/adminArea/client/' + this.client.id + '/departments');
                  if (clientDepartments['departments'] !== undefined) {
                    this.departments = clientDepartments['departments'].sort((a, b) => a.disabled ? 1 : -1);
                  }
                })
                .catch(() => {
                  this.snackBar.open('Die Änderung ist fehlgeschlagen!')._dismissAfter(4000);
                });
            }
          }
          this.snackBar.open('Änderung wurde gespeichert!')._dismissAfter(2000);
        });
    }
  }

  switchPricingModel() {
    this.pricingModel = 'v2';
  }

  async fetchDocumentStates() {
    this.documentStatesDescription = [];
    this.documentStates = [];
    let documents = await this.requestManager.get('adminArea/client/' + this.clientId + '/dms/documentStates');
    if (documents.http_code === 400) {
      this.dmsError = 'Beim Abruf der Daten aus DMS ist auf dem DATEV System folgender Fehler aufgetreten:\n\r'
        + _.get(documents, 'body').error_description;
      return;
    }
    documents = _.get(documents, 'body');

    if (documents) {
      Object.values(documents).forEach((state: any) => {
        this.documentStatesDescription.push(state.description);
        this.documentStates.push(state);
      });
    }

    let documentSetting = await this.requestManager.get('/adminArea/client/' + this.clientId + '/clientSettings', {array_values: true});

    let documentStateSetting = documentSetting.clientSettings?.find(s => s.settingName == 'documentStates');

    if (documentStateSetting === undefined) {
      let stateOffen = this.documentStates.filter((state) => state.description === 'offen');
      if (stateOffen !== undefined && stateOffen.length !== 0) {
        stateOffen = stateOffen[0];
        await this.requestManager.post('/clientSettings', {
          clientId: this.clientId,
          settingName: 'documentStates',
          settingValue: stateOffen['id']
        });
        documentSetting = await this.requestManager.get('/adminArea/client/' + this.clientId + '/clientSettings/settingValues',
          {array_values: true});
      } else {
        await this.requestManager.post('/clientSettings', {clientId: this.clientId, settingName: 'documentStates'});
        documentSetting = await this.requestManager.get('/adminArea/client/' + this.clientId + '/clientSettings/settingValues',
          {array_values: true});
      }

      documentStateSetting = documentSetting.clientSettings?.find(s => s.settingName == 'documentStates');
    }

    this.documentStateSetting = documentStateSetting;
    if (documents && documents.error !== 'Key could not be found') {
      documents.forEach(state => {
        if (state.id === documentStateSetting.settingValue) {
          this.selectedStateValue = state.description;
        }
      });
    }
  }

  async fetchRights() {
    let rights = await this.requestManager.get('/adminArea/client/' + this.clientId + '/clientSettings', {array_values: true});

    let newSetting = false;
    this.requiredSettingValues.forEach(required => {
      if (rights.clientSettings.find(setting => setting.settingName === required) === undefined) {
        this.createSettingValue(required);
        newSetting = true;
      }
    });

    if (newSetting) {
      rights = await this.requestManager.get('/adminArea/client/' + this.clientId + '/clientSettings',
        {array_values: true});
    }

    const pricingModelSetting = rights.clientSettings.find(s => s.settingName === 'pricingModel');
    this.pricingModelId = pricingModelSetting.id;
    this.pricingModel = pricingModelSetting.settingValue;

    const hasInvoiceUploadRightSetting = rights.clientSettings.find(s => s.settingName === 'incomingInvoicesUploadActive');
    this.hasInvoiceUploadRight = hasInvoiceUploadRightSetting.settingValue != '0';
    this.hasInvoiceUploadRightId = hasInvoiceUploadRightSetting.id;

    const hasInvoiceRightSetting = rights.clientSettings.find(s => s.settingName === 'incomingInvoicesWithClearanceActive');
    this.hasInvoiceRight = hasInvoiceRightSetting.settingValue != '0';
    this.hasInvoiceRightId = hasInvoiceRightSetting.id;

    const hasBusinessReportRightSetting = rights.clientSettings.find(s => s.settingName === 'businessReportActive');
    this.hasBusinessReportRight = hasBusinessReportRightSetting.settingValue != '0';
    this.hasBusinessReportRightId = hasBusinessReportRightSetting.id;

    const hasDmsRightSetting = rights.clientSettings.find(s => s.settingName === 'documentManagementActive');
    this.hasDmsRight = hasDmsRightSetting.settingValue != '0';
    this.hasDmsRightId = hasDmsRightSetting.id;

    const hasOcrRightSetting = rights.clientSettings.find(s => s.settingName === 'doOCR');
    this.hasOcrRight = hasOcrRightSetting.settingValue != '0';
    this.hasOcrRightId = hasOcrRightSetting.id;

    const hasPaymentTransactionRightSetting = rights.clientSettings.find(s => s.settingName === 'paymentTransactionActive');
    this.hasPaymentTransactionRight = hasPaymentTransactionRightSetting.settingValue != '0';
    this.hasPaymentTransactionRightId = hasPaymentTransactionRightSetting.id;
  }

  async createSettingValue(settingName: string) {
    const body = {};
    body['clientId'] = this.clientId;
    body['settingName'] = settingName;

    // Modules
    if (settingName === 'incomingInvoicesUploadActive') {
      body['settingValue'] = '1';
    }
    if (settingName === 'incomingInvoicesWithClearanceActive') {
      body['settingValue'] = '0';
    }
    if (settingName === 'businessReportActive') {
      body['settingValue'] = '0';
    }
    if (settingName === 'documentManagementActive') {
      body['settingValue'] = '0';
    }
    if (settingName === 'doOCR') {
      body['settingValue'] = '0';
    }
    if (settingName === 'paymentTransactionActive') {
      body['settingValue'] = '0';
    }

    // Pricing Model
    if (settingName === 'pricingModel') {
      body['settingValue'] = 'v1';
    }
    await this.requestManager.post('/clientSettings', body);
  }

  /**
   * Fetch all registers from DATEV
   */
  async fetchDmsRegisters() {
    this.datevDmsIntegrationRegisters = [];
    this.dmsRegisters = [];
    let settingsResponse = await this.requestManager.get('/adminArea/client/' + this.clientId + '/clientSettings/settingValues',
      {array_values: true});
    let filteredSetting = settingsResponse.clientSettings?.find(s => s.settingName == 'DMSDirectories');

    if (filteredSetting === undefined) {
      await this.requestManager.post('/clientSettings', {clientId: this.clientId, settingName: 'DMSDirectories'});
      settingsResponse = await this.requestManager.get('/adminArea/client/' + this.clientId + '/clientSettings/settingValues',
        {array_values: true});
      filteredSetting = settingsResponse.clientSettings?.find(s => s.settingName == 'DMSDirectories');
    }

    const settings = [];

    if (filteredSetting) {
      filteredSetting.settingValues?.forEach((settingValue: any) => {
        settings.push({
          registerId: settingValue.settingKey,
          clientSettingId: filteredSetting.id,
          settingValueId: settingValue.id,
          canRead: settingValue.settingValue.includes('r'),
          canWrite: settingValue.settingValue.includes('w'),
        });
      });
    }

    const response = await this.requestManager.get('/adminArea/client/' + this.clientId + '/dms/registers');
    if (response.http_code === 400) {
      this.dmsError = 'Beim Abruf der Daten aus DMS ist auf dem DATEV System folgender Fehler aufgetreten:\n\r'
        + _.get(response, 'body').error_description;
      return;
    }
    const array = _.get(response, 'body');
    if (array && array.error !== 'Key could not be found') {
      Object.values(array).forEach((outerListItem: any) => {
        const outerName = outerListItem.name;
        Object.values(outerListItem.folders).forEach((folder: any) => {
          const folderName = folder.name;
          Object.values(folder.registers).forEach((register: any) => {

            const setting = settings.find(s => s.registerId == register.id);

            this.datevDmsIntegrationRegisters.push({
              registerId: register.id,
              name: register.name,
              path: outerName + ' > ' + folderName,
              clientSettingId: setting?.clientSettingId || filteredSetting.id,
              settingValueId: setting?.settingValueId,
              canRead: setting?.canRead || false,
              canWrite: setting?.canWrite || false,
              isChanged: false,
            });
          });
        });
      });
      this.dmsRegisters = this.datevDmsIntegrationRegisters;
      this.emptyState = false;
      this.dmsRegisterSetting = settingsResponse.clientSettings?.find(s => s.settingName == 'invoiceRegisterId');
      this.dmsRegisters.forEach(register => {
        if (register.registerId === this.dmsRegisterSetting.settingValue) {
          this.selectedRegisterValue = register.name + ' ' + register.path;
        }
      });
    } else {
      this.emptyState = true;
    }
  }

  /**
   * Handle parameters subscription and get all information needed for /user/{id}
   * @param userService userService passed because 'this' is another scope
   * @param clientService clientService passed because 'this' is another scope
   * @param params Parameters given in url
   */
  private async handleParams(userService: UserService, clientService: ClientService, params: Params) {
    this.setClientIds(params['id']);
    // noinspection JSIgnoredPromiseFromCall
    this.isDatevStatusValid();

    this.departmentsLoading = true;
    this.usersLoading = true;
    [this.users, this.client, this.departments] = await Promise.all([
      userService.getUserList(),
      clientService.getClientSingle(this.clientId),
      this.requestManager.get('/adminArea/client/' + this.clientId + '/departments') // TODO: UPDATE ROUTE WHEN IMPLEMENTED
    ]);
    this.users = this.users['user'];
    this.users.sort((a) => a.deleted ? 1 : -1);
    this.usersLoading = false;
    if (this.departments['departments'] === undefined || this.departments['departments'].length === 0) {
      this.requestManager.post('/departments', {name: 'Direktupload'})
        .then(async (value) => {
          await this.requestManager.post('/clientDepartments', {clientId: this.client.id, departmentsId: '' + value[0]});
          const settingIncomingInvoicesMailFallbackDepartmentsId = this.client.clientSettings
            .filter((setting) =>
              setting.settingName.includes('incomingInvoicesMailFallbackDepartmentsId')
            )[0];
          await this.requestManager.put(
            '/clientSettings/' + settingIncomingInvoicesMailFallbackDepartmentsId.id, {settingValue: '' + value[0]});
          const clientDepartments = await this.requestManager.get('/adminArea/client/' + this.client.id + '/departments');
        })
        .catch(() => {
          this.snackBar.open('Die Änderung ist fehlgeschlagen!')._dismissAfter(4000);
        });
    }
    if (this.departments['departments'] !== undefined) {
      this.departments = this.departments['departments'].sort((a, b) => a.disabled ? 1 : -1);
    }
    this.departmentsLoading = false;

    this.client = this.client['client'][0];

    await this.isEmailServiceActivated();
  }

  private setClientIds(id: string) {
    this.clientId = id;
    this.datev.setClientId(this.clientId);
    this.requestManager.setClientId(this.clientId);
  }

  /**
   * Handle clientService updates called from child components by actualizing the client object
   * @param clientService ClientService that needs to be updated
   * @param next Next value that is emitted by clientService call from child
   */
  private async handleClientUpdate(clientService: ClientService, next) {
    if (next === 'registers') {
      await this.fetchDmsRegisters();
    } else {
      this.departmentsLoading = true;
      [this.client, this.departments] = await Promise.all([
        clientService.getClientSingle(this.clientId),
        this.requestManager.get('/adminArea/client/' + this.clientId + '/departments')
      ]);
      if (this.departments['departments'] !== undefined) {
        this.departments = this.departments['departments'].sort((a, b) => a.disabled ? 1 : -1);
      }
      this.departmentsLoading = false;
      this.client = this.client['client'][0];
      await this.isEmailServiceActivated();
    }
  }

  /**
   * Unsubscribe from all subscriptions
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Opens a modal for email activation via the dialog service
   */
  openActivateEmailModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = '600px';
    dialogConfig.data = {
      departments: this.departments,
      clientId: this.clientId,
    };

    const emailDialog = this.dialog.open(ClientActivateEmailComponent, dialogConfig);
    emailDialog.afterClosed().subscribe(() => this.isEmailServiceActivated());
  }

  /**
   * Check if email service is active
   */
  async isEmailServiceActivated() {
    this.client = await this.clientService.getClientSingle(this.clientId);
    this.client = this.client['client'][0];
    this.fallbackEmail = _.get(this.client.clientSettings
      .find(s => (s.settingName.includes('incomingInvoicesMailAddress'))), 'settingValue');
    this.isEmailActive = !!this.fallbackEmail;
  }

  /**
   * Request datev status
   */
  private async isDatevStatusValid() {
    try {
      const status = await this.datev.getStatus();
      if (status && status['error']) {
        await this.datev.disconnectFromDatev();
      }
    } finally {
    }
  }


  /**
   * Activate edit mode for vatId and save last information into lastVatID
   * @param client Client that has the vatId property that should be changed
   */
  editVatID(client) {
    this.lastVatID = client.vatId;
    this.isEditVatID = true;
  }


  /**
   * Try to save new vatID and response with snackbar service on failure
   * @param client Client that has the vatId property that should be changed
   */
  async saveVatID(client: IClient) {
    this.isEditVatID = false;
    if (client.vatId != this.lastVatID) {
      try {
        await this.requestManager.put('/client/' + client.id, {vatId: client.vatId});
      } catch (e) {
        this.snackBar.open(`Die eingegebene USt-Id war invalide und konnte deshalb nicht gespeichert werden!`, 'verstanden');
        client.vatId = this.lastVatID;
      }
    }
  }

  /**
   * Activate edit mode for taxIdentificationNumber and save last information into lastTaxId
   * @param client Client that has the taxId property that should be changed
   */
  editTaxID(client: IClient) {
    this.lastTaxID = client.taxIdentificationNumber;
    this.isEditTaxId = true;
  }

  /**
   * Save new taxID
   * @param client Client that has the taxId property that should be changed
   */
  async saveTaxID(client: IClient) {
    this.isEditTaxId = false;
    if (client.taxIdentificationNumber != this.lastTaxID) {
      try {
        await this.requestManager.put('/client/' + client.id, {taxIdentificationNumber: client.taxIdentificationNumber});
      } catch (e) {
        console.warn(e);
        client.taxIdentificationNumber = this.lastTaxID;
      }
    }
  }
}
