import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { RequestManagerService } from '@services/request-manager/request-manager.service';
import {
  ClientUploadBusinessReportComponent,
  IClientUploadBusinessReportData,
} from '../../client-upload-business-report/client-upload-business-report.component';
import { Component, Input, OnInit } from '@angular/core';
import { IBusinessReport } from '@interfaces/ibusiness-report';
import { IClientSettings } from '@interfaces/iclient-settings';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

type IBusinessReportExt = IBusinessReport & { ['inUse']: boolean };

@Component({
  selector: 'app-client-business-reports',
  templateUrl: './client-business-reports.component.html',
  styleUrls: ['./client-business-reports.component.scss'],
})
export class ClientBusinessReportsComponent implements OnInit {
  @Input() clientId: string;
  public settings: Array<IClientSettings>;
  public businessReports: Array<IBusinessReportExt> = [];
  public businessReportInUse: string;
  public isLoading = true;
  public displayedColumnsBusinessReports: string[] = ['inUse', 'name', 'entryCreated', 'create'];

  SETTING_NAME = 'businessReportInUse'; // should be in const somewhere

  constructor(
    private dialog: MatDialog,
    private requestManager: RequestManagerService,
    private snackbar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.loadBusinessReports();
  }

  async loadBusinessReports() {
    this.isLoading = true;
    const resp = await this.requestManager.get(
      '/businessReports?array_values=true'
    );

    const standardBusinessReport = {
      clientId: null,
      entryCreated: null,
      entryUpdated: null,
      id: null,
      name: 'Standard Auswertung',
      description: null,
      inUse: null,
    };
    this.businessReports = [standardBusinessReport];
    if (resp && resp['businessReports'] && resp['businessReports'].length > 0) {
      this.businessReports = this.businessReports.concat(
        resp['businessReports']
          .filter((el) => el.clientId === this.clientId)
          .map((el) => ({ ...el, inUse: null }))
      );
    }

    this.settings = await this.loadClientSettings();
    this.businessReportInUse = await this.getClientSettingValueByName(
      this.SETTING_NAME
    );
    const theReportInUse = this.businessReports.find(
      (el) =>
        el.id === this.businessReportInUse ||
        (el.id === null &&
          (this.businessReportInUse === '0' ||
            this.businessReportInUse === '' ||
            !this.businessReportInUse))
    );
    if (theReportInUse) {
      theReportInUse.inUse = true;
    } else {
      console.warn(
        'Current businessReportInUse id out of bound',
        this.businessReportInUse
      );
    }

    this.isLoading = false;
  }

  async getClientSettingValueByName(settingName: string) {
    if (this.settings && this.settings.length > 0) {
      const setting = this.settings.find(
        (el) => el.settingName === settingName
      );
      if (setting) {
        return setting.settingValue;
      }
    }
    return null;
  }

  async loadClientSettings() {
    const settings = await this.requestManager.get(
      `/adminArea/client/${this.clientId}/clientSettings?array_values=true`
    );
    if (
      !settings ||
      !settings.clientSettings
    ) {
      throw new Error('Could not load client settings!');
    }
    return settings.clientSettings;
  }

  async setStandardBusinessReport(businessReport: IBusinessReportExt, doReload = true) {
    const setting = this.settings.find(
      (el) => el.settingName === this.SETTING_NAME
    );
    const putBody = {
      settingValue: businessReport.id ? businessReport.id : '0',
    };

    try {
      if (this.businessReportInUse && setting) {
        await this.requestManager.put('/clientSettings/' + setting.id, putBody);
      } else {
        const postBody = {
          ...putBody,
          clientId: this.clientId,
          settingName: this.SETTING_NAME,
        };
        await this.requestManager.post('/clientSettings', postBody);
      }
      if (doReload) {
        await this.loadBusinessReports();
      }
      this.snackbar.open('Änderung wurde gespeichert!', null, {
        duration: 2000,
      });
    } catch (err) {
      console.error(err);
      this.snackbar.open(`Die Änderung ist fehlgeschlagen! ${err}`, null, {
        duration: 2000,
      });
    }
  }

  async deleteBusinessReport($event, businessReport: IBusinessReportExt) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      minWidth: '250px',
      data: businessReport
    });

    const areYouSure = await dialogRef.afterClosed().toPromise();
    if (!areYouSure) { return; }

    try {
      if (businessReport && businessReport.id) {
        if (businessReport.inUse) {
          await this.setStandardBusinessReport(this.businessReports[0], false);
        }

        await this.requestManager.delete(
          `/businessReports/${businessReport.id}`
        );

        await this.loadBusinessReports();
        this.snackbar.open('Business Report wurde gelöscht!', null, {
          duration: 2000,
        });
      } else {
        // TODO: warn user
      }
    } catch (err) {
      console.error(err);
      this.snackbar.open(
        `Beim Löschen ist ein Fehler aufgetreten! ${err}`,
        null,
        {
          duration: 2000,
        }
      );
    }
  }

  uploadBusinessReportDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';
    dialogConfig.data = {
      clientId: this.clientId,
    } as IClientUploadBusinessReportData;

    const dialogRef = this.dialog.open(
      ClientUploadBusinessReportComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadBusinessReports();
      }
    });
  }
}
