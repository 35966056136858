<mat-card-content *ngIf="dmsRegisters && !isLoading" class="table-container">
  <div *ngIf="dmsError === undefined">
    <div *ngIf="!emptyState">
      <table mat-table class="width-100" [dataSource]="dmsRegisters">

        <!-- Register Column -->
        <ng-container matColumnDef="register">
          <th style="width: 60%" mat-header-cell *matHeaderCellDef>Freigeschaltete Register</th>
          <td mat-cell *matCellDef="let element">
            <div>
              {{element.name}}
            </div>
            <div style="padding-left: 3px; font-size: small">
              {{element.path}}
            </div>
          </td>
        </ng-container>

        <!-- Read -->
        <ng-container matColumnDef="read">
          <th mat-header-cell *matHeaderCellDef class="right-cell">Lesen</th>
          <td mat-cell *matCellDef="let element" class="right-cell">
            <mat-checkbox
              [checked]="element.canRead"
              (change)="changeRead($event.checked, element)"
            ></mat-checkbox>
        </ng-container>

        <!-- Write -->
        <ng-container matColumnDef="write">
          <th mat-header-cell *matHeaderCellDef class="right-cell">Schreiben</th>
          <td mat-cell *matCellDef="let element" class="right-cell">
            <mat-checkbox
              [checked]="element.canWrite"
              (change)="changeWrite($event.checked, element)"
            ></mat-checkbox>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedRegisters; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedRegisters;"></tr>
      </table>

      <div style="position: sticky; bottom:0; padding: 0.5em; background: #fff; text-align: end">
        <button mat-raised-button color="primary" (click)="save()">Speichern</button>
      </div>
    </div>

    <div *ngIf="emptyState" style="text-align: center; justify-content: center; justify-items: center">
      Wir könnten keine Dokumentenstatus finden. <br>
      Wende dich an den Support.
    </div>
  </div>
  <div *ngIf="dmsError !== undefined">
    {{dmsError}}
  </div>
</mat-card-content>

<mat-card-content *ngIf="isLoading">
  <div class="empty-array">
    <mat-spinner></mat-spinner>
  </div>
</mat-card-content>
