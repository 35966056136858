<mat-radio-group class="radio-group" [(ngModel)]="selectedDepartment">
  <mat-radio-button class="radio-button" *ngFor="let department of departments" [value]="department">{{department.name}}</mat-radio-button>
</mat-radio-group>
<div *ngIf="departments === undefined" style="text-align: center">
  <p>
    Keine Abteilungen vorhanden
  </p>
</div>
<div *ngIf="departments !== undefined" class="flex-end">
  <button mat-raised-button color="primary" (click)="activateEmailService()">Aktivieren</button>
</div>
