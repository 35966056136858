import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {IDepartment} from '@interfaces/idepartment';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ClientCreateDepartmentInfoComponent} from '../client-create-department-info/client-create-department-info.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-create-department',
  templateUrl: './client-create-department.component.html',
  styleUrls: ['./client-create-department.component.scss']
})
export class ClientCreateDepartmentComponent implements OnInit {
  public departmentFormGroup: FormGroup;
  public isPutMode = false;
  private department: IDepartment;
  private actualId = 0;
  private lastPutId = 0;
  public amountEmails: number[] = [];
  private keys: string[];

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private requestManager: RequestManagerService,
    @Inject(MAT_DIALOG_DATA) public data: IDepartment,
    private snackBar: MatSnackBar
  ) {
    if (data) {
      this.department = data['department'];
      this.isPutMode = true;

      // Initialize formGroup with depending on values
      this.departmentFormGroup = this.formBuilder.group({
        departmentName: new FormControl(data['department'].name, Validators.required),
        departmentDocumentType: new FormControl(data['department'].documentType),
      });

      if (this.department.departmentsMailPattern) {
        this.department.departmentsMailPattern.forEach(
          mailPattern => {
            this.departmentFormGroup
              .addControl('mailPattern' + mailPattern.id, new FormControl(mailPattern.mailPattern, Validators.required));
            this.amountEmails.push(Number(mailPattern.id));
          }
        );

        this.keys = Object.keys(this.departmentFormGroup.controls); // get all keys for formControls
        this.keys.forEach(key => {
            if (key.startsWith('mailPattern')) {
              this.actualId = Math.max(Number(key.replace('mailPattern', '')), this.actualId);
            }
          }
        );
      } else {
        this.department.departmentsMailPattern = [];
      }

      this.actualId++;
      this.lastPutId = this.actualId - 1;

    } else {
      this.departmentFormGroup = this.formBuilder.group({
        departmentName: new FormControl('', Validators.required),
        departmentDocumentType: new FormControl(''),
      });

    }
  }

  ngOnInit() {
  }

  public async createEditDepartment() {
    const departmentBody = {
      name: this.departmentFormGroup.get('departmentName').value,
      documentType: this.departmentFormGroup.get('departmentDocumentType').value || '',
    };
    const clientId = await this.requestManager.getClientId();
    let departmentsId: string;

    if (this.isPutMode) {
      departmentsId = this.department.id;
      await this.requestManager.put('/departments/' + departmentsId, departmentBody);
    } else {
      departmentsId = await this.requestManager.post('/departments', departmentBody);
      departmentsId = departmentsId[0];
    }

    const clientDepartmentsBody = {clientId: clientId, departmentsId};

    if (this.isPutMode) {
      this.keys = Object.keys(this.departmentFormGroup.controls); // get all keys for formControls
      for (const key of this.keys) {
        if (key.startsWith('mailPattern')) {
          const body = {mailPattern: this.departmentFormGroup.get(key).value, departmentsId};
          const mailPatternId = key.replace('mailPattern', '');
          if (Number(mailPatternId) <= this.lastPutId) {
            await this.requestManager.put('/departmentsMailPattern/' + mailPatternId, body);
          } else {
            await this.requestManager.post('/adminArea/client/' + clientId + '/departmentsMailPattern', body);
          }
        }
      }
    } else {
      const promises = [];
      this.keys = Object.keys(this.departmentFormGroup.controls); // get all keys for formControls
      for (const key of this.keys) {
        if (key.startsWith('mailPattern')) {
          const body = {mailPattern: this.departmentFormGroup.get(key).value, departmentsId};
          promises.push(this.requestManager.post('/adminArea/client/' + clientId + '/departmentsMailPattern', body));
        }
      }
      promises.push(this.requestManager.post('/clientDepartments', clientDepartmentsBody));
      await Promise.all(promises);
    }
    this.closeDialog();
  }

  /**
   * Close actual dialog window
   */
  public closeDialog() {
    this.dialog.closeAll();
  }

  /**
   * Add new mailPattern to html and formGroup
   */
  public addEmailControl() {
    this.actualId++;
    this.amountEmails.push(this.actualId);
    this.departmentFormGroup.addControl('mailPattern' + this.actualId, new FormControl('', Validators.required));
  }

  /**
   * Delete specific mailPattern from html and formGroup
   * @param i index that should be deleted
   */
  public async deleteEmailControl(i: number) {
    if (this.isPutMode) {
      await this.requestManager.delete('/adminArea/departmentsMailPattern/' + this.amountEmails[i]);
    }
    this.departmentFormGroup.removeControl('mailPattern' + this.amountEmails[i]);
    this.amountEmails.splice(i, 1);
    this.department.departmentsMailPattern.splice(i, 1);
  }

  showDocumentTypeInfo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    this.dialog.open(ClientCreateDepartmentInfoComponent, dialogConfig);
  }

  async disableUserDialog() {
    const clientId = await this.requestManager.getClientId();
    const settings = await this.requestManager.get('/adminArea/client/' + clientId + '/clientSettings', {array_values: true});
    const incomingSetting = settings.clientSettings.find(s => s.settingName == 'incomingInvoicesMailFallbackDepartmentsId');

    if (this.department.id === incomingSetting.settingValue) { // standard department cant be disabled
      this.snackBar.open('Dies ist die Standardabteilung, daher kann sie nicht deaktiviert werden.', 'verstanden');
    } else if (this.department.departmentsMailPattern.length > 0) { // department mustn't have email pattern to be disabled
      this.snackBar.open('Um die Abteilung zu deaktivieren müssen zuerst die E-Mail Vorlagen entfernt werden.', 'verstanden');
    } else {
      this.department.disabled = this.department.disabled === '1' ? '0' : '1';
      const postBody = {
        disabled: this.department.disabled
      };
      await this.requestManager.put('/departments/' + this.department.id, postBody);
    }
  }
}
