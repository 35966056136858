import {Injectable} from '@angular/core';

import {ICredentials} from '@interfaces/icredentials';

import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';
import {Observable} from 'rxjs';
import {TokenStorageService} from '@services/token-storage/token-storage.service';

/**
 * Request manager to handle, that handles all Requests to the backend and saves information about backend server and cookies
 * It's possible to add where conditions to GET-requests
 * Available request types:
 * GET, POST, PUT, DELETE
 */
@Injectable({
  providedIn: 'root'
})
export class RequestManagerService {
  private baseURL = ''; // TODO: unset this variable
  private clientId = undefined;
  private whereConditions = undefined;

  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) {
    if (this.tokenStorageService.getUrl()) {
      this.setBaseUrl(this.tokenStorageService.getUrl());
    }
  }

  /**
   * Set the client id for all dependent requests
   * @param clientId client id of actually selected client
   */
  setClientId(clientId: string): void {
    this.clientId = clientId;
  }

  /**
   * Get the client id of actually selected client
   */
  getClientId(): string {
    return this.clientId;
  }

  /**
   * Set base url for all following requests
   * @param baseUrl base url for requests
   */
  setBaseUrl(baseUrl: string): void {
    this.baseURL = 'https://' + baseUrl + '/index.php';
  }

  /**
   * Authenticate user by login credentials
   * @param body credentials for logging in
   */
  async auth(body: ICredentials): Promise<any> {
    this.setBaseUrl(body.url);

    // auth
    let authRes;
    try {
      authRes = await this.http.post(this.baseURL + '/auth', body).toPromise();
    } catch (err) {
      return err;
    }
    return authRes;
  }

  async authRefresh(body: { refreshToken: string }): Promise<any> {
    let authRes;
    try {
      authRes = await this.http.post(this.baseURL + '/auth/refresh', body).toPromise();
    } catch (err) {
      return err;
    }
    return authRes;
  }

  /**
   * GET-Request from route with parameters
   * @param route route endpoint
   * @param parameters parameters that can be passed e.g. exclude fields etc.
   * @param useWhereConditions if true requestManagers where conditions will be used
   */
  async get(route: string, parameters: any = null, useWhereConditions: boolean = false): Promise<any> {
    if (useWhereConditions) {
      if (parameters === null) {
        parameters = {};
      }
      parameters['where_condition'] = JSON.stringify(this.whereConditions);
    }

    const options = {
      headers: new HttpHeaders({
        Authorization: this.tokenStorageService.getAccessToken()
      }),
      params: parameters
    };
    return await this.http.get(this.baseURL + route, options).toPromise();
  }

  /**
   * POST-Request from route with body
   * @param route route endpoint
   * @param body body that contains data for backend e.g. IEncryptedMessage.ts.
   */
  async post(route: string, body: {}): Promise<any> {
    const options = {
        headers: new HttpHeaders({
          Authorization: this.tokenStorageService.getAccessToken()
        })
      }
    ;
    return await this.http.post(this.baseURL + route, body, options).toPromise();
  }

  /**
   * Returns Promise<string> of the actual logged-in user
   */
  async getUserId(): Promise<number> {
    const jwt = this.tokenStorageService.getAccessToken();
    const decodedJWT = jwt_decode(jwt);
    return decodedJWT['data']['user_id'];

  }

  /**
   * PUT-Request from route with body
   * @param route route endpoint
   * @param body body that contains data for backend e.g. IEncryptedMessage.ts.
   */
  async put(route: string, body: {}): Promise<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.tokenStorageService.getAccessToken()
      })
    };
    return await this.http.put(this.baseURL + route, body, options).toPromise();
  }

  /**
   * DELETE-Request from route
   * @param route route that shows what should be deleted
   */
  async delete(route: string) {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.tokenStorageService.getAccessToken(),
      })
    };
    return await this.http.delete(this.baseURL + route, options).toPromise();
  }


  sget(route: string, parameters: any = null, useWhereConditions: boolean = false): Observable<any> {
    if (useWhereConditions) {
      if (parameters === null) {
        parameters = {};
      }
      parameters['where_condition'] = JSON.stringify(this.whereConditions);
    }

    const options = {
      headers: new HttpHeaders({
        Authorization: this.tokenStorageService.getAccessToken()
      }),
      params: parameters
    };
    return this.http.get(this.baseURL + route, options);
  }

  /**
   * POST-Request from route with body
   * @param route route endpoint
   * @param body body that contains data for backend e.g. IEncryptedMessage.ts.
   */
  spost(route: string, body: {}): Observable<any> {
    const options = {
        headers: new HttpHeaders({
          Authorization: this.tokenStorageService.getAccessToken()
        })
      }
    ;
    return this.http.post(this.baseURL + route, body, options);
  }

  uploadFile(route: string, formData: FormData): Observable<HttpEvent<any>> {
    const params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
        Authorization: this.tokenStorageService.getAccessToken()
      })
    };

    const req = new HttpRequest('POST', this.baseURL + route, formData, options);
    return this.http.request(req);
  }

  /**
   * PUT-Request from route with body
   * @param route route endpoint
   * @param body body that contains data for backend e.g. IEncryptedMessage.ts.
   */
  sput(route: string, body: {}): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.tokenStorageService.getAccessToken()
      })
    };
    return this.http.put(this.baseURL + route, body, options);
  }

  /**
   * DELETE-Request from route
   * @param route route that shows what should be deleted
   */
  sdelete(route: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.tokenStorageService.getAccessToken(),
      })
    };
    return this.http.delete(this.baseURL + route, options);
  }

  /**
   * Reset all whereConditions of requestManager
   */
  resetWhereCondition(): void {
    this.whereConditions = [];
  }

  /**
   * Add a whereCondition to the array
   * @param field field that should be searched for
   * @param operator operator field must be compared to
   * @param value value field must be compared to
   * @param model model field is specified in
   */
  // tslint:disable-next-line:no-unnecessary-initializer
  addWhereCondition(field: string, operator: string, value: string, model: string, previousOperator = undefined): void {
    this.whereConditions.push({
      field,
      operator,
      value,
      'followingOperator': '',
      model,
      previousOperator
    });
  }
}
