<div mat-dialog-title class="modal-title">
  Berechtigungen
</div>
<hr class="hr-margin-bottom">

<div mat-dialog-title class="flex-row">
  <h4 style="margin: 0">{{label}}</h4>
  <div>
    <span style="margin-right: 10px">AKTIV</span>
    <mat-slide-toggle
      [checked]="user.deleted === null || user.deleted === '0'"
      (click)="disableUser()"></mat-slide-toggle>
  </div>
</div>

<div mat-dialog-content style="margin-top: 16px">
  <div class="flex-row">
    <div>E-Mail:</div>
    <div>{{user.email}}</div>
  </div>
  <div class="flex-row">
    <div>Telefon:</div>
    <div>{{user.phone}}</div>
  </div>
  <div class="flex-row">
    <div>Erstellungsdatum:</div>
    <div>{{user.entryCreated| date:'dd.MM.yyyy'}}</div>
  </div>
  <div class="flex-row">
    <div>Tägliche Zusammenfassung:</div>
    <mat-slide-toggle
      [checked]="this.mailSummaryEnabled !== undefined && this.mailSummaryEnabled.settingValue === '1'"
      (click)="toggleMailSummaryEnabled($event)"
    ></mat-slide-toggle>
  </div>
  <div class="flex-row" style="margin-top: 10px; margin-bottom: 10px; font-style: italic; color: gray">
    Wenn du die Option "Über neue Belege via PUSH Benachrichtigen" oder "Über neue Belege via E-Mail benachrichtigen"
    aktivierst, bekommst du jedes Mal dann eine Benachrichtigung, wenn ein Beleg in eine Abteilung kommt, in der du das
    Recht "Alle Belege sehen" hast. Wechselt ein Beleg die Abteilung und du hast in der neuen Abteilung auch das Recht
    "Alle Belege sehen", bekommst du wieder eine Benachrichtigung.
  </div>
  <div class="flex-row">
    <div>Über neue Belege via PUSH benachrichtigen:</div>
    <mat-slide-toggle
      [checked]="this.pushForNewInvoiceActive !== undefined && this.pushForNewInvoiceActive.settingValue === '1'"
      (click)="togglePushForNewInvoiceActive($event)"
    ></mat-slide-toggle>
  </div>
  <div class="flex-row">
    <div>Über neue Belege via E-Mail benachrichtigen:</div>
    <mat-slide-toggle
      [checked]="this.mailForNewInvoiceActive !== undefined && this.mailForNewInvoiceActive.settingValue === '1'"
      (click)="toggleMailForNewInvoiceActive($event)"
    ></mat-slide-toggle>
  </div>

  <mat-tab-group>
    <mat-tab label="App">
      <table mat-table [dataSource]="canSee">

        <!-- ID Column -->
        <ng-container matColumnDef="settingName">
          <th mat-header-cell *matHeaderCellDef> Name</th>
          <td mat-cell *matCellDef="let element"> {{element.settingName | translate:'userRights'}} </td>
        </ng-container>

        <!-- Bearbeiten Column -->
        <ng-container matColumnDef="settingValue">
          <th mat-header-cell *matHeaderCellDef> Sichtbarkeit</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [checked]="element.settingValue == 1"
              (click)="editSingleUserRight($event, element)"
            ></mat-slide-toggle>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-tab>
    <mat-tab *ngIf="hasInvoiceRight" label="Abteilungen" class="table-container">
      <div class="flex-end" style="margin: 5px 0;" *ngIf="availableDepartments && availableDepartments.length > 0">
        <button mat-raised-button color="primary" (click)="openAddDepartmentModal()">
          <mat-icon mat-list-icon class="ion ion-md-add-circle" color="light"></mat-icon>
          Abteilung hinzufügen
        </button>
      </div>

      <div class="empty-array" *ngIf="!userDepartments">
        <p>
          Keinen Abteilungen zugeordnet
        </p>
      </div>
      <table mat-table class="width-100" *ngIf="userDepartments" [dataSource]="userDepartments">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- MailPattern Column -->
        <ng-container matColumnDef="mailPattern">
          <th mat-header-cell *matHeaderCellDef>Anzahl Mails</th>
          <td mat-cell
              *matCellDef="let element"> {{element.departmentsMailPattern ? element.departmentsMailPattern.length : 0}} </td>
        </ng-container>

        <!-- CANREAD Column -->
        <ng-container matColumnDef="canRead">
          <th mat-header-cell *matHeaderCellDef>Alle Belege</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [checked]="element.userDepartmentsRights && element.userDepartmentsRights.canReadDepartmentsFiles == 1"
              (click)="toggleApproving($event, 'canRead', element)"
            ></mat-slide-toggle>
          </td>
        </ng-container>

        <!-- CONTENTWISE Column -->
        <ng-container matColumnDef="contentwise">
          <th mat-header-cell *matHeaderCellDef> Inhaltliche Freigabe</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [checked]="element.userDepartmentsRights && element.userDepartmentsRights.canApproveContentWise == 1"
              (click)="toggleApproving($event, 'contentwise', element)"
            ></mat-slide-toggle>
          </td>
        </ng-container>

        <!-- FINANCEWISE Column -->
        <ng-container matColumnDef="financewise">
          <th mat-header-cell *matHeaderCellDef> Zahlungsfreigabe</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [checked]="element.userDepartmentsRights && element.userDepartmentsRights.canApproveFinanceWise == 1"
              (click)="toggleApproving($event, 'financewise', element)"
            ></mat-slide-toggle>
          </td>
        </ng-container>

        <!-- MOVEDEPARTMENTSFILE Column -->
        <ng-container matColumnDef="canMove">
          <th mat-header-cell *matHeaderCellDef> Abteilung ändern</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [checked]="element.userDepartmentsRights && element.userDepartmentsRights.canMoveDepartmentsFiles == 1"
              (click)="toggleApproving($event, 'canMove', element)"
            ></mat-slide-toggle>
          </td>
        </ng-container>

        <!-- DELETEDEPARTMENTSFILE Column -->
        <ng-container matColumnDef="canDelete">
          <th mat-header-cell *matHeaderCellDef> Belege löschen</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [checked]="element.userDepartmentsRights && element.userDepartmentsRights.canDeleteDepartmentsFiles == 1"
              (click)="toggleApproving($event, 'canDelete', element)"
            ></mat-slide-toggle>
          </td>
        </ng-container>

        <!-- DELETEDEPARTMENTSFILE Column -->
        <ng-container matColumnDef="deleteDepartment">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button>
              <mat-icon
                mat-list-icon
                color="warn"
                (click)="deleteDepartmentFromUser(element)">delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsDepartment"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsDepartment;"></tr>
      </table>
    </mat-tab>
  </mat-tab-group>

</div>

<div mat-dialog-actions class="flex-end">
  <button mat-raised-button [mat-dialog-close]="" (click)="postCanSeeOptions()">Schließen</button>
</div>
