import {Component, Inject, OnInit} from '@angular/core';

import * as _ from 'lodash';

import {IAddress} from '@interfaces/iaddress';

import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-edit-address',
  templateUrl: './client-edit-address.component.html',
  styleUrls: ['./client-edit-address.component.scss']
})
export class ClientEditAddressComponent implements OnInit {
  public address: IAddress;
  public newAddress: IAddress;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ClientEditAddressComponent>,
    private requestManager: RequestManagerService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {
    if (data.address) {
      this.address = data.address;
      this.newAddress = _.clone(this.address);
    } else {
      this.newAddress = {
        country: undefined,
        postalCode: undefined,
        description: undefined,
        city: undefined,
        street: undefined,
        doorNumber: undefined,
        clientId: data.clientId
      };
    }
  }

  ngOnInit(): void {
  }

  async saveAddress() {
    if (this.address) {
      const putBody = {
        country: this.newAddress.country,
        postalCode: this.newAddress.postalCode,
        city: this.newAddress.city,
        street: this.newAddress.street,
        doorNumber: this.newAddress.doorNumber,
        clientId: this.newAddress.clientId
      };

      await this.requestManager.put('/clientAddresses/' + this.address.id, putBody);
      this.snackBar.open('Änderungen erfolgreich gespeichert!')._dismissAfter(3000);
      this.dialog.closeAll();
    } else {
      await this.requestManager.post('/clientAddresses', this.newAddress);
      this.snackBar.open('Adresse erfolgreich erstellt!')._dismissAfter(3000);
      this.dialog.closeAll();
    }
  }

  deleteAddress() {
    this.requestManager.delete('/clientAddresses/' + this.address.id);
    this.snackBar.open('Adresse erfolgreich gelöscht!')._dismissAfter(3000);
    this.dialog.closeAll();
  }
}
