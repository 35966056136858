<div class="div-content" *ngIf="user$ | async">
  <mat-grid-list cols="2" rowHeight="350">
    <!-- Information -->
    <mat-grid-tile>
      <div class="flex margin-bottom">
        <mat-card-title>{{(user$ | async).name}} {{(user$ | async).surname}}</mat-card-title>
        <div style="padding-right: 20px">
          <span style="margin-right: 10px">AKTIV</span>
          <mat-slide-toggle
            [checked]="(user$ | async).deleted === null || (user$ | async).deleted === '0'"
            (click)="disableUser()"></mat-slide-toggle>
        </div>
      </div>
      <div class="padding-20">
        <div class="flex margin-bottom">
          <div>E-Mail:</div>
          <a mat-button color="primary" class="no-padding-button"
             [href]="'mailto:' + (user$ | async).email">{{(user$ | async).email}}</a>
        </div>
        <div class="flex margin-bottom">
          <div>Telefon:</div>
          <div>{{(user$ | async).phone ? (user$ | async).phone : '- - -'}}</div>
        </div>
        <div class="flex margin-bottom">
          <div>Erstellungsdatum:</div>
          <div>{{(user$ | async).entryCreated | date:'dd.MM.yyyy'}}</div>
        </div>
        <div class="flex margin-bottom">
          <div></div>
          <button mat-raised-button (click)="deleteUser()" color="warn">LÖSCHEN</button>
        </div>
      </div>
    </mat-grid-tile>
    <!-- Image -->
    <mat-grid-tile>
      <div class="flex">
        <div></div>
        <div class="user-img">
          <img class="avatar-img"
               [src]="(user$ | async).avatar
             ? domSanatizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'+(user$ | async).avatar)
             : '../../../../assets/img/person-circle-outline.svg'"
               alt="user_avatar_image">
          <button (click)="openEditAvatarDialog()" class="badge-icon badge-icon-visibility" mat-fab color="primary">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list *ngIf="user$ | async" cols="2" rowHeight="580px" gutterSize="20px">
    <mat-grid-tile class="card" colspan="1" rowspan="1">
      <app-user-single-clients [userClients]="(user$ | async).userClient"
                               [user]="user$ | async"
                               [clients]="clients"
                               [hasInvoiceRight]="hasInvoiceRight"></app-user-single-clients>
    </mat-grid-tile>

    <mat-grid-tile class="card" colspan="1" rowspan="1">
      <app-user-single-devices [userDevices]="(user$ | async).userDevices"></app-user-single-devices>
    </mat-grid-tile>

    <!--    <mat-grid-tile class="card" colspan="1" rowspan="1">-->
    <!--      <app-user-single-departments [userDepartments]="(user$ | async).userDepartments"-->
    <!--                                   [departments]="departments"></app-user-single-departments>-->
    <!--    </mat-grid-tile>-->

  </mat-grid-list>
</div>
