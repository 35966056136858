<mat-card>
  <mat-card-title class="width-100 card-title-flex">
    <div>Adressen</div>
    <button mat-icon-button (click)="editAddressDialog(undefined)">
      <mat-icon mat-list-icon class="ion ion-md-add-circle-outline" color="primary"></mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content *ngIf="addresses">
    <table mat-table class="width-100" [dataSource]="addresses">

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <!-- Land Column -->
      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef> Land</th>
        <td mat-cell *matCellDef="let element"> {{element.country | countryCodeToCountry}} </td>
      </ng-container>

      <!-- Stadt Column -->
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef> Stadt</th>
        <td mat-cell *matCellDef="let element"> {{element.postalCode ? element.postalCode  + ', ' : ''}} {{element.city}} </td>
      </ng-container>

      <!-- Straße Column -->
      <ng-container matColumnDef="street">
        <th mat-header-cell *matHeaderCellDef> Straße</th>
        <td mat-cell *matCellDef="let element"> {{element.street}} {{element.doorNumber}}</td>
      </ng-container>

      <!-- Erstellungsdatum Column -->
      <ng-container matColumnDef="entryCreated">
        <th mat-header-cell *matHeaderCellDef> Erstellungsdatum</th>
        <td mat-cell *matCellDef="let element"> {{element.entryCreated | date:'dd.MM.yyyy'}} </td>
      </ng-container>

      <!-- Bearbeiten Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="editAddressDialog(element)">
            <mat-icon mat-list-icon class="ion ion-md-create" color="primary"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsAddress"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsAddress;"></tr>
    </table>
  </mat-card-content>
  <mat-card-content *ngIf="!addresses">
    <div class="empty-array">
      <p>
        Keine Adressen vorhanden
      </p>
    </div>
  </mat-card-content>
</mat-card>
