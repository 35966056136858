import {Component, Input, OnInit} from '@angular/core';

import {IDepartment} from '@interfaces/idepartment';

import {UserService} from '@services/user/user.service';
import {UserDeleteDepartmentDialogComponent} from '../../user-delete-department-dialog/user-delete-department-dialog.component';
import {UserAddDepartmentDialogComponent} from '../../user-add-department-dialog/user-add-department-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';


@Component({
  selector: 'app-user-single-departments',
  templateUrl: './user-single-departments.component.html',
  styleUrls: ['./user-single-departments.component.scss']
})
export class UserSingleDepartmentsComponent implements OnInit {
  @Input() userDepartments: IDepartment[];
  @Input() departments: IDepartment[];
  public displayedColumnsDepartment: string[] = ['name', 'entryCreated', 'edit'];

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  /**
   * Init dialog configurations and data, then open delete-department-dialog
   * @param row department-object that should be deleted
   */
  deleteDepartmentDialog(row: any) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.autoFocus = true;
      dialogConfig.minWidth = '600px';

      dialogConfig.data = row;

      this.dialog.open(UserDeleteDepartmentDialogComponent, dialogConfig);
   }

  /**
   * Init dialog configurations and data, then open add-department-dialog
   */
  addDepartmentDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';

    const user = this.userService.getUserSingleInformation();

    // TODO: CHANGE GET ALL DEPARTMENTS ROUTE AND FILTER DEPARTMENTS
    dialogConfig.data = this.departments['departments'];

    this.dialog.open(UserAddDepartmentDialogComponent, dialogConfig);
  }
}
