import { Component, Inject, OnInit } from '@angular/core';
import { RequestManagerService } from '@services/request-manager/request-manager.service';
import { IUser } from '@interfaces/iuser';
import { IUserRights } from '@interfaces/iuser-rights';
import { IDepartment } from '@interfaces/idepartment';
import * as _ from 'lodash';

import { ClientAddUserComponent } from '../client-add-user/client-add-user.component';
// tslint:disable-next-line:max-line-length
import {UserAddDepartmentDialogComponent} from '../../../users/components/user-add-department-dialog/user-add-department-dialog.component';
import {IClient} from '@interfaces/iclient';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserState} from '../../../users/store/user.reducer';
import {Store} from '@ngrx/store';
import {updateUser} from '../../../users/store/user.actions';
import {Update} from '@ngrx/entity';

@Component({
  selector: 'app-client-edit-user-settings-dialog',
  templateUrl: './client-edit-user-settings-dialog.component.html',
  styleUrls: ['./client-edit-user-settings-dialog.component.scss'],
})
export class ClientEditUserSettingsDialogComponent implements OnInit {
  public user: IUser;
  public userRightsForClient: IUserRights[];
  public userDepartmentsRights: any[];
  public canSee: IUserRights[];
  public mailSummaryEnabled: IUserRights;
  public pushForNewInvoiceActive: IUserRights;
  public mailForNewInvoiceActive: IUserRights;
  public hasInvoiceRight: boolean;

  public displayedColumns: string[] = ['settingName', 'settingValue'];
  public displayedColumnsDepartment: string[] = [
    'name',
    'mailPattern',
    'canRead',
    'contentwise',
    'financewise',
    'canMove',
    'canDelete',
    'deleteDepartment',
  ];

  public userDepartments: IDepartment[];
  public allDepartments: IDepartment[];
  public availableDepartments: IDepartment[];
  private postMode = false;
  private client: IClient;
  private inputUserDepartments: IDepartment[];
  label = '';

  constructor(
    private requestManager: RequestManagerService,
    private dialogRef: MatDialogRef<ClientEditUserSettingsDialogComponent>,
    private dialogRefAddUser: MatDialogRef<ClientAddUserComponent>,
    private dialogRefAddDepartment: MatDialogRef<UserAddDepartmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private store: Store<UserState>
  ) {
    this.user = data.user;
    this.client = data.client;
    this.hasInvoiceRight = data.hasInvoiceRight;

    if (data.calledFromUser) {
      this.label = this.client.name;
    } else {
      this.label = this.user.name + ' ' + this.user.surname;
    }
    this.userRightsForClient = data.userRightsForClient;
    this.userDepartmentsRights = data.userDepartmentsRights;
    this.userDepartments = data.userDepartments;
    this.inputUserDepartments = data.userDepartments;

    if (this.userRightsForClient) {
      this.mailSummaryEnabled = this.userRightsForClient.find(
        (ur) => ur.settingName === 'mailSummaryEnabled'
      );
      this.pushForNewInvoiceActive = this.userRightsForClient.find(
        (ur) => ur.settingName === 'pushForNewInvoiceActive'
      );
      this.mailForNewInvoiceActive = this.userRightsForClient.find(
        (ur) => ur.settingName === 'mailForNewInvoiceActive'
      );
      this.canSee = this.userRightsForClient.filter((ur) =>
        ur.settingName.includes('canSee')
      );
    } else {
      this.postMode = true;
      const clientId = this.requestManager.getClientId();
      this.canSee = [
        {
          settingName: 'canSeeOPOS',
          settingValue: '0',
          userId: this.user.id,
          clientId,
        },
        {
          settingName: 'canSeeAccounting',
          settingValue: '0',
          userId: this.user.id,
          clientId,
        },
        {
          settingName: 'canSeeVoucherScan',
          settingValue: '0',
          userId: this.user.id,
          clientId,
        },
        {
          settingName: 'canSeeVoucherApproval',
          settingValue: '0',
          userId: this.user.id,
          clientId,
        },
        {
          settingName: 'canSeeCompany',
          settingValue: '0',
          userId: this.user.id,
          clientId,
        },
        {
          settingName: 'canSeeDMS',
          settingValue: '0',
          userId: this.user.id,
          clientId,
        },
        {
          settingName: 'canSeePaymentTransactions',
          settingValue: '0',
          userId: this.user.id,
          clientId
        },
      ];
    }

    this.userDepartments = data.userDepartments;
    this.allDepartments = data.allDepartments;
    this.getAvailableDepartments();
    this.initializeUserDepartmentsRights();
  }

  ngOnInit() {}

  private async actualizeDepartmentsAndRights() {
    const data = await this.requestManager.get(
      '/adminArea/user/' + this.user.id + '/client',
      { array_values: true }
    );
    this.user = data['user'][0];

    if (this.user.userDepartments) {
      const userDepartmentsId = this.user.userDepartments.map(
        (el) => el.departmentsId
      );
      this.userDepartments = this.allDepartments.filter((dep) =>
        userDepartmentsId.includes(dep.id)
      );
      const departments = await this.requestManager.get(
        '/adminArea/user/' +
          this.user.id +
          '/client/' +
          this.client.id +
          '/departments',
        { array_values: true }
      );

      if (departments.length > 0) {
        this.userDepartments = departments.user[0].userDepartments.map(
          (el) => el.departments[0]
        );
      }
      this.userDepartmentsRights = departments.user[0].userDepartmentsRights;
    }
    this.getAvailableDepartments();
    await this.initializeUserDepartmentsRights();
  }

  private async initializeUserDepartmentsRights() {
    const departments = await this.requestManager.get(
      '/adminArea/user/' +
        this.user.id +
        '/client/' +
        this.client.id +
        '/departments',
      { array_values: true }
    );
    if (departments.user !== undefined && departments.user[0]?.userDepartments) {
      this.userDepartments = departments.user[0].userDepartments.map((el) => {
        return { ...el.departments[0], tableId: el.id };
      });
    } else {
      this.userDepartments = [];
    }

    if (this.userDepartments) {
      this.userDepartments.forEach((dep) => {
        if (this.userDepartmentsRights) {
          dep['userDepartmentsRights'] = this.userDepartmentsRights.find(
            (el) => dep.id === el.departmentsId
          );
        } else {
          dep['userDepartmentsRights'] = {
            canReadDepartmentsFiles: '0',
            canApproveContentWise: '0',
            canApproveFinanceWise: '0',
            canDeleteDepartmentsFiles: '0',
            canMoveDepartmentsFiles: '0',
            departmentsId: dep.id,
            userId: this.user.id,
          };
        }
      });
    }
  }

  private getAvailableDepartments() {
    if (this.userDepartments !== undefined && this.userDepartments.length !== 0) {
      this.availableDepartments = this.allDepartments.filter(
        (ad) => this.userDepartments.find((ud) => ud.id === ad.id) === undefined
      );
    } else {
      this.availableDepartments = _.cloneDeep(this.allDepartments);
    }
  }

  async editSingleUserRight($event, element: any) {
    $event.preventDefault();
    element.settingValue = element.settingValue === '1' ? '0' : '1';
    if (!this.postMode) {
      await this.requestManager.put('/userRightsForClient/' + element.id, {
        settingValue: element.settingValue,
      });
    }
  }

  async toggleApproving($event, type: string, element: any) {
    $event.preventDefault();
    let body;
    switch (type) {
      case 'contentwise':
        element['userDepartmentsRights']['canApproveContentWise'] =
          element['userDepartmentsRights']['canApproveContentWise'] === '1'
            ? '0'
            : '1';
        body = {
          canApproveContentWise:
            element['userDepartmentsRights']['canApproveContentWise'],
        };
        break;
      case 'financewise':
        element['userDepartmentsRights']['canApproveFinanceWise'] =
          element['userDepartmentsRights']['canApproveFinanceWise'] === '1'
            ? '0'
            : '1';
        body = {
          canApproveFinanceWise:
            element['userDepartmentsRights']['canApproveFinanceWise'],
        };
        break;
      case 'canMove':
        element['userDepartmentsRights']['canMoveDepartmentsFiles'] =
          element['userDepartmentsRights']['canMoveDepartmentsFiles'] === '1'
            ? '0'
            : '1';
        body = {
          canMoveDepartmentsFiles:
            element['userDepartmentsRights']['canMoveDepartmentsFiles'],
        };
        break;
      case 'canDelete':
        element['userDepartmentsRights']['canDeleteDepartmentsFiles'] =
          element['userDepartmentsRights']['canDeleteDepartmentsFiles'] === '1'
            ? '0'
            : '1';
        body = {
          canDeleteDepartmentsFiles:
            element['userDepartmentsRights']['canDeleteDepartmentsFiles'],
        };
        break;
      case 'canRead':
        if (
          element.userDepartmentsRights.canApproveFinanceWise === '1' ||
          element.userDepartmentsRights.canApproveContentWise === '1' ||
          element.userDepartmentsRights.canDeleteDepartmentsFiles === '1'
        ) {
          this.snackBar.open(
            'Deaktivieren sie zuerst alle Freigabeoptionen',
            'Okay',
            { duration: 2000 }
          );
        } else {
          element['userDepartmentsRights']['canReadDepartmentsFiles'] =
            element['userDepartmentsRights']['canReadDepartmentsFiles'] === '1'
              ? '0'
              : '1';
          body = {
            canReadDepartmentsFiles:
              element['userDepartmentsRights']['canReadDepartmentsFiles'],
          };
        }
        break;
    }

    if (
      body.canApproveFinanceWise === '1' ||
      body.canApproveContentWise === '1' ||
      body.canDeleteDepartmentsFiles === '1'
    ) {
      element['userDepartmentsRights']['canReadDepartmentsFiles'] = '1';
      body.canReadDepartmentsFiles = '1';
    }

    await this.requestManager.put(
      '/userDepartmentsRights/' + element['userDepartmentsRights']['id'],
      body
    );
  }

  public openAddDepartmentModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '600px';

    dialogConfig.data = {
      availableDepartments: this.availableDepartments,
      user: this.user,
    };

    const d = this.dialog.open(UserAddDepartmentDialogComponent, dialogConfig);
    d.afterClosed().subscribe(async () => {
      await this.actualizeDepartmentsAndRights();
    });
  }

  async disableUser() {
    this.user.deleted = this.user.deleted === '1' ? '0' : '1';
    const id = this.user.id;
    const update: Update<IUser> = {
      id,
      changes: {deleted: this.user.deleted}
    };
    this.store.dispatch(updateUser({user: update}));
    this.dialog.closeAll();
  }

  async toggleMailSummaryEnabled($event) {
    $event.preventDefault();
    if (this.mailSummaryEnabled) {
      this.mailSummaryEnabled.settingValue =
        this.mailSummaryEnabled.settingValue === '1' ? '0' : '1';
      await this.requestManager.put(
        '/userRightsForClient/' + this.mailSummaryEnabled.id,
        { settingValue: this.mailSummaryEnabled.settingValue }
      );
    } else {
      this.mailSummaryEnabled = {
        settingName: 'mailSummaryEnabled',
        settingValue: '1',
        clientId: this.requestManager.getClientId(),
        userId: this.user.id,
      };
      this.mailSummaryEnabled.id = await this.requestManager.post(
        '/userRightsForClient',
        this.mailSummaryEnabled
      );
    }
  }

  async togglePushForNewInvoiceActive($event) {
    $event.preventDefault();
    if (this.pushForNewInvoiceActive) {
      this.pushForNewInvoiceActive.settingValue =
        this.pushForNewInvoiceActive.settingValue === '1' ? '0' : '1';
      await this.requestManager.put(
        '/userRightsForClient/' + this.pushForNewInvoiceActive.id,
        {settingValue: this.pushForNewInvoiceActive.settingValue}
      );
    } else {
      this.pushForNewInvoiceActive = {
        settingName: 'pushForNewInvoiceActive',
        settingValue: '1',
        clientId: this.requestManager.getClientId(),
        userId: this.user.id,
      };
      this.pushForNewInvoiceActive.id = await this.requestManager.post(
        '/userRightsForClient',
        this.pushForNewInvoiceActive
      );
    }
  }

  async toggleMailForNewInvoiceActive($event) {
    $event.preventDefault();
    if (this.mailForNewInvoiceActive) {
      this.mailForNewInvoiceActive.settingValue =
        this.mailForNewInvoiceActive.settingValue === '1' ? '0' : '1';
      await this.requestManager.put(
        '/userRightsForClient/' + this.mailForNewInvoiceActive.id,
        {settingValue: this.mailForNewInvoiceActive.settingValue}
      );
    } else {
      this.mailForNewInvoiceActive = {
        settingName: 'mailForNewInvoiceActive',
        settingValue: '1',
        clientId: this.requestManager.getClientId(),
        userId: this.user.id,
      };
      this.mailForNewInvoiceActive.id = await this.requestManager.post(
        '/userRightsForClient',
        this.mailForNewInvoiceActive
      );
    }
  }

  async postCanSeeOptions() {
    if (this.postMode) {
      const promises = [];
      this.canSee.forEach((canSee) => {
        promises.push(this.requestManager.post('/userRightsForClient', canSee));
      });
      await Promise.all(promises);
      this.dialogRefAddUser.close();
    }
  }

  async deleteDepartmentFromUser(element: any) {
    await this.requestManager.delete('/userDepartments/' + element.tableId);
    const inputDepartment = this.inputUserDepartments.find(
      (el) => el.id === element.tableId
    );
    this.inputUserDepartments = this.inputUserDepartments.splice(
      this.inputUserDepartments.indexOf(inputDepartment),
      1
    );

    if (element.userDepartmentsRights) {
      await this.requestManager.delete(
        '/userDepartmentsRights/' + element.userDepartmentsRights.id
      );
    }
    await this.actualizeDepartmentsAndRights();
  }
}
