import {Component, Inject, OnInit} from '@angular/core';
import {IUser} from '@interfaces/iuser';
import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {UserService} from '@services/user/user.service';
import {IClient} from '@interfaces/iclient';
import {IDepartment} from '@interfaces/idepartment';
import {ClientEditUserSettingsDialogComponent} from '../client-edit-user-settings-dialog/client-edit-user-settings-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-client-add-user',
  templateUrl: './client-add-user.component.html',
  styleUrls: ['./client-add-user.component.scss']
})
export class ClientAddUserComponent implements OnInit {
  private user: IUser;
  private existingUsers: any;
  private client: IClient;
  public users: IUser[];
  public displayedColumnsUser: string[] = ['id', 'name', 'phone', 'entryCreated', 'edit'];
  public isLoading = true;
  private departments: IDepartment[];

  constructor(
    private dialogRef: MatDialogRef<ClientAddUserComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private userService: UserService,
    private dialog: MatDialog,
    private requestManager: RequestManagerService,
    private snackBar: MatSnackBar,
  ) {
    this.existingUsers = data.existingUsers;
    this.client = data.client;
    this.departments = data.departments;
  }

  async ngOnInit() {
    this.users = await this.requestManager.get('/adminArea/user');
    this.users = this.users['user'];
    if (this.users && this.existingUsers !== undefined) {
      this.users = this.users.filter(user => this.existingUsers.find(existingUser => user.id === existingUser.user[0].id) === undefined);
    }
    this.isLoading = false;
  }

  /**
   * Adds a user to a client via a POST request, and afterwards open a snackBar for 2 seconds
   * @param user the user that should be added to the actual client
   */
  async addUserToClient(user) {
    await this.requestManager.post('/userClient', {userId: user.id, clientId: this.client.id});

    // delete user from users array
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].id === user.id) {
        this.users.splice(i, 1);
      }
    }
    this.snackBar.open(`${user.name} ${user.surname} wurde hinzugefügt`)._dismissAfter(2000);
    this.openEditUserDialog(user);
  }

  async openEditUserDialog(user: IUser) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.minWidth = '850px';
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      client: this.client,
      user: user,
      allDepartments: this.departments,
    };

    this.dialog.open(ClientEditUserSettingsDialogComponent, dialogConfig)
      .afterClosed().subscribe(async () => {
      this.users = await this.requestManager.get('/adminArea/user');
      const client = await this.requestManager.get('/adminArea/client/' + this.client.id + '/user', {array_values: true});
      this.client = client['client'][0];
      this.existingUsers = this.client.userClient;
      this.users = this.users['user'];
      this.users = this.users.filter(u => this.existingUsers.find(existingUser => u.id === existingUser.user[0].id) === undefined);
    });
  }

}
