<div class="full-height" ngxUiLoaderBlurred>
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="side"
                 [opened]="tokenStorageService.isValid() && isInValidRoute()"
                 class="sidenav">
      <span class="side-nav-flex">
        <div>
          <mat-toolbar color="primary">
            <mat-toolbar-row style="display: flex;justify-content: center;">
              <img class="logo" src="../assets/img/logo_vector.svg" alt="...">
            </mat-toolbar-row>
          </mat-toolbar>
          <mat-nav-list class="nav-list">
            <ng-container *ngFor="let item of menu">
              <h2 *ngIf="item.type == 'divider'" matSubheader> {{item.name}} </h2>
              <mat-list-item *ngIf="item.type == 'item'" (click)="navigateTo(item.route)">
                <mat-icon mat-list-icon color="primary" [class]="'highlight icon-size ion ' + item.icon"></mat-icon>
                <h4 [class]="router.url === item.route ? 'highlight' : ''" mat-line>{{item.name}}</h4>
              </mat-list-item>
            </ng-container>
          </mat-nav-list>
        </div>
        <div class="version-flex">Taxflow Admin Version {{version}}</div>
      </span>
    </mat-sidenav>

    <mat-sidenav-content>
      <mat-toolbar color="primary" *ngIf="tokenStorageService.isValid() && isInValidRoute()">
        <div class="header-items">
          <div style="width: 100%;">
            <h1 style="text-align: center">{{pageName}}</h1>
          </div>
          <button mat-button (click)="tokenStorageService.signOutAndReload()">Logout</button>
        </div>
      </mat-toolbar>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<ngx-ui-loader></ngx-ui-loader>
