<mat-card-content>

  <div *ngIf="!sonstiges && !releaseInvoices && !evaluations && !dms">
      <span *ngIf="settingDoOcr" class='doocr-container'><div>OCR-Erkennung</div>
        <button mat-icon-button (click)="toggleSettingDoOcr(settingDoOcr)">
          <mat-icon mat-list-icon *ngIf="isSettingValueTrue(settingDoOcr)" class="ion ion-md-checkmark-circle"
                    color="primary"></mat-icon>
          <mat-icon mat-list-icon *ngIf="!isSettingValueTrue(settingDoOcr)" class="ion ion-md-close-circle"
                    color="warn"></mat-icon>
        </button>
      </span>
  </div>

  <!-- Evaluations -->
  <div *ngIf="evaluations">
    <div class="row">
      <div class="col-left">
        Standardwert
      </div>
    </div>

    <div class="row">
      <div class="col-left p-20">
        Erfolgsrechnung
      </div>
      <div class="col-right">
        <mat-form-field>
          <mat-select [(value)]="settingDefaultViewDataMonthAccountingAnalysis">
            <mat-option *ngFor="let singleValue of getDefaultListForSetting(settingsDefaultViewMonth[0])"
                        [value]="singleValue"
                        (click)="editDefaultViewDataMonthAccountAnalysis(singleValue)">
              {{singleValue | translate:'clientSettingsValue'}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-left p-20">
        Offene Posten
      </div>
      <div class="col-right">
        <mat-form-field>
          <mat-select [(value)]="settingDefaultViewDataMonthOPOS">
            <mat-option *ngFor="let singleValue of getDefaultListForSetting(settingsDefaultViewMonth[1])"
                        [value]="singleValue"
                        (click)="editDefaultViewDataMonthOPOS(singleValue)">
              {{singleValue | translate:'clientSettingsValue'}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-left">
        Live-Ansicht
      </div>
    </div>

    <div class="row">
      <div class="col-left-toggle p-20">
        Erfolgsrechnung
      </div>
      <div class="col-right-toggle" style="line-height: 50px">
        <mat-slide-toggle
          [checked]="settingsCanSee[0].settingValue === 'true'"
          (click)="toggleSetting(settingsCanSee[0])"></mat-slide-toggle>
      </div>
    </div>

    <div class="row">
      <div class="col-left-toggle p-20">
        Offene Posten
      </div>
      <div class="col-right-toggle" style="line-height: 50px">
        <mat-slide-toggle
          [checked]="settingsCanSee[1].settingValue === 'true'"
          (click)="toggleSetting(settingsCanSee[1])"></mat-slide-toggle>
      </div>
    </div>
  </div>

  <!-- Release Invoices -->
  <div *ngIf="releaseInvoices">
    <div *ngIf="active">
      <div class="row">
        <div class="col-left">
          DATEV Upload
        </div>
      </div>
      <div class="row">
        <div class="col-left p-20">
          Hochladen nach
        </div>
        <div class="col-right">
          <mat-form-field>
            <mat-select [(value)]="settinguploadToDATEVAfterStepValue">
              <mat-option *ngFor="let singleValue of ['0', '1','2']"
                          [value]="singleValue"
                          (click)="editDefaultViewValue(singleValue)">
                {{singleValue | translate:'uploadToDATEVAfterStep'}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="p-20" style="display: flex; align-items: center; text-align: left; width: 50%;">
          Belege zu DATEV DMS/DokAb hochladen (statt Unternehmen Online)
        </div>
        <div class="col-right-toggle" style="line-height: 50px">
          <mat-slide-toggle
            [checked]="settingUploadToDMSInsteadDUO?.settingValue === 'true' || settingUploadToDMSInsteadDUO?.settingValue === '1'"
            (click)="toggleSetting(settingUploadToDMSInsteadDUO)"></mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="settingUploadToDMSInsteadDUO?.settingValue === '1' || registerIsNull" class="row">
        <div class="col-left p-20">Register für Belege</div>
        <div class="col-right">
          <mat-form-field>
            <mat-select [(value)]="selectedRegisterValue">
              <mat-option *ngFor="let singleValue of dmsRegisters"
                          [value]="singleValue.name + ' ' + singleValue.path"
                          (click)="selectRegister(singleValue)"
                          style="height: 6em !important;">
                {{singleValue.name}}<br>&nbsp;&nbsp;&nbsp;&nbsp;{{singleValue.path}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="p-20" style="display: flex; align-items: center; text-align: left; width: 50%;">
          Buchungsvorschläge in DATEV erstellen
        </div>
        <div class="col-right-toggle" style="line-height: 50px">
          <mat-slide-toggle
            [checked]="settingCreatePostingProposals?.settingValue === 'true' || settingCreatePostingProposals?.settingValue === '1'"
            (click)="toggleSetting(settingCreatePostingProposals)"></mat-slide-toggle>
        </div>
      </div>
      <div class="row">
        <div class="col-left">
          Automatische Antwort
        </div>
      </div>
      <div class="row">
        <div class="p-20" style="display: flex; align-items: center; text-align: left; width: 50%;">
          Sende eine automatische Antwort auf eingehende E-Mails an den Absender
        </div>
        <div class="col-right-toggle" style="line-height: 50px">
          <mat-slide-toggle
            [checked]="settingAutomaticEmailReply?.settingValue === 'true' || settingAutomaticEmailReply?.settingValue === '1'"
            (click)="toggleSetting(settingAutomaticEmailReply)"></mat-slide-toggle>
        </div>
      </div>
      <div
        *ngIf="settingAutomaticEmailReply?.settingValue === 'true' || settingAutomaticEmailReply?.settingValue === '1'"
        class="row" style="padding-top: 10px; height: fit-content">
        <div class="p-20" style="width: 100%">
          <input matInput
                 type="text"
                 [(ngModel)]="settingAutomaticEmailReplySubject.settingValue"
                 style="border: 1px solid #dddddd; padding: 8px 12px; box-sizing: border-box"
                 placeholder="Betreff eingeben ...">
          <div style="padding-top: 5px">
            <angular-editor [(ngModel)]="settingAutomaticEmailReplyText.settingValue" name="ngEditor"
                            [config]="config" style="margin-top: 10px"></angular-editor>
          </div>
          <div style="text-align: right; padding-top: 5px">
            <button
              mat-raised-button color="primary"
              (click)="onSaveEmailReplyText()">
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!active">
      <div class="row">
        <div class="col-left">
          DATEV Upload
        </div>
      </div>
      <div class="row">
        <div class="col-left p-20">
          Hochladen nach
        </div>
        <div class="col-right" style="line-height: 50px">
          Direkt
          <button mat-icon-button (click)="openInfoModal()">
            <mat-icon mat-list-icon class="ion ion-md-information-circle" color="primary"></mat-icon>
          </button>
        </div>
      </div>
      <!--      <div class="row">-->
      <!--        <div class="col-left">-->
      <!--          DATEV Upload-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="row">-->
      <!--        <div class="col-left p-20">-->
      <!--          Hochladen nach-->
      <!--        </div>-->
      <!--        <div class="col-right">-->
      <!--          <mat-form-field>-->
      <!--            <mat-select [(value)]="settinguploadToDATEVAfterStepValue">-->
      <!--              <mat-option *ngFor="let singleValue of ['0']"-->
      <!--                          [value]="singleValue"-->
      <!--                          (click)="editDefaultViewValue(singleValue)">-->
      <!--                {{singleValue | translate:'uploadToDATEVAfterStep'}}-->
      <!--              </mat-option>-->
      <!--            </mat-select>-->
      <!--          </mat-form-field>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>

  <!-- Sonstiges -->
  <div *ngIf="sonstiges">
    <div class="row">
      <div class="col-left">
        Synchronisations Zyklus
      </div>
      <div class="col-right">
        <mat-form-field>
          <mat-select [(value)]="settingSynchronisationPeriodValue">
            <mat-option *ngFor="let singleValue of settingsSynchronisationValues"
                        [value]="singleValue"
                        (click)="editSettingSynchronisationPeriodValue(singleValue)">
              {{singleValue | translate:'synchronisationPeriod'}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-left">
        Buchhaltungskontakt
      </div>
      <div class="col-right lh-50">
        <div *ngIf="settingsAccountingContact && settingsAccountingContact.length > 0">
          {{settingsAccountingContact[0].settingValue | toUser:users:'id':'name'}}
          <button mat-icon-button (click)="editAccountingContact(settingsAccountingContact)">
            <mat-icon mat-list-icon class="ion ion-md-create" color="primary"></mat-icon>
          </button>
        </div>
        <div *ngIf="settingsAccountingContact && settingsAccountingContact.length < 1">
          Kontakt hinzufügen
          <button mat-icon-button (click)="editAccountingContact(undefined)">
            <mat-icon mat-list-icon class="ion ion-md-create" color="primary"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- DMS -->
  <div *ngIf="dms">
    <div class="row">
      <div class="col-left">
        Neue Dokumente
      </div>
    </div>
    <div class="row">
      <div class="col-left p-20">
        Speichern mit Status:
      </div>
      <div class="col-right">
        <mat-form-field>
          <mat-select [(value)]="selectedStateValue">
            <mat-option *ngFor="let singleValue of documentStatesDescription"
                        [value]="singleValue"
                        (click)="selectState(singleValue)">
              {{singleValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

</mat-card-content>
