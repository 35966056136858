<div style="display: flex; justify-content: center; ">
  <div style="max-width: 75%">
    <h1>Mit meiner Smartcard verbundene Anwendungen</h1>
    <div>
      <p>
        Hier können Sie erfahren, welche Anwendungen eine Freigabe mit Ihrer Smartcard (Smart Login, mIdentity) erhalten
        haben und diese Berechtigungen ggf. auch widerrufen
      </p>
      <button mat-raised-button color="primary" (click)="openDATEVPage()">Zu den verbundenen Anwendungen</button>
    </div>
  </div>
</div>
