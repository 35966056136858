<div mat-dialog-title class="modal-title">
  Abteilung hinzufügen
</div>
<hr class="hr-margin-bottom">

<div class="table-modal-container">

  <div *ngIf="!availableDepartments || availableDepartments.length <= 0" class="empty-array">
    <p>Es stehen keine weiteren Abteilungen zur Auswahl</p>
  </div>

  <table *ngIf="availableDepartments && availableDepartments.length > 0" mat-table class="width-100"
         [dataSource]="availableDepartments">

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell
          *matCellDef="let element"> {{element.name | slice:0:30}}{{element.name.length > 30 ? '...' : ''}} </td>
    </ng-container>

    <!-- Erstellungsdatum Column -->
    <ng-container matColumnDef="entryCreated">
      <th mat-header-cell *matHeaderCellDef> Erstellungsdatum</th>
      <td mat-cell *matCellDef="let element"> {{element.entryCreated | date:'dd.MM.yyyy'}} </td>
    </ng-container>

    <!-- Bearbeiten Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [disabled]="isAddingRightNow">
          <mat-icon mat-list-icon class="ion ion-md-add-circle" color="primary"
                    (click)="addDepartment(element)"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsDepartments; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsDepartments;"></tr>
  </table>
</div>

<div mat-dialog-actions class="flex-end">
  <button mat-raised-button color="success" [mat-dialog-close]="">Schließen</button>
</div>
