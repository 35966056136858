<mat-card>
  <mat-card-title class="width-100">
    <div>Geräte</div>
  </mat-card-title>
  <mat-card-content class="table-container">
    <mat-table *ngIf="userDevices" mat-table class="width-100 table-flex" [dataSource]="userDevices">

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="deviceDescription">
        <mat-header-cell *matHeaderCellDef> Beschreibung</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.deviceDescription}} </mat-cell>
      </ng-container>

      <!-- USt-Id. Column -->
      <ng-container matColumnDef="lastSeen">
        <mat-header-cell *matHeaderCellDef> Zuletzt gesehen</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.lastSeen | date:'dd.MM.yyyy'}} </mat-cell>
      </ng-container>

      <!-- Erstellungsdatum Column -->
      <ng-container matColumnDef="entryCreated">
        <mat-header-cell *matHeaderCellDef> Erstellungsdatum</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.entryCreated | date:'dd.MM.yyyy'}} </mat-cell>
      </ng-container>

      <!-- Bearbeiten Column -->
      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="deleteDeviceDialog(element)">
            <mat-icon mat-list-icon class="ion ion-md-close-circle" color="warn"></mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsDevices; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsDevices;"></mat-row>
    </mat-table>
  </mat-card-content>
  <div *ngIf="!userDevices" class="empty-array">
    <p>
      Keine Geräte vorhanden
    </p>
  </div>
</mat-card>

<ng-template let-data #dialogRef>
  Hello, {{data.name}}
</ng-template>
