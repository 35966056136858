import {Component, Inject, OnInit} from '@angular/core';
import {IClient, IClientWrapped} from '@interfaces/iclient';
import {IUserRights} from '@interfaces/iuser-rights';

import {RequestManagerService} from '@services/request-manager/request-manager.service';
import {UserService} from '@services/user/user.service';
import {IDepartment} from '@interfaces/idepartment';
import {IUser} from '@interfaces/iuser';

import * as _ from 'lodash';
import {UserAddDepartmentDialogComponent} from '../user-add-department-dialog/user-add-department-dialog.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-client-settings-dialog',
  templateUrl: './user-client-settings-dialog.component.html',
  styleUrls: ['./user-client-settings-dialog.component.scss'],
})
export class UserClientSettingsDialogComponent implements OnInit {
  displayedColumns: string[] = ['settingName', 'settingValue'];
  displayedColumnsDepartment: string[] = [
    'name',
    'canRead',
    'contentwise',
    'financewise',
    'canMove',
    'canDelete',
  ];

  data: { user: IUser; client: IClientWrapped; newClient: boolean };

  userClientRights: IUserRights[];
  userDepartments: IDepartment[];
  availableDepartments: IDepartment[];
  private allDepartments: IDepartment[];

  client: IClient;
  newClient: boolean;

  constructor(
    private requestManager: RequestManagerService,
    private dialogRef: MatDialogRef<UserClientSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.data = Object.assign({}, data);
    this.client = this.data.client.client[0];
  }

  public async ngOnInit() {
    const { user, client, newClient } = this.data;

    if (newClient) {
      this.userClientRights = [];
      this.allDepartments = (
        await this.requestManager.get(
          '/adminArea/client/' + client.client[0].id + '/departments'
        )
      ).departments;
      this.availableDepartments = this.getAvailableDepartments();
      this.initializeUserDepartmentsRights();
      return;
    }
    this.allDepartments = (
      await this.requestManager.get(
        '/adminArea/client/' + client.clientId + '/departments'
      )
    ).departments;
    this.userDepartments = Array.from(user.userDepartments);

    this.userClientRights = user.userRightsForClient.filter(
      (el) => el.clientId === client.clientId
    );
    this.userClientRights = this.userClientRights.map((item) =>
      Object.assign({}, item)
    );

    if (this.userClientRights == undefined) {
      this.userClientRights = [];
    }
    this.availableDepartments = this.getAvailableDepartments();
    this.initializeUserDepartmentsRights();
  }

  /**
   * Edit single setting of userRightsForClientTable, if client and settings already exist update instantly,
   * otherwise change settingValue for later post requests
   * @param setting value that will be changed
   */
  public async editSingleUserRight(setting: any) {
    const {newClient} = this.data;

    if (!newClient) {
      setting.settingValue = setting.settingValue === '1' ? '0' : '1';
      const body = { settingValue: setting.settingValue };
      await this.requestManager.put('/userRightsForClient/' + setting.id, body);
    } else {
      const actualSetting = this.userClientRights.find((el) => el === setting);
      actualSetting.settingValue = setting.settingValue === '1' ? '0' : '1';
    }
  }

  /**
   * Delete client and all depending userRightsForClient only possible, if client already exists
   */
  public async deleteClient() {
    const { client } = this.data;

    const userRightsPromises = [];
    this.userClientRights.forEach((el) =>
      userRightsPromises.push(
        this.requestManager.delete('/userRightsForClient/' + el.id)
      )
    );
    await Promise.all(userRightsPromises);
    await this.requestManager.delete('/userClient/' + client.id);
    this.dialogRef.close();
  }

  /**
   * Post userRightsForClient-settings to backend and close all dialogs
   */
  public async postUserClientSettings() {
    const promises = [];
    this.userClientRights.forEach((el) =>
      promises.push(this.requestManager.post('userRightsForClient', el))
    );
    await Promise.all(promises);
    this.dialog.closeAll();
  }

  public openAddDepartmentModal() {
    this.availableDepartments.filter(() => {});
    const data = {
      availableDepartments: this.availableDepartments,
      user: this.data.user,
    };

    const d = this.dialog.open(UserAddDepartmentDialogComponent, {
      autoFocus: true,
      minWidth: '600px',
      data,
    });
    d.afterClosed().subscribe(async () => {
      const userData = (
        await this.requestManager.get(
          '/adminArea/user/' + this.data.user.id + '/client',
          {array_values: true}
        )
      )['user'][0];

      if (userData.userDepartments) {
        const userDepartmentsId = userData.userDepartments.map(
          (el) => el.departments[0].id
        );
        this.userDepartments = this.allDepartments.filter((dep) =>
          userDepartmentsId.includes(dep.id)
        );
        this.initializeUserDepartmentsRights();
      }
    });
  }

  private initializeUserDepartmentsRights() {

    const { user } = this.data;
    if (!user || !user.userDepartments) {
      this.userDepartments = [];
    }
    this.userDepartments = Array.from(user.userDepartments);
    this.userDepartments = this.userDepartments.map((item) =>
      Object.assign({}, item)
    );

    if (this.userDepartments) {
      this.userDepartments.forEach((dep) => {
        this.requestManager.resetWhereCondition();
        this.requestManager.addWhereCondition(
          'userId',
          '=',
          dep.userId,
          'userDepartmentsRights',
          'AND'
        );
        this.requestManager
          .get(
            '/departments/' + dep.departmentsId + '/userDepartmentsRights',
            {array_values: true},
            true
          )
          .then((userDepartmentsRights) => {
            userDepartmentsRights = userDepartmentsRights['departments'][0][
              'userDepartmentsRights'
              ].find((el) => el.userId === user.id);
            dep['userDepartmentsRights'] = userDepartmentsRights;
          });
      });
    }
  }

  private getAvailableDepartments() {
    return this.userDepartments
      ? this.allDepartments.filter(
          (ad) =>
            this.userDepartments.find((ud) => ud.id === ad.id) === undefined
        )
      : (this.availableDepartments = _.cloneDeep(this.allDepartments));
  }

  async toggleApproving(type: string, element: any) {
    let body;
    switch (type) {
      case 'contentwise':
        element['userDepartmentsRights']['canApproveContentWise'] =
          element['userDepartmentsRights']['canApproveContentWise'] === '1'
            ? '0'
            : '1';
        body = {
          canApproveContentWise:
            element['userDepartmentsRights']['canApproveContentWise'],
        };

        if (body.canApproveContentWise === '1') {
          element['userDepartmentsRights']['canReadDepartmentsFiles'] = '1';
          body.canReadDepartmentsFiles = '1';
        }
        break;
      case 'financewise':
        element['userDepartmentsRights']['canApproveFinanceWise'] =
          element['userDepartmentsRights']['canApproveFinanceWise'] === '1'
            ? '0'
            : '1';
        body = {
          canApproveFinanceWise:
            element['userDepartmentsRights']['canApproveFinanceWise'],
        };

        if (body.canApproveFinanceWise === '1') {
          element['userDepartmentsRights']['canReadDepartmentsFiles'] = '1';
          body.canReadDepartmentsFiles = '1';
        }
        break;
      case 'canMove':
        element['userDepartmentsRights']['canMoveDepartmentsFiles'] =
          element['userDepartmentsRights']['canMoveDepartmentsFiles'] === '1'
            ? '0'
            : '1';
        body = {
          canMoveDepartmentsFiles:
            element['userDepartmentsRights']['canMoveDepartmentsFiles'],
        };
        break;
      case 'canDelete':
        element['userDepartmentsRights']['canDeleteDepartmentsFiles'] =
          element['userDepartmentsRights']['canDeleteDepartmentsFiles'] === '1'
            ? '0'
            : '1';
        body = {
          canDeleteDepartmentsFiles:
            element['userDepartmentsRights']['canDeleteDepartmentsFiles'],
        };
        break;
      case 'canRead':
        if (
          element.userDepartmentsRights.canApproveFinanceWise === '1' ||
          element.userDepartmentsRights.canApproveContentWise === '1'
        ) {
          this.snackBar.open(
            'Deaktivieren sie zuerst alle Freigabeoptionen',
            'Okay',
            {duration: 2000}
          );
        } else {
          element['userDepartmentsRights']['canReadDepartmentsFiles'] =
            element['userDepartmentsRights']['canReadDepartmentsFiles'] === '1'
              ? '0'
              : '1';
          body = {
            canReadDepartmentsFiles:
              element['userDepartmentsRights']['canReadDepartmentsFiles'],
          };
        }
        break;
    }
    await this.requestManager.put(
      '/userDepartmentsRights/' + element['userDepartmentsRights']['id'],
      body
    );
  }
}
