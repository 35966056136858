import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {IUser} from '@interfaces/iuser';

import {UserService} from '@services/user/user.service';
import {select, Store} from '@ngrx/store';
import {UserState} from '../../store/user.reducer';
import {loadUsers} from '../../store/user.actions';
import {selectUsers} from '../../store/user.selectors';
import {Observable} from 'rxjs';
import {UserCreateDialogComponent} from '../user-create-dialog/user-create-dialog.component';
import {concatAll, filter} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Component({
  selector: 'app-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.scss']
})
export class UserOverviewComponent implements OnInit {
  searchTerm: string;
  displayedColumns: string[] = ['id', 'name', 'email', 'phone', 'entryCreated'];
  isLoading = true;

  users$: Observable<IUser[]>;
  filteredUserList: IUser[];

  constructor(
    public userServices: UserService,
    private router: Router,
    private dialog: MatDialog,
    private store: Store<UserState>
  ) {
  }

  async ngOnInit() {
    this.isLoading = true;
    this.store.dispatch(loadUsers());
    this.users$ = this.store.pipe(select(selectUsers));
    this.users$.subscribe(users => {
      this.filteredUserList = users;
      this.filteredUserList.sort((a, b) => a.id ? 1 : -1);
    });
    this.isLoading = false;
  }

  /**
   * Navigate to user-single route
   * @param row user-object needed to get user id for route
   */
  openSingleUser(row: any) {
    this.router.navigateByUrl('user/' + row.id);
  }

  /**
   * Filter user-array by id, name, phone, entryCreated and save results to filteredUserList
   */
  async filter() {
    this.isLoading = true;
    this.filteredUserList = [];
    await this.users$
      .pipe(
        concatAll(),
        filter((el) =>
          el.id.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          (el.name.toLowerCase() + ' ' + el.surname.toLowerCase()).includes(this.searchTerm.toLowerCase()) ||
          el.phone.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          el.email.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          el.entryCreated.toLowerCase().includes(this.searchTerm.toLowerCase())
        ),
      ).forEach(
        user => {
          if (this.filteredUserList.find((existing) => existing.id === user.id) === undefined) {
            this.filteredUserList.push(user);
          }
        }
      );
    this.filteredUserList.sort((a, b) => a.deleted ? 1 : -1);
    this.isLoading = false;
  }

  addUserDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '800px';

    this.dialog.open(UserCreateDialogComponent, dialogConfig);
  }
}
