import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {IUser} from '@interfaces/iuser';

// Load Users
export const loadUsers = createAction(
  '[User Overview Component] Load Users'
);
export const loadUsersSuccess = createAction(
  '[User List Effect] Load Users Success',
  props<{ users: IUser[] }>()
);
export const loadUsersFailure = createAction(
  '[User List Effect] Load Users Failure',
  props<{ error: any }>()
);


// Load User
export const loadUser = createAction(
  '[User Overview Component] Load User',
  props<{ id: string | number }>()
);
export const loadUserSuccess = createAction(
  '[User Effect] Load User Success',
  props<{ selectedUser: IUser }>()
);
export const loadUserFailure = createAction(
  '[User Effect] Load User Failure',
  props<{ error: any }>()
);


// Add User
export const addUser = createAction(
  '[User Create Dialog] Add User',
  props<{ user: IUser }>()
);
export const addUserSuccess = createAction(
  '[User Add Effect] Add User Success',
  props<{ user: IUser }>()
);
export const addUserFailure = createAction(
  '[User Add Effect] Add User Failure',
  props<{ error: any }>()
);
export const clearUserFailure = createAction(
  '[User Clear Effect] Clear User Failure'
);

// Update User
export const updateUser = createAction(
  '[User Single Component] Update User',
  props<{ user: Update<IUser> }>()
);

// Delete User
export const deleteUser = createAction(
  '[User Delete Dialog] Delete User',
  props<{ id: string | number }>()
);

