<h1 mat-dialog-title>Sind Sie sicher?</h1>
<div mat-dialog-content>
  <p>
    Durch Klicken auf Löschen wird dieser Business Report vom Server entfernt!
  </p>
  <div>
    <strong>{{ data.name }}</strong>
    <div class="grey">{{ data.description }}</div>
    <div>{{ data.entryCreated | date: "dd.MM.yyyy" }}</div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Abbrechen</button>
  <button mat-button [mat-dialog-close]="true" color="warn">Löschen</button>
</div>
